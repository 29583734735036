import React, { useState, useEffect } from 'react';
import GoalProfile from './../GoalSteps/GoalProfile';
import WealthName from './../GoalSteps/WealthName'
import WealthAmount from './../GoalSteps/WealthAmount'
import WealthYears from './../GoalSteps/WealthYears'

export default function AddWealth({title, profileName, gStepChange, gsteps, goalName}) {

  const [steps, setSteps] = useState(2);
  const [percentage, setPercentage] = useState(0);
  const [stepCount, setStepCount] = useState(0);

  const StepChange = (step) => {
    setSteps(step);
  }
  
  useEffect(() => {
    if(sessionStorage.getItem('goal_creation') === '1'){
      setSteps(2);
    }else{
      setSteps(1);
    }
 }, []);

  return (
    <>
      {steps === 1 && (<GoalProfile title={title}  StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} setStepCount={setStepCount} goalType={"Wealth"}/>)}

      {steps === 2 && (<WealthName title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} profileName={profileName} gsteps={gsteps} gStepChange={gStepChange} goalName={goalName}/>)}

      {steps === 3 && (<WealthAmount title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} profileName={profileName} />)}

      {steps === 4 && (<WealthYears title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} profileName={profileName} goalName={goalName}/>)}
    </>
  );
}
