import React from 'react'
import Header from '../layout/Header';
import Footer from '../layout/Footer';

export default function About() {
   return (
      <React.Fragment>
         <Header />
         <div className="container">
              <div className="row">
                 <h1>About Us</h1>
              </div>
           </div>
           <div className="clear"></div>
         <Footer />
      </React.Fragment>
   )
}
