import React, { Fragment, useState } from 'react'
import useValidator from '../../hooks/useValidator'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react';

export default function GoalExpense({title, StepChange, steps, setPercentage, percentage, profileName, gpsteps, gStepChange }) {

    const [validator, showValidationMessage] = useValidator();
    const [expense, setExpense] = useState("");
    const [frequency, setFrequency] = useState(1);
    const handleChange = e => {
        const { value } = e.target;
        setExpense(formatAmount(value.replace(/,/g, "")));
        numtoWord(e);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleSubmit = () => {

        if (validator.allValid()) {
            sessionStorage.setItem('g_expense', parseInt(expense.replace(/,/g, "")) * parseInt(frequency));
            StepChange(steps + 1);
        } else {
            showValidationMessage(true);
        }
    };

    const [amt, setAmt] = useState("");

    const numtoWord = e => {
        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');
        setAmt(ucwords(amountInWords));
    }

    const skipGoal = () => {
        
        let goalStep = sessionStorage.getItem('goalStep');
        let increment= 1;
        if(goalStep >= 3 && sessionStorage.getItem('kids') !== '1'){
            increment = 2;
        }
        sessionStorage.setItem('goalStep', parseInt(goalStep)+increment);
        gStepChange(parseInt(goalStep)+increment);
    }

    useEffect(() => {

        let profSumm = JSON.parse(sessionStorage.getItem('ProfSummary'));
        const ucwords = require('ucwords');
        if(sessionStorage.getItem('g_relation') === "self"){
            setExpense(formatAmount(profSumm.familyIncome.expense_self_monthly));
            const amountInWords = numWords(profSumm.familyIncome.expense_self_monthly);
            setAmt(ucwords(amountInWords));
        }else{
            setExpense(formatAmount(profSumm.familyIncome.expense_spouse_monthly));
            const amountInWords = numWords(profSumm.familyIncome.expense_spouse_monthly);
            setAmt(ucwords(amountInWords));
        }
        

        
    }, []);

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                    <h1> Retirement for {sessionStorage.getItem('g_name')}</h1>
                        <div className="input-box ruppys yearly">
                            <label> Your current expenses </label>
                            <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"expense"} value={expense} onChange={handleChange} onKeyPress={numtoWord} />
                            <span className="text-word">{amt}</span>
                            {validator.message("expense", expense, "required", {
                                messages: {
                                    required: "Expense is required"
                                }
                            })}
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={frequency === 12 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtn toggleBtnC toggleBtnP" onChange={(checked) => {
                                setFrequency(checked === true ? 1 : 12)
                            }} />

                        </div>
                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="skipBtn" onClick={skipGoal}> SKIP </button>
                            <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
