import React, { Fragment, useState } from 'react'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import axios from 'axios';
import { apiUrl } from '../../constants/global';
import {calculateAge} from '../../constants/common';

export default function EducationG({ title, StepChange, steps, setPercentage, percentage }) {


    const { register, handleSubmit, formState: { errors } } = useForm();
    const [pgamt, setPgAmt] = useState("");
    const [gamount, setGamount] = useState("");
    const [pgamount, setPgamount] = useState("");


    const previousButton = () => {
        StepChange(steps - 1);
    }

    const [amt, setAmt] = useState("");

    const numtoWord = e => {
        const { value } = e.target;


        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');

        if(e.target.name === 'gamount'){
            setGamount(formatAmount(value.replace(/,/g, "")));
            setAmt(ucwords(amountInWords));
        }else{
            setPgamount(formatAmount(value.replace(/,/g, "")));
            setPgAmt(ucwords(amountInWords));
        }

        
    }
    const onSubmit = data => {

        const age = calculateAge(sessionStorage.getItem('g_dob'));
        const required_after_cal = 17 - age;
        const required_after = required_after_cal < 1 ? 1 : required_after_cal;
        const required_till = required_after + 6;
        const postData = {
            "goal_type": "Education",
            "aspire": "Education",
            "goal_name":"Education",
            "profile_id": sessionStorage.getItem('profile_id'),
            "plan_for": {
                "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
                "current_age": age < 1 ? 1 : age,
                "relation": sessionStorage.getItem('g_relation'),
            },
            "yearly_expense":{
                "0":gamount.replace(/,/g, ""),
                "1":pgamount.replace(/,/g, "")
            },
            "no_years":{
                "0":4,
                "1":2
            },
            "goal_params": {
                "age": age < 1 ? 1 : age,
                "post_inflation": 7,
                "post_return": 7,
                "goal_inflation": 7,
                "required_after":required_after,
                "required_till": required_till
            },
            "risk_profile": {
                "available": false,
                "pre_return": 8,
                "risk_profile_id": sessionStorage.getItem('profile_id')
            },
            "assumptions": {
                "assumption_id": false,
                "inflation": 7.5,
                "income_grow": "10"
            },
            "total_abcd": [{
                "amount": parseInt(gamount.replace(/,/g, "")) + parseInt(pgamount.replace(/,/g, "")),
                "frequency": 12,
                "no_of_times": 1,
                "gap": 0
            }],
            "actual": [{
                "actual_y": 0,
                "frequency": 1,
                "actual_ls": 1,
                "grow": "0"

            }]
        };

        axios.post(`${apiUrl}goals/add`, postData)
            .then(function (response) {
                SweetAlert.fire(
                    'Goals',
                    'Added New Goal.',
                    'success'
                ).then(function () {
                    window.location.href = "/";
                })
            })
            .catch(function (error) {
                console.log(error);
                SweetAlert.fire(
                    'Goals',
                    'Failed.',
                    'error'
                )
            });

    };

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=" box-shadow Creating-Financial">
                        <h1> Education for {sessionStorage.getItem('g_name')}</h1>
                            <div className="input-box ruppys yearly">
                                <label> Amount for Graduation </label>
                                <input type="text" className="form-control userInput" aria-label="Amount " name={gamount} value={gamount} {...register("gamount", { required: true })} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' />
                                <span className="text-word">{amt}</span><br />
                                {errors.gamount && <span className='srv-validation-message'>Amount for Graduation is required</span>}
                                <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            </div>


                            <div className="input-box ruppys yearly">
                                <label> Amount for Post Graduation </label>
                                <input type="text" className="form-control userInput" aria-label="Amount " name={pgamount} value={pgamount} {...register("pgamount", { required: true })} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' />
                                <span className="text-word">{pgamt}</span><br />
                                {errors.pgamount && <span className='srv-validation-message'>Amount for Post Graduation is required</span>}
                                <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            </div>
                            <div className="bottombtn mt-7">
                                <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                <button className="next" type='submit'> Next<i className="fas fa-angle-right" /> </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment >
    )
}
