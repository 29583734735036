import React from "react";
import { useEffect } from "react";
import { apiUrl, } from '../../constants/global';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useState } from "react";

export default function MapContactPopup({ profile_id, fullname, emailAddress }) {

    const [contacts, setContacts] = useState([]);
    const [searchkeyword, setSearchkeyword] = useState();
    const searchContact = (fullname = '') => {

        axios.post(`${apiUrl}admin/search-lms-contact`, { fullname: fullname })
            .then(function (response) {
                setContacts(response.data.rows);
            })
            .catch(e => {
                SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });

    }

    const handleChange = (e) => {
        setSearchkeyword(e.target.value);
    }

    const mapContact = (contact_id, profile_id) => {

        axios.post(`${apiUrl}admin/map-lms-profile-id-contact`, { contact_id: contact_id, profile_id: profile_id })
            .then(function (response) {

                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Mapped Successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                }).then(function () {

                    window.location.href = "/admin-dashboard";


                });

            })
            .catch(e => {
                SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });

    }

    useEffect(() => {

        searchContact(fullname);
        /*const Postdata = {
            "profile_id": profile_id,
            "fullname": fullname,
            "emailAddress": emailAddress
        };

        axios.post(`${apiUrl}admin/search-lms-contact`, Postdata)
            .then(function (response) {
                setContacts(response.data.rows);
            })
            .catch(e => {
                SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });*/

    }, [profile_id, fullname, emailAddress]);

    return (
        <>



            <div class="form-group"><div class="col-md-12">
                <div class="input-group">
                    <input type="text" autocomplete="off" placeholder="Investor Search" name="investor_search" id="investor_search" defaultValue={fullname} class="form-control" onChange={handleChange} />
                    <div class="input-group-append"><button class="btn btn-primary" id="target" type="button" onClick={() => searchContact(searchkeyword)}>Search</button></div></div></div></div>
            <div id="contact_view">
                <div class="table-responsive">
                    <table class="table table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>FULLNAME</th>
                                <th>Contact Id</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {contacts.length > 0 ?
                                <>
                                    {contacts.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <label><input type="radio" name="closed_by" value={item.id} /> {item.id}</label>
                                                    </td>
                                                    <td><button class="btn btn-primary btn-sm" type="button" onClick={() => mapContact(item.id, profile_id)}>Map</button></td>
                                                </tr>
                                            </>
                                        )
                                    })
                                    }
                                </> :
                                <>
                                    <tr>
                                        <td colSpan={3}> No Data Found</td>
                                    </tr>
                                </>
                            }


                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );
}