import React, { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../constants/global';

import AddWealth from './Goals/AddWealth';
import AddRetirement from './Goals/AddRetirement';
import AddEducation from './Goals/AddEducation';
import AddMarriage from './Goals/AddMarriage';

export default function CreateGoalsOld() {

  const [gsteps, setgSteps] = useState(1);
  const [profileName, setProfileName] = useState('');
  const [kids, setKids] = useState('');

  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
      (response, data) => {


        if (response.data.status === 100) {
          sessionStorage.setItem('ProfSummary', JSON.stringify(response.data));
          sessionStorage.setItem('life_stage', response.data.self_data.lifeStage);
          sessionStorage.setItem('self_dob', response.data.self_data.dob);
          sessionStorage.setItem('self_gender', response.data.self_data.gender)
          sessionStorage.setItem('goal_creation', 1);
          var found = response.data.prof_det.find(function(element) {
            return element.relation === "self";
         });

         sessionStorage.setItem('g_profile_details_id', found.profile_details_id);

          setProfileName("Anoj Tambe");
          sessionStorage.setItem('goalStep', 1);

          if(response.data.self_data.lifeStage === 'marriedWithKids1'){
            setKids(1);
            sessionStorage.setItem('kids', 1);
          }else{
            sessionStorage.setItem('kids', 0);
          }
        }
      });
  }, [])



  const gStepChange = (gsteps) => {
    setgSteps(gsteps);
  }


  return (
    <div className="graybg">
      <form className="theme-form">
        {gsteps === 1 && (<AddWealth relation="1" profileName={profileName} title={"Buy House"} gStepChange={gStepChange} gsteps={gsteps} />)}

        {gsteps === 2 && (<AddWealth relation="1" profileName={profileName} title={"Big Vacation"} gStepChange={gStepChange} steps={gsteps}  />)}

        {gsteps === 3 && (<AddWealth relation="1" profileName={profileName} title={"Car"} gStepChange={gStepChange} gsteps={gsteps} />)}

        {(gsteps === 4 && kids === 1) && (<AddEducation relation="1" profileName={profileName} title={"Kids Education"} gStepChange={gStepChange} gsteps={gsteps} />)}

        {(gsteps === 5 && kids === 1) && (<AddMarriage relation="1" profileName={profileName} title={"Kids Marriage"} gStepChange={gStepChange} gsteps={gsteps} />)}

        {gsteps === 5 && (<AddRetirement relation="1" profileName={profileName} title={"Retirement"} gStepChange={gStepChange} gsteps={gsteps} defaultgoal={1}/>)}

      </form>
    </div>
  )
}