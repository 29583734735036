import React, { useState } from 'react';
//import GoalDob from './../GoalSteps/GoalDob';
import GoalProfile from './../GoalSteps/GoalProfile';
//import GoalRetire from './../GoalSteps/GoalRetire';
import MarriageAmount from './../GoalSteps/MarriageAmount'
import MarriageAge from './../GoalSteps/MarriageAge'

export default function AddMarriage({title, profileName, gStepChange, gsteps}) {
  const [steps, setSteps] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [stepCount, setStepCount] = useState(0);

  const StepChange = (step) => {
    setSteps(step);
  }

  return (
    <>
      {steps === 1 && (<GoalProfile title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} setStepCount={setStepCount} goalType={"Marriage"}/>)}

      {/*steps === 2 && (<GoalDob StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)*/}

      {steps === 2 && (<MarriageAmount title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}

      {steps === 3 && (<MarriageAge title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}
    </>
  );
}
