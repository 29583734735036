import React, { Fragment, useState, useEffect } from 'react';
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import { Col, Card, CardHeader, CardBody } from 'reactstrap'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { apiUrl } from '../constants/global'
import formatAmount from 'indian-currency-formatter';
import SweetAlert from 'sweetalert2'

export default function EmergencyFund() {

    const [assets, setAssets] = useState([]);
    const [emergencyFund, setEmergencyFund] = useState("");
    const [requiredFund, setRequiredFund] = useState("");

    const getAssetsData = (property = "1", primary_flag = "N") => {
        const profile_id = sessionStorage.getItem('profile_id');
        axios.post(`${apiUrl}investment/recommended-assets`, { 'profile_id': profile_id, 'radio_type': 10, 'primary_flag': primary_flag }).then(
            (response, data) => {
                setAssets(response.data.main_assets);
                setEmergencyFund(response.data.grand_total.emergency_fund);
                setRequiredFund(response.data.grand_total.required_emergency_fund);
            });
    }

    useEffect(() => {

        if (sessionStorage.getItem('propery_radio') !== null) {
            getAssetsData(sessionStorage.getItem('propery_radio') === "/" ? 1 : 3, "Y");
        } else {
            getAssetsData();
        }

    }, []);

    const handleChange = () =>{
        console.log('hieee');
    }

    const MapFund = (id, currentValue) => {



        const inputValue = formatAmount(currentValue);

        
        SweetAlert.fire({
            title: 'Emergency Fund',
            input: 'text',
            inputLabel: 'Enter Amount to Map Emergency Fund',
            inputValue: inputValue,
            showCancelButton: true,
            onChange:handleChange,
            inputValidator: (value) => {
                if (!value) {
                    return 'Amount is Required'
                }
                console.log('value iis '+value+ ' and inputValue is'+inputValue.replace(/,/g, ""));
                if(parseInt(value) > parseInt(inputValue.replace(/,/g, ""))){
                    return 'Amount cannot be greater than current value'
                }

                
            }
        }).then(function (result) {
            console.log('amount '+result.value);

            axios.post(`${apiUrl}emergency-fund/map`, { profile_id: sessionStorage.getItem('profile_id'), 'invest_id': id, amount:result.value.replace(/,/g, "") }).then(
                function (response) {
      
                    SweetAlert.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Emergency Fund Mapped Successfully,',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                      }).then(function () {
                        getAssetsData();
                      });
                  
                }
              )

           
          }
            
        )

    }


    const tableColumns = [
        {
            name: 'Asset Class',
            selector: 'asset_class',
            sortable: true,
            left: true,
        },
        {
            name: 'Asset Type	',
            selector: 'asset_type',
            sortable: true,
            left: true,
        },
        {
            name: 'Instrument Name',
            selector: 'instr_name',
            sortable: true,
            left: true,
        },
        {
            name: 'Yearly Amount',
            cell: (row) => row.yearly_amount,
            sortable: false,
            center: true,
            footer: 10000000000
        },
        {
            name: 'Current Value',
            cell: (row) => formatAmount(row.till_date),
            sortable: false,
            center: true,
        },
        {
            name: 'Fund Mapped',
            cell: (row) => formatAmount(row.amount),
            sortable: false,
            center: true,
        },
        {
            name: 'Action',
            cell: (row) => <div>{row.type === 2 || row.type === 3 ? <> {row.linked === 1 ? <span onClick={() => MapFund(row.invest_id, row.till_date)}><i className="fas fa-unlink"></i></span> : <span onClick={() => MapFund(row.invest_id, row.till_date)}><i className="fas fa-link"></i></span>}</> : ''}</div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    return (
        <Fragment>
            <Header />
            <SideMenu />
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="name-box">
                            <h1 className="h1Inn"> Hey {sessionStorage.getItem('full_name')}, </h1>
                            <span> Your Emergency Fund is <strong className="text-success">Rs. {formatAmount(Math.round(emergencyFund))}</strong> of <strong className='text-primary'>Rs. {formatAmount(Math.round(requiredFund))}</strong></span>
                            <button type="button" className="btn Pay-Now float-left" onClick={() => { window.location.href = "/adjust" }}> Review / Adjust</button>
                            <br />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <Col lg="12 box-col-12" xl="12 xl-100">
                        <Card>
                            <CardHeader>
                                <h5>{"Track Funds"} </h5>
                            </CardHeader>
                            <CardBody className="tabbed-card">

                                <div className="best-seller-table responsive-tbl">
                                    <div className="item">
                                        <div className="table-responsive product-list">
                                            <DataTable
                                                data={assets}
                                                columns={tableColumns}
                                                striped={true}
                                                center={true}
                                                persistTableHead
                                            />
                                        </div>
                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </div>

            </div>
            <div className="clear"></div>
            <Footer />
        </Fragment>
    );

};