import React, { Fragment, useState, useEffect } from 'react';
//import DataTable from 'react-data-table-component'
import { Col, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import AdminHeader from '../layout/AdminHeader'
import Footer from '../layout/Footer'
import axios from 'axios';
import { apiUrl, WebUrl } from '../constants/global';
//import { Link } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import Register from './Register';
import * as copy from 'copy-to-clipboard';
import { Dropdown } from 'react-bootstrap'
import MapContactPopup from './popups/MapContactPopup';
import DataTable from 'react-data-table-component';


export default function AdminDashboard() {

  let navigate = useNavigate();
  const loggedInUser = sessionStorage.getItem("admin_id");
  if (loggedInUser === null) {
    navigate("/ulogin");
  }

  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  const [relManager, setRelManager] = useState([])
  const [profile_id, setProfile_id] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [usermodal, setUsermodal] = useState(false);
  const usertoggle = () => setUsermodal(!usermodal);

  const [adminRole, setAdminRole] = useState();


  const [mappingModel, setMappingModel] = useState(false);
  const mappingtoggle = () => setMappingModel(!mappingModel);


  const [fullname, setFullname] = useState();
  const [emailAddress, setEmailAddress] = useState();


  const loginUserV2 = (email) => {

    /*let pathName = "http://192.168.1.28:3000/auto-sign-in?email=" + email;
    window.open(pathName, "_blank");*/

    let pathName = "https://planv2.finnovatelive.in/auto-sign-in?email=" + email;
    window.open(pathName, "_blank");
  }

  const loginUser = (id) => {
    axios.get(`${apiUrl}admin/user-login/` + id).then(
      (response, data) => {

        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('profile_id', response.data.profile_id);
        sessionStorage.setItem('full_name', response.data.full_name);

        if (response.data.token !== null) {
          window.location.href = "/plan";
        }
      }
    );
  }

  const deleteUser = (id, profile_id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF0000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.post(`${apiUrl}admin/user-delete`, { 'id': id, 'profile_id': profile_id }).then(
            function (response) {
              SweetAlert.fire(
                'User',
                'Successfully Deleted.',
                'success'
              ).then(function () {
                window.location.href = "/admin-dashboard";
              });
            }
          )
        }
      });
  }

  useEffect(() => {
    axios.post(`${apiUrl}admin/user-profiles`, { 'rel_manager': sessionStorage.getItem('admin_id'), 'admin_role': sessionStorage.getItem('admin_role') }).then(
      (response, data) => {
        //console.log(response.data);
        setData(response.data);
        setTableRowsData(response.data);

      }
    );

    axios.get(`${apiUrl}admin/get-admins`).then(
      (response, data) => {
        //console.log(response.data);
        setRelManager(response.data);
      }
    );

    setAdminRole(sessionStorage.getItem('admin_role'));

  }, []);

  const EditRelManager = (id) => {
    setProfile_id(id);
    setModal(!modal);
  }

  const ContactMapping = (profile_id, name, email) => {

    setProfile_id(profile_id);
    setFullname(name);
    setEmailAddress(email);

    setMappingModel(!mappingModel);
  }

  const [title, setTitle] = useState("");

  const onChange = async (e) => {
    setTitle(e.target.value);

    // eslint-disable-next-line
    var searchData = data.filter((item) => {
      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }
    });

    setTableRowsData(searchData);

  };


  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Rel Manager',
      selector: row => row.admin_name,
      sortable: true,
    },
    {
      name: 'Created On',
      selector: row => row.create_on,
      sortable: true,
    },
    {
      name: 'Mapped',
      selector: row => <>{
        row.contact_map !== null ? (<><button class="btn btn-sm btn-success">Mapped</button></>) : (<><button class="btn btn-xs btn-danger" onClick={() => ContactMapping(row.profile_details_id, row.name, row.email)}>Not Mapped</button></>)
      }</>,
    },
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <><span className='cursor_pointer' onClick={() => loginUser(d.id)}><i className="fas fa-2x fa-sync-alt" style={{ fontSize: 16, padding: 11, color: '#06B153' }}></i></span><span className='cursor_pointer' onClick={() => loginUserV2(d.email)}> <i className="fas fa-2x fa-sign-in-alt" style={{ fontSize: 16, padding: 11, color: '#06B153' }}></i></span><span className='three-dot-admin cursor_pointer'>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
            <i className="fas fa-ellipsis-v" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => EditRelManager(d.profile_id)}><i className="fa fa-user text-primary" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Edit Rel Manager</Dropdown.Item>

            <Dropdown.Item onClick={() => deleteUser(d.id, d.profile_id)}><i className="fas fa-trash-alt text-danger" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Delete User</Dropdown.Item>

            <Dropdown.Item onClick={() => RiskLink(d.id, d.enc_profile)}> <i className="fas fa-paste text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Risk Profile Link</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </span></>,
    },
  ];


  const RiskLink = (id, enc_profile) => {
    const link = WebUrl + 'risk-profile-set?profile_id=' + enc_profile;

    if (copy(link)) {

      const postData = { link: link, module_name: 'risk_profile', profile_id: id, created_by: sessionStorage.getItem('admin_id') };
      axios.post(`${apiUrl}url-shared`, postData).then(
        (response, data) => {

        }
      );

      SweetAlert.fire({
        toast: true,
        icon: 'success',
        title: 'Link Copied Successfully',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
        }
      })
    } else {
      SweetAlert.fire(
        'Copy Link',
        'Something went wrong',
        'error'
      );
    }


  }

  const UpdateRelManager = () => {
    var postData = {
      'profile_id': profile_id,
      'rel_manager': admin_id
    }
    axios.post(`${apiUrl}profile/update-relmanager`, postData)
      .then(function (response) {

        SweetAlert.fire(
          'Profile',
          'Successfully Updated Rel Manager.',
          'success'
        ).then(function () {
          window.location.href = "/admin-dashboard"
        })

      })
      .catch(function (error) {
        SweetAlert.fire(
          'Profile',
          'Failed.',
          'error'
        )
      });
  }

  const [admin_id, setadmin_id] = useState();

  const handleChange = (e) => {
    const { value } = e.target;
    setadmin_id(value);
  }

  const AddUser = () => {
    setUsermodal(!usermodal);
  }


  return (


    <Fragment>
      <AdminHeader />

      <div className="container">
        <div className="email-wrap bookmark-wrap">
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>{"Assign/Update Rel Manager"}</ModalHeader>
            <ModalBody>
              <div className='col-md-12'>
                <br />
                <select className="form-control digits" name={"admin_id"} value={admin_id} onChange={handleChange} autoComplete="off">
                  <option value="">select</option>
                  {relManager.map((data, i) => {
                    return (
                      <option value={data.admin_id} key={i}>{data.admin_name}</option>
                    )
                  })}
                </select>
                <br />
                <button className="btn btn-primary" type='button' onClick={() => UpdateRelManager()}> Update Rel Manager </button>
              </div>
            </ModalBody>
          </Modal>

          <Modal isOpen={usermodal} toggle={usertoggle}>
            <ModalBody>
              <Register ulogin={1} relManager={sessionStorage.getItem('admin_id')} />
            </ModalBody>
          </Modal>



          <Modal isOpen={mappingModel} toggle={mappingtoggle}>
            <ModalHeader toggle={mappingtoggle}>{"Contact Mapping"}</ModalHeader>
            <ModalBody>
              <MapContactPopup profile_id={profile_id} fullname={fullname} emailAddress={emailAddress} />
            </ModalBody>
          </Modal>


          <div className="row">

            <Col lg="12 box-col-12" xl="12 xl-100">
              <Card>
                <CardHeader>
                  <h1 className='mt-4 mb-4'>Admin Dashboard</h1>

                  <div className="row">

                    <div className="col-md-6">
                      <button className="btn btn-primary" type='button' onClick={() => AddUser()}> Add User </button>
                    </div>

                    <div className="col-md-6">
                      <div className='text-end'>

                        <input
                          type="text"
                          placeholder="Name Search"
                          value={title}
                          onChange={(e) => onChange(e)}
                          style={{ width: "80%" }}
                          className="form-control"
                        />

                      </div>
                    </div>

                  </div>



                </CardHeader>
                <CardBody>

                  <DataTable
                    columns={columns}
                    data={tableRowsData}
                    pagination
                  />

                  {/*<DataTable
                    data={data}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    selectableRows
                    persistTableHead
                  />*

                  <Table>
                    <thead>
                      <tr>
                        <th>{"ID"}</th>
                        <th className="f-22">{"Name"}</th>
                        <th>{"Email"}</th>
                        <th>{"Rel Manager"}</th>
                        <th className="text-right">{"Created On"}</th>
                        <th>Mapped</th>
                        <th>{"Action"}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {// eslint-disable-next-line
                        data.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{item.profile_id}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.admin_name}</td>
                                <td>{item.create_on}</td>
                                <td>
                                  {
                                    item.contact_map !== null ? (<><button class="btn btn-sm btn-success">Mapped</button></>) : (<><button class="btn btn-sm btn-danger" onClick={() => ContactMapping(item.profile_details_id, item.name, item.email)}>Not Mapped</button></>)
                                  }
                                </td>
                                <td className="text-right">


                                  <span className='cursor_pointer' onClick={() => loginUser(item.id)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span>
                                  <span className='three-dot-admin cursor_pointer'>
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
                                        <i className="fas fa-ellipsis-v" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => EditRelManager(item.profile_id)}><i className="fa fa-user text-primary" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Edit Rel Manager</Dropdown.Item>
                                        {
                                          adminRole === "0" ?
                                            <>

                                              <Dropdown.Item onClick={() => deleteUser(item.id, item.profile_id)}><i className="fas fa-trash-alt text-danger" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Delete User</Dropdown.Item>
                                            </> : <></>
                                        }


                                        <Dropdown.Item onClick={() => RiskLink(item.id, item.enc_profile)}> <i className="fas fa-paste text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Risk Profile Link</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </span>




                                </td>
                              </tr>
                            </>
                          )
                        })}



                    </tbody>
                      </Table>*/}
                </CardBody>
              </Card>
            </Col>


          </div>

        </div>
      </div>
      <Footer />
    </Fragment>

  );

}
