import React from 'react';
import { Link } from 'react-router-dom';

export default function SideMenu({ goal_count }) {

    const page = window.location.pathname;

    return (
        <div className="navLeftFix">
            <ul>
                <li className="navPor"><Link to="/plan" className={page === '/plan' ? "active" : ""}><span> <i className="fas fa-pen-square"></i> </span><b>Plan</b></Link></li>
                <li className="navTra"><Link to="/goals" className={page === '/goals' ? "active" : ""}><span> <i className="fas fa-crosshairs"></i> </span><b>Life Goal</b></Link></li>
                <li className="navTra"><Link to="/cashflow" className={page === '/cashflow' ? "active" : ""}><span> <i className="fas fa-exchange-alt"></i></span><b>Cashflow</b></Link></li>
                <li className="navTra"><Link to="/advise" className={page === '/advise' ? "active" : ""}><span><i className="fas fa-location-arrow"></i></span><b>Advise</b></Link></li>
            </ul>
        </div>
    );
}
