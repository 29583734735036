import React, { useState, Fragment } from 'react'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Link } from 'react-router-dom'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'
import { useForm } from "react-hook-form";
import { Input, Col } from 'reactstrap'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import { apiUrl } from '../../constants/global';
import SweetAlert from 'sweetalert2'
import { useEffect } from 'react';

export default function AddRetirement({ title, gStepChange, gsteps, progress, currentexp, defaultgoal }) {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [steps, setSteps] = useState("");
  const [expense, setExpense] = useState(formatAmount(currentexp));
  const [frequency, setFrequency] = useState(12);
  const [retire_age, setUserval] = useState(65);
  const [value, setvalue] = useState();

  const onSliderChange = value => {
    setvalue(value);
    setUserval(value);
  };

  const onAfterChange = value => {
    setUserval(null);
  };

  const handleInputChange = (event) => {
    setvalue(event.target.value);
    setUserval(event.target.value);
};

  const previousButton = () => {
    StepChange(steps - 1);
  }

  const skipGoal = () => {

    let goalStep = sessionStorage.getItem('goalStep');
    let increment = 1;
    if (goalStep >= 3 && sessionStorage.getItem('kids') !== '1') {
      increment = 2;
    }
    sessionStorage.setItem('goalStep', parseInt(goalStep) + increment);
    gStepChange(parseInt(goalStep) + increment);
  }

  const onSubmit = (data) => {
    const age = sessionStorage.getItem('age');
    const life_expectancy = 80;
    const relation = "self";
    const required_after = value - age;
    const required_till = life_expectancy - age;

    if(age > value){
      SweetAlert.fire(
        'Goals',
        'Retire Age Cannot Be Less Than Age.',
        'error'
      )
    }else{
      const postData = {
        "goal_type": "Retirement",
        "aspire": "Retirement",
        "goal_name": "Retirement",
        "profile_id": sessionStorage.getItem('profile_id'),
        "plan_for": {
          "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
          "current_age": sessionStorage.getItem('age'),
          "relation": relation
        },
        "goal_params": {
          "age": age,
          "retire_age": value,
          "life_expectancy": life_expectancy,
          "post_inflation": 7,
          "post_return": 8,
          "goal_inflation": 7,
          "required_after": required_after,
          "required_till": required_till
        },
        "risk_profile": {
          "available": false,
          "pre_return": 10,
          "risk_profile_id": sessionStorage.getItem('profile_id')
        },
        "assumptions": {
          "assumption_id": false,
          "inflation": 7.5,
          "income_grow": "10"
        },
        "total_abcd": [{
          "amount": data.expense.replace(/,/g, ""),
          "frequency": frequency,
          "no_of_times": 1,
          "gap": 0
        }],
        "actual": [{
          "actual_y": 0,
          "frequency": 1,
          "actual_ls": 1,
          "grow": "0"
  
        }]
      };
  
      if (defaultgoal === 1) {
        const goalParams = {
          "profile_id": sessionStorage.getItem('profile_id'),
          "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
          "dob": sessionStorage.getItem('age'),
          "inflation": sessionStorage.getItem('inflation'),
          "income_grow": sessionStorage.getItem('income_grow'),
          "api_type": "insert",
          "goal_group": '2',
          "yearly_expense": sessionStorage.getItem('total_expense'),
          "yearly_income": sessionStorage.getItem('IncomeSelf'),
          "post_retire_inflation": sessionStorage.getItem('post_retire_inflation'),
          "life_expentancy": sessionStorage.getItem('life_expentancy'),
          "gi_dependents": 0,
          "income": sessionStorage.getItem('total_income'),
          "spouse_income": sessionStorage.getItem('IncomeSpouse'),
          "spouse_prof_details_id": sessionStorage.getItem('spouse_prof_details_id'),
          "min": 100000,
          "max": 1000000,
          "retire_age": sessionStorage.getItem('retire_age')
        }
        axios.post(`${apiUrl}goals/default`, goalParams).then(function (response) {
  
        })
      }
  
      axios.post(`${apiUrl}goals/add`, postData)
        .then(function (response) {
  
          const profile_id = sessionStorage.getItem('profile_id');
  
          const postData = { profile_id: profile_id, assumption: { retire_age: value } };
  
          axios.post(`${apiUrl}profile-assumptions/update_assumption`, postData);
  
  
          axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
            (response, data) => {
              SweetAlert.fire(
                'Goals',
                'Added New Goal.',
                'success'
              ).then(function () {
                //window.location.href = "/plan"
                skipGoal();
              });
            }
          );
  
        })
        .catch(function (error) {
          console.log(error);
          SweetAlert.fire(
            'Goals',
            'Failed.',
            'error'
          )
        });
    }



  };

  const [amt, setAmt] = useState("");

  const numtoWord = e => {

    const { value } = e.target;
    setExpense(formatAmount(value.replace(/,/g, "")));

    const amountInWords = numWords(e.target.value.replace(/,/g, ""));
    const ucwords = require('ucwords');
    setAmt(ucwords(amountInWords));
  }

  /*const skipGoal = () => {

    let goalStep = sessionStorage.getItem('goalStep');
    let increment = 1;
    if (goalStep >= 3 && sessionStorage.getItem('kids') !== '1') {
      increment = 2;
    }
    sessionStorage.setItem('goalStep', parseInt(goalStep) + increment);
    gStepChange(parseInt(goalStep) + increment);
  }*/

  const handleBlur = () => {
    if (retire_age < 0) {
      setUserval(0);
    } else if (retire_age > 100) {
      setUserval(100);
    }
  };
  const StepChange = (step) => {
    setSteps(step);
  }

  useEffect(() => {
    setvalue(65);
}, []);

  return (
    <Fragment>
      <div className="row DivJustify">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="financiali-tilte"><Link to="/plan-status" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
          <Progress color="success" value={progress} className="sm-progress-bar" />
          <div className=" box-shadow Creating-Financial">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="input-box ruppys yearly">
                <label> Your current expenses </label>
                <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name="expense" value={expense} {...register("expense", { required: true })} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' />
                <span className="text-word">{amt}</span>
                {errors.expense && <span className='srv-validation-message'>Amount is required</span>}
                <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                { /* eslint-disable-next-line */}
                <BootstrapSwitchButton id="btnself" width={70} height={40} checked={frequency === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtnf toggleBtnC toggleBtnP" onChange={(checked) => {
                  setFrequency(checked === true ? 1 : 12)
                }} />

              </div>

              <div className="input-box ruppys yearly">
                <div md="12">
                  <span>{"When you intend to retire?"}</span></div>

                <Col md="10">
                  <Slider
                    onChange={onSliderChange}
                    onAfterChange={onAfterChange}
                    min={45}
                    max={65}
                    defaultValue={value}
                    value={value}
                  />
                </Col>
                <div md="2" style={{ 'justifyContent': 'right', 'display': 'flex' }}>
                  <Input
                    value={retire_age}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    type="text"
                    className='sliderInput'
                    style={{ paddingLeft: '15px !important' }}
                  />
                </div>
              </div>

              <div className="bottombtn mt-7">
                <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>

                <button className="next" type='submit'> Next<i className="fas fa-angle-right" /> </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
