import React, { Fragment } from 'react'
import { Progress, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import AddHealth from './RiskProfile/AddHealth';
import AddInsurance from './RiskProfile/AddInsurance';

export default function ChooseInsurance() {

    // eslint-disable-next-line
    const [subType, setSubType] = useState(sessionStorage.getItem('subType') !== null ? Number.parseInt(sessionStorage.getItem('subType')) : " ");
    const [steps, setSteps] = useState(sessionStorage.getItem('giStep') !== null ? Number.parseInt(sessionStorage.getItem('giStep')) :  Number.parseInt(1));

    const setType = (e) => {
        setSubType(Number.parseInt(e.target.value));
        setSteps(Number.parseInt(2));
    }

    return (
        <Fragment>
            <div className="tap-top"><i data-feather="chevrons-up"></i></div>
            <div className="page-wrapper CustomPage">
                <div className="container">
                    {steps === 1 && (
                        <div className="row DivJustify">
                            <div className="col-lg-9 col-md-12 col-sm-12">
                                <div className="financiali-tilte"><Link to="/cashflow" className="goback"><i className="fas fa-angle-left" /></Link> Check How much Cover You Need</div>
                                <Progress color="success" value="50" className="sm-progress-bar" />
                                <div className=" box-shadow Creating-Financial selectToContine">
                                    <h1> select one to continue </h1>
                                    <div>
                                        <Button className='btn_choice btn_choice_insurance btnYes' onClick={setType} value={Number.parseInt(4)}>Life Insurance</Button>
                                        <Button className='btn_choice btn_choice_insurance btnNo' onClick={setType} value={Number.parseInt(9)}>Health Insurance</Button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    )}
                    {(steps === 2 && subType === 4) && (<AddInsurance />)}
                    {(steps === 2 && subType === 9) && (<AddHealth />)}
                </div>
            </div>
        </Fragment>
    )
}
