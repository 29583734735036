import React, { Fragment, useEffect, useState } from 'react';
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import { Card, CardHeader, CardBody, Table } from 'reactstrap'
import axios from 'axios';
import { apiUrl, BaseUrl } from '../constants/global';
//import SweetAlert from 'sweetalert2'
import { Link } from 'react-router-dom';

export default function ReportHistory() {

    const [history, setHistory] = useState([]);
    const [email, setEmail] = useState();

    /*const SentMail = (ref_doc) => {
        SweetAlert.fire({
            title: "Email PDF",
            text: "Click on Yes to Sent Email",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FF0000",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            closeOnConfirm: false,
            closeOnCancel: true
        })
            .then((result) => {
                if (result.isConfirmed) {





                    axios.post(`${apiUrl}fpplanstatus/mail`, { 'profile_id': sessionStorage.getItem("profile_id"), 'to': 'anoj.tambe@finnovate.in', 'path': '/var/www/restapi/public/uploads/riskpdf/', 'filename': ref_doc, 'email': email }).then(
                        (response, data) => {
                            SweetAlert.fire(
                                'EMail',
                                'Successfully Sent.',
                                'success'
                            ).then(function () {
                                window.location.href = "/report-history";
                            });
                        }
                    );



                }
            });
    }*/


    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');

        axios.get(`${apiUrl}fpplanstatus/get/${profile_id}`).then(
            (response, data) => {
                setHistory(JSON.parse(response.data.history));
                setEmail(response.data.email);
            });

    }, []);

    return (
        <Fragment>
            <Header />
            <SideMenu />

            <div className="container">
                <div className="email-wrap bookmark-wrap">
                    <div className="row">
                        <h1 className='mt-4'> Report History</h1>



                        <div className="col-xl-12 col-md-12 box-col-12">
                            <div className="email-right-aside bookmark-tabcontent contacts-tabs"></div>
                            <div className="email-body radius-left"></div>
                            <div className="pl-0">

                                <Card className="mb-0">
                                    <CardHeader className="d-flex">
                                        <span className="f-14 pull-right mt-0"> {"Contacts"}</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="best-seller-table responsive-tbl">
                                            <div className="item">
                                                <div className="table-responsive product-list">
                                                    <Table borderless>
                                                        <thead>
                                                            <tr>
                                                                <th className="f-22">{"Status"}</th>
                                                                <th>{"Status Date"}</th>
                                                                <th>{"Financial Plan (ref_doc)"}</th>
                                                                <th className="text-right">{"Action"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {// eslint-disable-next-line
                                                                history.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>
                                                                                    {item.status}
                                                                                </td>
                                                                                <td>{item.status_date}</td>
                                                                                <td>

                                                                                    {
                                                                                        item.ref_doc !== "" ?
                                                                                            (
                                                                                                <a href={BaseUrl + "uploads/riskpdf/" + item.ref_doc} target="_blank" rel="noreferrer"><i className="fas fa-file-pdf fa-2x"></i></a>
                                                                                            ) : ''
                                                                                    }


                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {
                                                                                        item.ref_doc !== "" ?
                                                                                            (<Link to="/compose"
                                                                                                state={{ 'email': email, 'ref_doc': item.ref_doc, 'path': '/var/www/restapi/public/uploads/riskpdf/', 'subject': 'FP REPORT PDF', ref_url:'/report-history' }}
                                                                                            ><i className="fa fa-envelope fa-2x" aria-hidden="true"></i></Link>)
                                                                                            : 'NA'
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clear"></div>
            <Footer />
        </Fragment>
    );

}