import React, { Fragment, useEffect, useState } from 'react'
import useValidator from '../../hooks/useValidator'
import { Input, Col } from 'reactstrap'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import {calculateAge} from '../../constants/common'

export default function GoalRetire({title, StepChange, steps, setPercentage, percentage, defaultgoal }) {

    const [retire_age, setUserval] = useState(58);
    const [value, setvalue] = useState();
    const [validator, showValidationMessage] = useValidator();

    const onSliderChange = value => {
        setvalue(value);
        setUserval(value);
    };

    const onAfterChange = value => {
        setUserval(null);
    };

    const handleInputChange = (event) => {
        setvalue(event.target.value);
        setUserval(event.target.value);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleBlur = () => {
        if (retire_age < 0) {
            setUserval(0);
        } else if (retire_age > 100) {
            setUserval(100);
        }
    };

    const handleSubmit = (event) => {
        
        if (validator.allValid()) {


            if(sessionStorage.getItem('age') > value){
                SweetAlert.fire(
                  'Goals',
                  'Retire Age Cannot Be Less Than Age.',
                  'error'
                ).then({
    
                })
            }else{
                const age = calculateAge(sessionStorage.getItem('g_dob'));
                const life_expectancy = 80;
                const relation = "self";
                const required_after = value - age;
                const required_till = life_expectancy - age;
                const aspire = "Retirement";
                const postData = {
                    "goal_type": "Retirement",
                    "goal_name":"Retirement",
                    "aspire": aspire,
                    "profile_id": sessionStorage.getItem('profile_id'),
                    "plan_for": {
                        "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
                        "current_age": sessionStorage.getItem('current_age'),
                        "relation": relation
                    },
                    "goal_params": {
                        "age": age,
                        "retire_age": value,
                        "life_expectancy": life_expectancy,
                        "post_inflation": 7,
                        "post_return": 8,
                        "goal_inflation": 7,
                        "required_after": required_after,
                        "required_till": required_till
                    },
                    "risk_profile": {
                        "available": false,
                        "pre_return": 10,
                        "risk_profile_id": sessionStorage.getItem('profile_id')
                    },
                    "assumptions": {
                        "assumption_id": false,
                        "inflation": 7.5,
                        "income_grow": "10"
                    },
                    "total_abcd": [{
                        "amount": sessionStorage.getItem("g_expense").replace(/,/g, ""),
                        "frequency": 12,
                        "no_of_times": 1,
                        "gap": 0
                    }],
                    "actual": [{
                        "actual_y": 0,
                        "frequency": 1,
                        "actual_ls": 1,
                        "grow": "0"
    
                    }]
                };
    
                console.log("defaultgoal "+defaultgoal);
                
                if(defaultgoal === "1"){
                    const goalParams = {
                        "profile_id": sessionStorage.getItem('profile_id'),
                        "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
                            "dob":31,
                            "inflation": 7.5,
                            "income_grow": "10",
                            "api_type":"insert",
                            "goal_group":2,
                            "yearly_expense":sessionStorage.getItem("g_expense").replace(/,/g, ""),
                            "yearly_income":100000,
                            "post_retire_inflation":6,
                            "life_expentancy":80,
                            "gi_dependents":0,
                            "income":100000,
                            "min":100000,
                            "max":1000000,
                            "retire_age":60
                    }
                axios.post(`${apiUrl}goals/default`, goalParams).then(function (response) {
                        
                    })
                }
    
                axios.post(`${apiUrl}goals/add`, postData)
                    .then(function (response) {
    
                        
                        axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                            (response, data) => {
                              SweetAlert.fire(
                                'Goals',
                                'Added New Goal.',
                                'success'
                              ).then(function () {
                                window.location.href = "/"
                              })
                            }
                          );
    
                    })
                    .catch(function (error) {
                        console.log(error);
                        SweetAlert.fire(
                            'Goals',
                            'Failed.',
                            'error'
                        )
                    });
            }

            

        } else {
            showValidationMessage(true);
        }
    };

    useEffect(() => {
        setvalue(58);
    }, []);

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                    <h1> Retirement Goal for {"profile_name"}</h1>
                        <div className="input-box ruppys yearly">
                            <div md="12">
                                <span>{"When you intend to retire?"}</span></div>
     
                            <Col md="10">
                                <Slider
                                    onChange={onSliderChange}
                                    onAfterChange={onAfterChange}
                                    min={45}
                                    max={65}
                                    defaultValue={value}
                                    value={value}
                                />
                            </Col>
                            <div md="2" style={{ 'justifyContent': 'right', 'display': 'flex'}}>
                                <Input
                                    value={retire_age}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    className='sliderInput'
                                    style={{paddingLeft:'15px !important'}}
                                />
                            </div>
                        </div>
                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Save<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
