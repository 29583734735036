import React, { Fragment } from 'react'
import { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import numWords from 'num-words';

export default function Step3({ step, ChangeStep, riskId, riskDescription, goalCount }) {

  const [validator, showValidationMessage] = useValidator();
  const [lumpsum, setLumpsum] = useState("");
  const [monthly, setMonthly] = useState("");

  const [lumpsum_amt, setLumpsum_amt] = useState("");
  const [monthly_amt, setMonthly_amt] = useState("");

  const handleSubmit = () => {
    if (validator.allValid()) {
      ChangeStep(step + 1);
    } else {
      showValidationMessage(true);
    }
  }

  const numtoWord = e => {

    const amountInWords = numWords(e.target.value.replace(/,/g, ""));
    const ucwords = require('ucwords');

    if (e.target.name === 'lumpsum') {
      setLumpsum_amt(ucwords(amountInWords));
    } else if (e.target.name === 'monthly') {
      setMonthly_amt(ucwords(amountInWords));
    }



}

  const handleChange = (e) => {
    if (e.target.name === 'lumpsum') {
      setLumpsum(formatAmount(e.target.value.replace(/,/g, "")));
      sessionStorage.setItem('lumpsum', e.target.value.replace(/,/g, ""));
    } else if (e.target.name === 'monthly') {
      setMonthly(formatAmount(e.target.value.replace(/,/g, "")));
      sessionStorage.setItem('monthly', e.target.value.replace(/,/g, ""));
    }

    numtoWord(e);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12"> </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="financiali-tilte"></div>
          <div className=" box-shadow Creating-Financial">
            <h1> Set Amount </h1>

            <div className='row'>
              <div className='col-md-6'>
                <div className="input-box ruppys">
                  <label> One Time Lumpsum </label>
                  <input type="text" className="form-control userInput" name={"lumpsum"} value={lumpsum} onChange={handleChange} onKeyPress={numtoWord}/>
                  <div className="intext text-word"> {lumpsum_amt} </div>

                  <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>

                  {validator.message("lumpsum", lumpsum, "required", {
                    messages: {
                      required: "Lumpsum Amount is required"
                    }
                  })}

                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className="input-box ruppys">
                  <label> Monthly Amount </label>
                  <input type="text" className="form-control userInput" name={"monthly"} value={monthly} onChange={handleChange} onKeyPress={numtoWord}/>
                  <div className="intext text-word"> {monthly_amt} </div>
                  <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                  {validator.message("monthly", monthly, "required", {
                    messages: {
                      required: "Monthly Amount is required"
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="bottombtn">
              <button className="back" disabled> <i className="fas fa-angle-left" /> </button>
              <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12"> </div>
      </div>
    </Fragment>
  )
}