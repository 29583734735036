import React, { Fragment, useState, useEffect } from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Table } from 'reactstrap'
import axios from 'axios'
import { apiUrl } from '../constants/global'
import formatAmount from 'indian-currency-formatter';
import SweetAlert from 'sweetalert2'
import DataTable from 'react-data-table-component'

export default function TrackPortfolio() {

  const [activeTab2, setActiveTab2] = useState('1');
  const [assets, setAssets] = useState([]);
  //eslint-disable-next-line
  const [summary, setSummary] = useState([]);
  const [property, setProperty] = useState("");

  const [ytotal, setYtotal] = useState("");
  const [tilldate, setTilldate] = useState("");

  const [nested, setNested] = useState([]);

  const tableColumns = [
    {
      name: 'Asset Class',
      selector: 'asset_class',
      sortable: true,
      left: true,
    },
    {
      name: 'Asset Type	',
      cell: (row) => row.asset_type+" - "+row.type_name,
      sortable: true,
      left: true,
    },
    {
      name: 'Investor Name	',
      selector: 'full_name',
      sortable: true,
      left: true,
    },
    {
      name: 'Instrument Name',
      selector: 'instr_name',
      sortable: true,
      left: true,
    },
    {
      name: 'Yearly Amount',
      cell: (row) => row.yearly_amount,
      sortable: false,
      center: true,
      footer: 10000000000
    },
    {
      name: 'Current Value',
      cell: (row) => formatAmount(row.till_date),
      sortable: false,
      center: true,
    },
    {
      name: 'Action',
      cell: (row) => <div><span onClick={() => DeleteAsset(row.invest_id)}><i className='fa fa-trash' style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span><span onClick={() => EditAsset(row.invest_id, row.type)}><i className='fas fa-pencil-alt' style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i> </span>{ row.type === 6 || row.type === 7 ? <> {row.linked === 1 ? <span onClick={() => MapAsset(row.invest_id, '0')}><i className="fas fa-unlink"></i></span> : <span onClick={() => MapAsset(row.invest_id, '1')}><i className="fas fa-link"></i></span>}</> : ''}</div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const MapAsset = (id, linked) => {

    SweetAlert.fire({
      title: "Are you sure?",
      text: linked === "1" ? "you want to Link this Asset?" : "you want to unLink this Asset?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF0000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios.post(`${apiUrl}investment/map`, { profile_id: sessionStorage.getItem('profile_id'), 'invest_id': id, linked:linked }).then(
          function (response) {


            SweetAlert.fire({
              toast: true,
              icon: 'success',
              title: linked === "1" ? 'Assets Successfully Linked.' : 'Assets Successfully unLinked.',
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
              }
            }).then(function () {
              getAssetsData(property);
            });
          }
        )
      }
    })

  }

  const EditAsset = (id, type) => {

    var url = '';
    switch (type) {
      case 1: //Equity
        url = "/edit-equity/";
        break;
      case 2: //deposits
        url = "/edit-deposits/";
        break;
      case 3: //mf
        url = "/edit-mf/";
        break;
      case 4: //Providend Fund
        url = "/edit-life/";
        break;
      case 5: //Providend Fund
        url = "/edit-ppf/";
        break;

      case 6: //Life Insurance
        url = "/edit-gold/";
        break;

      case 7: //General Insurance
        url = "/edit-real-estate/";
        break;

      case 11: //nps Insurance
        url = "/edit-nps/";
        break;
      default:
    }

    window.location.href = url + id;

  }

  //eslint-disable-next-line
  const handleChange = (e) => {
    setProperty(e.target.value);
  };




  const DeleteAsset = (id) => {


    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this Asset?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF0000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios.post(`${apiUrl}investment/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'invest_id': id }).then(
          function (response) {


            SweetAlert.fire({
              toast: true,
              icon: 'success',
              title: 'Assets Successfully Deleted.',
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
              }
            }).then(function () {
              window.location.href = "/track-portfolio";
            });
          }
        )
      }
    })
  }

  const [grandTill, setGrandTill] = useState("");
  const [grandYearly, setGrandYearly] = useState("");
  const [grandTillPerc, setGrandTillPerc] = useState("");
  const [getCheck, setGetCheck] = useState("");
  const getPortfolio = (e) => {
    getAssetsData(e, 'Y');
  }

  const getAssetsData = (property = "1", primary_flag = "N") => {
    const profile_id = sessionStorage.getItem('profile_id');
    axios.post(`${apiUrl}investment/recommended-assets`, { 'profile_id': profile_id, 'radio_type': property, 'primary_flag': primary_flag }).then(
      (response, data) => {

        setAssets(response.data.main_assets);
        setSummary(response.data.summary_assets);
        setTilldate(response.data.grand_total.till_date_grand);
        setYtotal(response.data.grand_total.yearly_grand);

        setNested(response.data.nested.data);

        setGrandTill(response.data.nested.grand_till_date);
        setGrandYearly(response.data.nested.grand_yearly);
        setGrandTillPerc(response.data.nested.till_perc);

        setGetCheck(property);


      });
  }

  useEffect(() => {

    if (sessionStorage.getItem('propery_radio') !== null) {
      getAssetsData(sessionStorage.getItem('propery_radio') === "/" ? 1 : 3, "Y");
    } else {
      getAssetsData(property);
    }

  }, [property]);

  return (
    <Fragment>
      <Header />



      <div className="container">

        <div className="email-wrap bookmark-wrap">
          <Row>
            <h1 className='mt-4 mb-4'>Add New Investment</h1>
            <Col xl="12" md="12" className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent">

                <div className="pl-0">


                  <div className={`details-bookmark text-center`}>
                    <Row id="bookmarkData">

                      <Col xl="2 xl-50" md="3">
                        <Card className="card-with-border bookmark-card o-hidden">
                          <Link className="details-website" to="/add-deposits">
                            <div className="desciption-data">
                              <div className="title-bookmark">
                                <h6 className="title_0">{"ADD Deposits"}</h6>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>



                      <Col xl="2 xl-50" md="3">
                        <Card className="card-with-border bookmark-card o-hidden">
                          <Link className="details-website" to="/add-mf">
                            <div className="desciption-data">
                              <div className="title-bookmark">
                                <h6 className="title_0">{"ADD Mutual Fund"}</h6>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>


                      <Col xl="2 xl-50" md="3">
                        <Card className="card-with-border bookmark-card o-hidden">
                          <Link className="details-website" to="/add-equity">
                            <div className="desciption-data">
                              <div className="title-bookmark">
                                <h6 className="title_0">{"ADD Equity/PMS"}</h6>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>


                      <Col xl="2 xl-50" md="3">
                        <Card className="card-with-border bookmark-card o-hidden">
                          <Link className="details-website" to="/add-ppf">
                            <div className="desciption-data">
                              <div className="title-bookmark">
                                <h6 className="title_0">{"ADD PPF/EPF"}</h6>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>



                      <Col xl="2 xl-50" md="3">
                        <Card className="card-with-border bookmark-card o-hidden">
                          <Link className="details-website" to="/add-nps">
                            <div className="desciption-data">
                              <div className="title-bookmark">
                                <h6 className="title_0">{"ADD NPS"}</h6>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>


                      <Col xl="2 xl-50" md="3">
                        <Card className="card-with-border bookmark-card o-hidden">
                          <Link to="/add-real-estate" className="details-website">
                            <div className="desciption-data">
                              <div className="title-bookmark">
                                <h6 className="title_0">{"ADD Real Estate"}</h6>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>

                      <Col xl="2 xl-50" md="3">
                        <Card className="card-with-border bookmark-card o-hidden">
                          <Link to="/add-insurance" className="details-website">
                            <div className="desciption-data">
                              <div className="title-bookmark">
                                <h6 className="title_0">{"ADD Insurance"}</h6>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>

                      <Col xl="2 xl-50" md="3">
                        <Card className="card-with-border bookmark-card o-hidden">
                          <Link to="/add-gold" className="details-website">
                            <div className="desciption-data">
                              <div className="title-bookmark">
                                <h6 className="title_0">{"ADD GOLD"}</h6>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </Col>

                    </Row>
                  </div>


                </div>

              </div>
            </Col>
          </Row>


          <div className="row">

            <Col lg="12 box-col-12" xl="12 xl-100">
              <Card>
                <CardHeader>
                  <h5>{"Track Portfolio"} </h5>

                  <div className="mb-3 mt-3">

                    <div className="m-checkbox-inline">

                      <label for="edo-ani" className="form-label" onChange={() => getPortfolio(1)}>
                        <input
                          id="edo-ani"
                          name="rdo-ani"
                          type="radio"
                          className="radio_animated form-check-input"
                          value={1}
                          checked={getCheck === 1 ? "checked" : ""}
                        />{"All Assets"}</label>

                      <label for="edo-ani3" className="form-label" onChange={() => getPortfolio(3)}>
                        <input
                          id="edo-ani3"
                          name="rdo-ani"
                          type="radio"
                          className="radio_animated form-check-input"
                          value={3}
                          checked={getCheck === 3 ? "checked" : ""}
                        />{"Linked Assets"}</label>
                      <label for="edo-ani2" className="form-label" onChange={() => getPortfolio(2)}>
                        <input
                          id="edo-ani2"
                          name="rdo-ani"
                          type="radio"
                          className="radio_animated form-check-input"
                          value={2}
                          checked={getCheck === 2 ? "checked" : ""}
                        />{"Exclude Property"}</label>
                    </div>
                  </div>

                </CardHeader>
                <CardBody className="tabbed-card">



                  <Nav className="nav-pills nav-primary">
                    <NavItem>
                      <NavLink className={activeTab2 === '1' ? 'active' : ''} onClick={() => setActiveTab2('1')}>
                        <i className="icofont icofont-ui-home"></i> {"Assets"}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={activeTab2 === '2' ? 'active' : ''} onClick={() => setActiveTab2('2')}>
                        <i className="icofont icofont-man-in-glasses"></i>{"Summary Assets"}
                      </NavLink>
                    </NavItem>
                    <br />



                  </Nav>


                  <TabContent activeTab={activeTab2}>
                    <TabPane tabId="1">
                      <div className="best-seller-table responsive-tbl">
                        <div className="item">
                          <div className="table-responsive product-list">

                            <DataTable
                              data={assets}
                              columns={tableColumns}
                              striped={true}
                              center={true}
                              persistTableHead
                            />
                            <Table>
                              <tfoot>
                                <th>Total</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{formatAmount(ytotal)}</th>
                                <th>{formatAmount(tilldate)}</th>
                                <th></th>
                              </tfoot>
                            </Table>




                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="best-seller-table responsive-tbl">
                        <div className="item">
                          <div className="table-responsive product-list">
                            <Table bordered hover>
                              <thead>
                                <tr>
                                  <th className="f-22">{"Asset Class"}</th>
                                  <th>{"Asset Type	"}</th>
                                  <th>{"Yearly Amount	"}</th>
                                  <th>{"Till Date"}</th>
                                  <th>{"% Till Date"}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {nested.map((person, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td colSpan={4}>{person.assetName}</td>
                                      </tr>


                                      {person.assets.map((pet, data) => {
                                        return (
                                          <tr key={data}>
                                            <td></td>
                                            <td>{pet.asset_type}</td>
                                            <td>{formatAmount(pet.yearly_amount)}</td>
                                            <td>{formatAmount(pet.till_date)}</td>
                                            <td>{pet.till_perc}</td>
                                          </tr>
                                        );
                                      })}

                                      <tr>
                                        <td colSpan={2}><strong>{person.assetName} Total</strong></td>
                                        <td><strong>{formatAmount(person.asset_class_yearly_amount)}</strong></td>
                                        <td><strong>{formatAmount(person.asset_class_till_total)}</strong></td>
                                        <td><strong>{person.asset_till_perc}</strong></td>

                                      </tr>
                                    </>
                                  );
                                })}

                              </tbody>

                              <tfoot style={{ 'border-top': '1px solid #ecf3fa' }}>
                                <tr>
                                  <th><strong>Grand Total</strong></th>
                                  <th></th>
                                  <th><strong>{formatAmount(grandYearly)}</strong></th>
                                  <th><strong>{formatAmount(grandTill)}</strong></th>
                                  <th><strong>{grandTillPerc}</strong></th>
                                </tr>
                              </tfoot>

                            </Table>
                          </div>
                        </div>
                      </div>
                    </TabPane>

                  </TabContent>
                </CardBody>
              </Card>
            </Col>


          </div>

        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

