import React from 'react';
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../constants/global'
import formatAmount from 'indian-currency-formatter';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import EditIncome from './popups/EditIncome';
import EditExpense from './popups/EditExpense';
import Knob from "knob";
import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'

export default function Cashflow() {

   const [emi, setEmi] = useState([]);
   const [commitements, setCommitements] = useState([]);
   const [incomeSum, setIncomeSum] = useState('');
   const [expenseSum, setExpenseSum] = useState('');
   const [emiSum, setEmiSum] = useState('');
   const [investmentSum, setInvestmentSum] = useState('');
   const [ligiSum, setLigiSum] = useState('');
   const [date, setDate] = useState('');
   const [investPerc, setInvestPerc] = useState(0);

   const [incomeModel, setIncomeModel] = useState(false)
   const incomeToggle = () => setIncomeModel(!incomeModel);
   const IncomeModel = () => {
      setIncomeModel(!incomeModel);
   }

   const [expenseModel, setExpenseModel] = useState(false)
   const expenseToggle = () => setExpenseModel(!expenseModel);
   const ExpenseModel = () => {
      setExpenseModel(!expenseModel);
   }

   const editInvest = (type, id) => {

      if(type === 4){
         window.location.href = "/edit-life/"+id;
      }else{
         window.location.href = "/edit-health/"+id;
      }
      
   }

   const editEmi = (id) =>{
      window.location.href = "/edit-emi/"+id;
   }

   const deleteInvest = (id) => {


      SweetAlert.fire({
         title: "Are you sure?",
         text: "you want to delete this investment?",
           type: "warning",
           showCancelButton: true,
           confirmButtonColor: "#FF0000",
           confirmButtonText: "Yes",
           cancelButtonText: "Cancel",
           closeOnConfirm: false,
           closeOnCancel: false
       })
       .then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {
                 axios.post(`${apiUrl}investment/delete`, {profile_id:sessionStorage.getItem('profile_id'), 'invest_id':id}).then(
                    function(response){
                      SweetAlert.fire(
                    'Investment',
                    'Successfully Deleted.',
                    'success'
                ).then(function () {
                    window.location.href = "/cashflow";
                });
                    }
                 )
           }
    });
}

   useEffect(() => {

      setDate(new Date().toDateString());

      sessionStorage.removeItem('subType');
      sessionStorage.removeItem('giStep');

      const profile_id = sessionStorage.getItem('profile_id');
      axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
         (response, data) => {
            if (response.data.status === 100) {
               setEmi(response.data.liability);
               setCommitements(response.data.risk_inv);
               setIncomeSum(response.data.incomeSum.incomeSum);
               setExpenseSum(response.data.expenseSum.expenseSum);
               setEmiSum(response.data.emiSum.emiSum);
               setInvestmentSum(response.data.incomeSum.incomeSum - response.data.outflow);
               setLigiSum(response.data.ligiSum.lifeSum);
               
               setInvestPerc(response.data.incomeSum.incomeSum !== null ?  Math.round(((response.data.incomeSum.incomeSum - response.data.outflow)/response.data.incomeSum.incomeSum)*100) : 0);

               var ordervalue1 = Knob({
                  value: response.data.incomeSum.incomeSum === null ? 0 : Math.round(((response.data.incomeSum.incomeSum - response.data.outflow)/response.data.incomeSum.incomeSum)*100),
                  angleOffset: -10,
                  thickness: 0.125,
                  width: 120,
                  fgColor: "#f44336",
                  readOnly: true,
                  dynamicDraw: true,
                  tickColorizeValues: true,
                  bgColor: '#eef5fb',
                  lineCap: 'round',
                  displayPrevious: false
               });
               document.getElementById('ordervalue1').appendChild(ordervalue1);

            }
         });



   }, []);

   return (
      <React.Fragment>
         <Header />
         <SideMenu />
         <div className="container pb-70">
            <div className="row">
               <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="cashflow_wpr">
                     <div className="cashflow_title">CASHFLOW SUMMARY</div>
                     <div className="cashflow_box">
                        <div>

                           
                           <div className="cashflow_sum_title p-l-20 p-b-10 p-t-20">
                           
                           <div className="cashflow_commonbox_title_l">
                              <span className="circle blueclr m-r-20" /> Available For Investments
                           </div>
                           </div>
                           <div className="investments_amnt p-l-20">
                              {
                                 investmentSum != null ? (<span className="m-r-20" >{formatAmount(investmentSum)} </span>) :
                                 (<span className="m-r-20" >  ₹ 0</span>)
                              }
                              </div>

                              
                           <div className="savingtext p-l-20 p-t-20">Saving Rate </div>
                           <div className="savingtext p-l-20">{investPerc}%</div>
                           <div className="updatetext p-l-20 p-t-20 p-b-5">Updated on {date}</div>
                        </div>
                        <div className='pr20'>
                        <div className="knob1" id="ordervalue1"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="inflow_wpr">
                     <div className="inflow_title">
                        Inflows <span className="downarrow m-l-15" />
                     </div>
                     <div className="cashflow_commonbox p-10">
                        <div className="cashflow_commonbox_title">
                           <div className="cashflow_commonbox_title_l">
                              <span className="circle greenclr m-r-20" /> All Income
                           </div>
                           <div className="cashflow_commonbox_title_r">
                              <span className="grayclr cursor_pointer f-18" onClick={() => IncomeModel()}><i className="fas fa-ellipsis-v" /></span>
                              <Modal isOpen={incomeModel} toggle={incomeToggle}>
                                 <ModalHeader toggle={incomeToggle}>{"Edit Income"}</ModalHeader>
                                 <ModalBody>
                                    <EditIncome />
                                 </ModalBody>
                              </Modal>

                           </div>
                        </div>
                        <div className="f-20 f-w-700 m-t-10 p-b-25 dgray"><i className='fas fa-rupee-sign'></i> {incomeSum === null ? '0' : formatAmount(incomeSum)}</div>
                     </div>
                  </div>
               </div>
               <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="inflow_wpr">
                     <div className="inflow_title">
                        Outflows <span className="downarrow m-l-15" />
                     </div>
                     <div className="cashflow_commonbox p-10">
                        <div className="cashflow_commonbox_title">
                           <div className="cashflow_commonbox_title_l">
                              <span className="circle dgoldclr m-r-20" /> Expenses
                           </div>
                           <div className="cashflow_commonbox_title_r">
                              <span className="grayclr cursor_pointer f-18" onClick={() => ExpenseModel()}><i className="fas fa-ellipsis-v" /></span>
                              <Modal isOpen={expenseModel} toggle={expenseToggle}>
                                 <ModalHeader toggle={expenseToggle}>{"Edit Expense"}</ModalHeader>
                                 <ModalBody>
                                    <EditExpense />
                                 </ModalBody>
                              </Modal>
                           </div>
                        </div>
                        <div className="f-20 f-w-700 m-t-10 p-b-25 dgray"> <i className='fas fa-rupee-sign'></i> {expenseSum === null ? '0' : formatAmount(expenseSum)}</div>
                     </div>
                  </div>
               </div>
               <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="inflow_wpr">
                     <div className="inflow_title">
                        &nbsp;
                     </div>
                     <div className="cashflow_commonbox p-10">
                        <div className="cashflow_commonbox_title">
                           <div className="cashflow_commonbox_title_l">
                              <span className="circle lgoldclr m-r-20" /> Yearly EMIs
                           </div>
                           <div className="cashflow_commonbox_title_r">
                              <span className="grayclr cursor_pointer f-18"><i className="mdi mdi-chevron-down" /></span>
                           </div>
                        </div>
                        <div className="f-20 f-w-700 m-t-10 p-b-25 dgray"><i className='fas fa-rupee-sign'></i> {emiSum === null ? '0' : formatAmount(emiSum)}</div>
                     </div>
                  </div>
               </div>
               <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="inflow_wpr">
                  <div className="inflow_title">
                        &nbsp;
                     </div>
                     <div className="cashflow_commonbox p-10">
                        <div className="cashflow_commonbox_title">
                           <div className="cashflow_commonbox_title_l">
                              <span className="circle dblueclr m-r-20" /> Commitments
                           </div>
                           <div className="cashflow_commonbox_title_r">
                              <span className="grayclr cursor_pointer f-18"><i className="mdi mdi-chevron-down" /></span>
                           </div>
                        </div>
                        <div className="f-20 f-w-700 m-t-10 p-b-25 dgray"><i className='fas fa-rupee-sign'></i> {ligiSum === null ? '0' : formatAmount(ligiSum)}</div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="emi_wpr">
                  <div className="emi_title">
                     <span className="emi_title_l"><span className="circle lgoldclr m-r-20" /> EMIs</span>
                     <span className="emi_title_r p-l-50"><Link to="/add-emi" className="f-14">+Add EMIs</Link></span>
                  </div>
                  <div className="emi_outer row">
                     {// eslint-disable-next-line
                        emi.map((item, index) => {
                           return (
                              <>
                                 <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12" key={index} onClick={()=>editEmi(item.liability_id)}>
                                    <div className="emi_commonbox">
                                       <div className="emi_commonbox_title f-20 f-w-700" key={index}>
                                          {item.inv_name} <span className="f-14 f-w-400">Next 10 years</span>
                                       </div>
                                       <div className="emi_info m-t-40">
                                          <div>
                                             <div className="f-14 f-w-400">Outstanding</div>
                                             <div className="f-16 f-w-700">₹ {formatAmount(item.outstanding_loan)}</div>
                                          </div>
                                          <div className="p-l-70">
                                             <div className="f-14 f-w-400">EMI</div>
                                             <div className="f-16 f-w-700">₹ {formatAmount(item.emi)}</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </>
                           )
                        })}
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="commitements_wpr">
                  <div className="commitements_title">
                     <span className="commitements_title_l"><span className="circle dblueclr m-r-20" /> Commitments</span>
                     <span className="commitements_title_r p-l-50"><Link className="f-14" to="/add-insurance">+Add</Link></span>
                  </div>
                  <div className="commitements_outer row">
                     {// eslint-disable-next-line
                        commitements.map((item, index) => {
                           return (
                              <>
                                 <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-5" key={index}>
                                    <div className="commitements_commonbox">
                                       <div className="commitements_commonbox_title">
                                          <div className="f-20 f-w-700"><img src={item.type === 4 ? "assets/images/Life.svg" : "assets/images/Health.svg"} alt="img" className='commitements_logo' />{item.instr_name}</div>
                                          <div className="dotNav">
                                          <Dropdown>
                                             <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
                                                <i className="fas fa-ellipsis-v" />
                                             </Dropdown.Toggle>

                                             <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>editInvest(item.type, item.invest_id)}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>deleteInvest(item.invest_id)}>Delete</Dropdown.Item>
                                             </Dropdown.Menu>
                                          </Dropdown>
                                          </div>
                                       </div>
                                       <div className="commitmentstabs_wpr m-t-20">
                                          <div className="commitmentstabs">{item.type_name}</div>
                                          <div className="commitmentstabs m-l-15">{item.insuredName}</div>
                                       </div>
                                       <div className="commitments_info m-t-40">
                                          <div>
                                             <div className="f-14 f-w-400">Cover</div>
                                             <div className="f-16 f-w-700">₹ {item.insured_value != null ? formatAmount(item.insured_value) : 'NA'}</div>
                                          </div>
                                          <div className="p-l-70">
                                             <div className="f-14 f-w-400">Premium</div>
                                             <div className="f-16 f-w-700">₹ {item.invest_amount != null ? formatAmount(item.invest_amount) : 'NA'}</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </>
                           )
                        })}
                  </div>
               </div>
            </div>
         </div>
         <div className="clear"></div>
         <Footer />
      </React.Fragment>
   );
}
