import React from 'react';

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="footerStyle">Copyright © 2022. Finnovate Financial Services Pvt Ltd. All Rights Reserved.</div>
            </div>
        </footer>
    );
}
