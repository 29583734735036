import React from 'react'
import { apiUrl, } from '../constants/global';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { useEffect } from 'react';

export default function AutoLogin() {

   useEffect(() => {

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const email = params.get('email');

      const Postdata = {
         "email": email
      };

      axios.post(`${apiUrl}admin/auto-login`, Postdata)
         .then(function (response) {

            if (response.data.status === 101) {
               SweetAlert.fire({
                  toast: true,
                  icon: 'success',
                  title: 'No Plan Found/Created.',
                  animation: false,
                  position: 'top-right',
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                     toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                     toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                  }
               }).then(function () {
     
                  window.location.href = "/sign-in";
                   
               });
            } else {
               SweetAlert.fire({
                  toast: true,
                  icon: 'success',
                  title: 'Signed in Successfully',
                  animation: false,
                  position: 'top-right',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                     toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                     toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                  }
               }).then(function () {

                  sessionStorage.setItem('token', response.data.token);
                  const pdata = { "email": email };
                  axios.post(`${apiUrl}profile/view`, pdata).then(function (response) {
                     sessionStorage.setItem('profile_id', response.data.profile_id);
                     sessionStorage.setItem('full_name', response.data.full_name);
                     if (response) {
                        window.location.href = "/plan";
                     }
                  });

                  //

               });
            }

         })
         .catch(e => {
            SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
         });

   });

   return (<> <div>auto login</div></>)
}
