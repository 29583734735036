import React, { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import { Button } from 'reactstrap'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';

export default function EditHealthSuggested({ data }) {

    console.log(data);
    const [validator, showValidationMessage] = useValidator();
    // eslint-disable-next-line
    const [selectOpt, setSelectOpt] = useState(formatAmount(data.income));

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'select_opt':
                setSelectOpt(e.target.value);
                break;

            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const postData = {
                "goal_type": "Health Insurance",
                "goal_id": data.goal_id,
                "aspire": data.aspire,
                "profile_id": data.profile_id,
                "profile_details_id": data.profile_details_id,
                "income_grow": data.income_grow * 100,
                "inflation": data.goal_inflation * 100,
                "select_opt": selectOpt,
                "income": data.income,
                "gi_dependents": data.gi_dependents,
                "dob": data.current_age,
                "api_type": 'insert'
            };

            axios.post(`${apiUrl}goals/addhealth`, postData)
                .then(function (response) {
                    SweetAlert.fire(
                        'Goals',
                        'Successfully Updated Goal.',
                        'success'
                    ).then(function () {
                        window.location.href = "/risk-goals"
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-row">
                <div className='row'>


                    <h6>Minimum Suggested </h6>
                    <div className="radio-one"><input type="radio" id="test-11" name="select_opt" value="regular" onClick={handleChange} /><label for="test-11"> {data.min_suggested}</label></div>
                    <h6>Maximum Suggested</h6>
                    <div className="radio-one"><input type="radio" id="test-1" name="select_opt" value="income_grow" onClick={handleChange} /><label for="test-1"> {data.max_suggested} </label></div>


                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-12">
                            <Button color="success" className="mr-1" type="button" onClick={handleSubmit}>Save</Button>
                            <Button color="primary">{"Cancel"}</Button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
