import React, { Fragment, useState } from 'react'
import useValidator from '../../hooks/useValidator'

export default function Step2({ step, ChangeStep, goalCount }) {

    const [validator, showValidationMessage] = useValidator();
    const [investType, setInvestType] = useState();
    const handleSubmit = () => {
        if (validator.allValid()) {
            ChangeStep(step + 1);
        } else {
            showValidationMessage(true);
        }
    }

    const handleChange = e => {
        if (e.target.name === 'investType') {
            
            setInvestType(e.target.value);
            if(e.target.value === "2" ){
                if(goalCount <= 0){
                    sessionStorage.setItem('page_referer', '/strategy');
                    window.location.href = "/create-profile";
                }
            }
            sessionStorage.setItem('investType', e.target.value);
            
        }
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="financiali-tilte"></div>
                    <div className=" box-shadow Creating-Financial">
                        <h1> Choose One </h1>
                        <div className="form-group">

                            <div className="m-checkbox-inline">
                                <label for="investType">
                                    <input className="radio_animated" id="edo-ani" type="radio" name={"investType"} value={1} onChange={handleChange} />{"I know the  amount to invest"}
                                </label>

                            </div>

                            <div className="m-checkbox-inline">
                                <label for="investType">
                                    <input className="radio_animated" id="edo-ani" type="radio" name={"investType"} value={2} onChange={handleChange} />{"Create my Goals Plan to determine the amount"}
                                </label>

                            </div>

                            {validator.message("investType", investType, "required", {
                                messages: {
                                    required: "Choose one Option"
                                }
                            })}

                        </div>
                        <div className="bottombtn">
                            <button className="back" disabled> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
            </div>
        </Fragment>
    )
}
