import React, { useEffect, useState, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import { apiUrl } from '../constants/global'
import { Button } from 'reactstrap'
import SweetAlert from 'sweetalert2'

export default function SetRoiOption() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // eslint-disable-next-line
    const [calSummaryAsset, setCalSummaryAsset] = useState();

    // eslint-disable-next-line
    const [calRiskAsset, setCalRiskAsset] = useState();

    // eslint-disable-next-line
    const [roiOption, setRoiOption] = useState();
    // eslint-disable-next-line
    const [allocation, setAllocation] = useState();
    const [terms, setTerms] = useState(null);

    console.log('roiOption' + roiOption);
    const onSubmit = (data) => {


        setRoiOption(data.roiOption);


        const postData = {
            "roiOption": data.roiOption,
            "roi": data.roiOption === "1" ? calRiskAsset : calSummaryAsset,
            "profile_id": sessionStorage.getItem('profile_id')
        }

        axios.post(`${apiUrl}profile/update-roi`, postData).then(
            (response, data) => {

                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: 'ROI Successfully Updated.',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                }).then(function () {

                    const gpostData = { profile_id: sessionStorage.getItem('profile_id') };
                     axios.post(`${apiUrl}goals/reset`, gpostData)
                         .then(function (response) {
                             axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id'), roiChange:1, roi: roiOption === "1" ? calRiskAsset : calSummaryAsset, asset_alloc:allocation}).then(
                                 (response, data) => {
 
                                     window.location.href = sessionStorage.getItem('page_referer') !== null ? sessionStorage.getItem('page_referer') : "/adjust";
 
                                 }
                             );
                         })


                });

            }
        );
    }
    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {
                if (response.data.status === 100) {
                    setRoiOption(response.data.profgoalSummary.roiOption);

                    setTerms({ roiOption: response.data.profgoalSummary.roiOption });

                    setAllocation(response.data.profgoalSummary.goal_asset_alloc);

                    const profile_id = sessionStorage.getItem('profile_id');

                    axios.post(`${apiUrl}summary/calculate-roi`, { profile_id: profile_id, asset_alloc: response.data.risk_profile.max_val }).then(
                        (response, data) => {
                            setCalRiskAsset((response.data.roi * 100).toFixed(2));
                        });

                    axios.post(`${apiUrl}summary/calculate-roi`, { profile_id: profile_id, asset_alloc: response.data.profgoalSummary.goal_asset_alloc }).then(
                        (response, data) => {
                            setCalSummaryAsset((response.data.roi * 100).toFixed(2));
                        });
                }
            });

    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(terms);
        // eslint-disable-next-line
    }, [terms]);

    return (
        <Fragment>


            <div className="form-bookmark">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row d-flex justify-content-center'>


                        <div className='col-md-12 d-flex justify-content-center'>

                            <div class="plans">
                                <div class="title">Choose ROI</div>
                                <label class="plan basic-plan" for="basic">
                                    <input type="radio" name="roiOption" id="basic" value="1" {...register('roiOption', { required: true })} />
                                    <div class="plan-content">
                                        <div class="plan-details">
                                            <span>ROI: {calRiskAsset}%</span>
                                            <p>Asset Allocation As Per Risk Profile</p>
                                        </div>
                                    </div>
                                </label>
                                <br />
                                <label class="plan complete-plan" for="complete">
                                    <input type="radio" id="complete" name="roiOption" value="2" {...register('roiOption', { required: true })} />
                                    <div class="plan-content">
                                        <div class="plan-details">
                                            <span>ROI: {calSummaryAsset}%</span>
                                            <p>Asset Allocation as Per Goals</p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <span className="srv-validation-message"><ErrorMessage errors={errors} name={"gname"} message="This is required" /></span>
                        </div>



                    </div>
                    <Button color="success" type="submit" className='m-2'>{"Save"}</Button>
                    <Button color="primary" className="m-2" type='button'>{"Cancel"}</Button>
                </form>

            </div>
        </Fragment>
    )
}
