import React, { Fragment } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

export default function GoalDashboard() {
  return (
    <Fragment>
      <Header />

      <div className="container">
        <div className="email-wrap bookmark-wrap">

          <div class="row">

            <h1 className='mt-4 mb-4'>Add New Goal</h1>

            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/retirement"}>
              <div class="service-one__single">
                <img className="img-fluid w-50 mb-3" src="assets/images/Retirement.svg" alt="lohgo" />
                <h3>{"Retirement"}</h3>
              </div>
            </div>
            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/marriage"}>
              <div class="service-one__single">
                <img className="img-fluid w-50 mb-3" src="assets/images/Marriage.svg" alt="lohgo" />
                <h3>{"Marriage"}</h3>
              </div>
            </div>
            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/buyhome"}>
              <div class="service-one__single">
                <img className="img-fluid w-50 mb-3" src="assets/images/House.svg" alt="lohgo" />
                <h3>{"Buy Home"}</h3>
              </div>
            </div>
            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/buycar"}>
              <div class="service-one__single">
                <img className="img-fluid w-50 mb-3" src="assets/images/Car.svg" alt="lohgo" />
                <h3>{"Buy Car"}</h3>
              </div>
            </div>
            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/vacation"}>
              <div class="service-one__single">
                <img className="img-fluid w-50 mb-3" src="assets/images/Wealth.svg" alt="lohgo" />
                <h3>{"Vacation"}</h3>
              </div>
            </div>
            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/education"}>
              <div class="service-one__single">
                <img className="img-fluid w-50 mb-3" src="assets/images/Education.svg" alt="lohgo" />
                <h3>{"Education"}</h3>
              </div>
            </div>
            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/wealth"}>
              <div class="service-one__single">
                <img className="img-fluid w-50 mb-3" src="assets/images/Wealth.svg" alt="lohgo" />
                <h3>{"Custom Goal"}</h3>
              </div>
            </div>
          </div>


          <hr />

          <div class="row">

            <h2 className='mt-4 mb-4'>Life Insurance and Health Insurance</h2>

            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/life"}>
              <div class="service-one__single">
              <img className="img-fluid w-50 mb-3" src="assets/images/Life.svg" alt="lohgo" />
                <h3>{"Life Insurance"}</h3>
              </div>
            </div>
            <div class="service-one__col wow fadeInUp" onClick={() => window.location.href = "/add-goal/health"}>
              <div class="service-one__single">
              <img className="img-fluid w-50 mb-3" src="assets/images/Health.svg" alt="lohgo" />
                <h3>{"Health Insurance"}</h3>
              </div>
            </div>

            <div class="service-one__col wow fadeInUp">
             
            </div>

            <div class="service-one__col wow fadeInUp">
              
            </div>

            <div class="service-one__col wow fadeInUp">
              
            </div>

            <div class="service-one__col wow fadeInUp">
              
            </div>

          </div>

        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
