import React, { Fragment, useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export default function HealthIncome({title, StepChange, steps, setPercentage, percentage }) {

    const [validator, showValidationMessage] = useValidator();
    const [income, setIncome] = useState("");
    const [amt, setAmt] = useState("");

    const handleChange = e => {
        const { value } = e.target;
        setIncome(formatAmount(value.replace(/,/g, "")));
        numtoWord(e);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleSubmit = () => {
        if (validator.allValid()) {
            sessionStorage.setItem('income', income);
            StepChange(steps + 1);
        } else {
            showValidationMessage(true);
        }
    };

    const numtoWord = e => {
        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');
        setAmt(ucwords(amountInWords));
    }

    useEffect(() => {
        const ucwords = require('ucwords');
        const profile = JSON.parse(sessionStorage.getItem('ProfSummary'));
        if(sessionStorage.getItem('g_relation') === 'self'){
            setIncome(formatAmount(profile.profIncomeData.IncomeSelf.replace(/,/g, "")));
            const amountInWords = numWords(profile.profIncomeData.IncomeSelf);
            setAmt(ucwords(amountInWords));
        }else{
            setIncome(formatAmount(profile.profIncomeData.IncomeSpouse.replace(/,/g, "")));
            const amountInWords = numWords(profile.profIncomeData.IncomeSpouse);
            setAmt(ucwords(amountInWords));
        }

    }, [])

    return (
        <Fragment>


            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                    <h1> For {sessionStorage.getItem('g_name')}</h1>
                        <div className="input-box ruppys yearly">
                            <label> Your current Income </label>
                            <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"income"} value={income} onChange={handleChange} onKeyPress={numtoWord} />
                            <span className="text-word">{amt}</span>
                            {validator.message("income", income, "required", {
                                messages: {
                                    required: "current Income is required"
                                }
                            })}
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton checked={true} width={100} height={40} onlabel='Yearly' offlabel='Monthly' style="toggleBtn toggleBtnC"/>
                        </div>

                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
                        </div>

                    </div>


                </div>
            </div>


        </Fragment>


    )
}

