import React from 'react';
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';

export default function Transact() {
   return (
      <React.Fragment>
         <Header />
         <SideMenu />
         <div className="container">
            <div className="row">
               <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="name-box">
                     <h1 className="h1Inn"> Transact  </h1>
                  </div>
               </div>
               <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="my-goals box-shadow">
                  </div>
               </div>
            </div>
         </div>
         <div className="clear"></div>
         <Footer />
      </React.Fragment>
   );
}