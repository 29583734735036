import React, { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import { Button } from 'reactstrap'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import formatAmount from 'indian-currency-formatter';

export default function EditCashflowOpt({ fund, totalSaving, cashflowOption, customAmount,  refUrl}) {

    console.log('fund '+ fund + ' totalSaving '+totalSaving+ ' cashflowOption '+cashflowOption+ ' customAmount'+customAmount);
    const [validator, showValidationMessage] = useValidator();
    // eslint-disable-next-line
    const [option, setOption] = useState(cashflowOption);
    // eslint-disable-next-line
    const [custom, setCustom] = useState(customAmount !== "0.00" ? formatAmount(Math.round(customAmount)) : formatAmount(Math.round(fund)));

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'option':
                setOption(e.target.value);
                break;

            case 'custom':
                
                setCustom(formatAmount(Math.round(e.target.value.replace(/,/g, ""))));
                break;

            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        if (validator.allValid()) {

            axios.post(`${apiUrl}profile/cashflow-option`, { profile_id: sessionStorage.getItem('profile_id'), 'cashflow_opt': option, 'custom_value': custom.replace(/,/g, "") }).then(
                function (response) {
                    SweetAlert.fire(
                        'Summary',
                        'Cashflow Option Updated Successfully.',
                        'success'
                    ).then(function () {
                        window.location.href = refUrl;
                    });
                }
            )

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-row">
                <div className='row'>
                    <div className="col-md-4">
                        <label className="btn active">
                            <input type="radio" name='option' onClick={handleChange} value="1" checked={option === "1" ? "checked" : ''}/><span>  Suggested Amount</span>
                            <br/><strong>{formatAmount(Math.round(fund))}</strong>
                        </label>
                    </div>
                    <div className="col-md-4">
                        <label className="btn active">
                            <input type="radio" name='option' onClick={handleChange} value="2" checked={option === "2" ? "checked" : ''}/><span>  This Year Saving</span>
                            <br/><strong>{formatAmount(Math.round(totalSaving))}</strong>
                        </label>
                    </div>
                    <div className="col-md-4">
                        <label className="btn active">
                            <input type="radio" name='option' onClick={handleChange} value="3" checked={option === "3" ? "checked" : ''}/><span>  Target Amount</span>
                            <br/><input type={"text"} name='custom' onChange={handleChange} width={70} className='form-control' value={custom}/>
                        </label>
                    </div>


                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-12">
                            <Button color="success" className="mr-1" type="button" onClick={handleSubmit}>Save</Button>
                            <Button color="primary">{"Cancel"}</Button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}