import React, { useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import numWords from 'num-words';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import { Progress } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'

export default function EditInsurance() {

    let { investId } = useParams();
    const [validator, showValidationMessage] = useValidator();
    const [invest_amount, setinvest_amount] = useState();
    const [insured_value, setinsured_value] = useState();
    const [instr_name, setinstr_name] = useState();
    const [ppt, setppt] = useState(1)
    const [policy_term, setpolicy_term] = useState(1)
    const ucwords = require('ucwords');
    const [frequency, setFrequency] = useState(1);
    const [outstanding_amt_txt, setOutAmt] = useState("");
    const [emi_txt, setEmiAmt] = useState("");
    const [members, setMembers] = useState([]);
    const [sub_type, setsub_type] = useState("");

    const [amount, setAmount] = useState("");
    const [amount_txt, setAmount_txt] = useState("");

    const [checked, setChecked] = useState([]);
    const [profId, setProfId] = useState('');

    useEffect(() => {
        let profSumm = JSON.parse(sessionStorage.getItem('ProfSummary'));
        //console.log(profSumm.prof_det);
        setMembers(profSumm.prof_det);

        axios.get(`${apiUrl}investment/edit/${investId}`).then(
            (response, data) => {
                console.log(response.data);
                setinstr_name(response.data.instr_name);
                setinvest_amount(formatAmount(response.data.current_value));
                setinsured_value(formatAmount(response.data.insured_value));
                setppt(response.data.ppt);
                setpolicy_term(response.data.policy_term);
                setFrequency(response.data.frequency);
                setsub_type(response.data.sub_type);
                setProfId(response.data.profile_details_id);
                setChecked([response.data.profile_details_id]);

                setFrequency(response.data.frequency);
                setAmount(formatAmount(response.data.invest_amount));
            });

    }, [investId])


    const handleChange = (e) => {
        console.log(e.target.name);
        const { value } = e.target;

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));

        if (e.target.name === 'invest_amount') {
            setinvest_amount(formatAmount(value.replace(/,/g, "")));
            setOutAmt(ucwords(amountInWords));
        } else if (e.target.name === 'insured_value') {
            setinsured_value(formatAmount(value.replace(/,/g, "")));
            setEmiAmt(ucwords(amountInWords));
        } else if (e.target.name === 'amount') {
            setAmount(formatAmount(value.replace(/,/g, "")));
            setAmount_txt(ucwords(amountInWords));
        }else if (e.target.name === 'instr_name') {
            setinstr_name(value);
        } else if (e.target.name === 'ppt') {
            setppt(value);
        } else if (e.target.name === 'policy_term') {
            setpolicy_term(value);
        } else if (e.target.name === 'sub_type') {
            setsub_type(value);
        } else if (e.target.name === 'li_assured') {
            setChecked([value]);
            setProfId(value);
        }else if (e.target.name === 'frequency') {
            setFrequency(value);
        } 
    };


    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const Postdata = {
                "prop_id": sessionStorage.getItem('profile_id'),
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": profId,
                "type": 4,
                "sub_type": sub_type,
                "owner": sessionStorage.getItem('profile_id'),
                "instr_name": instr_name,
                "invest_type": 0,
                "invest_amount": amount.replace(/,/g, ""),
                "amount": amount.replace(/,/g, ""),
                "cost_amount": invest_amount.replace(/,/g, ""),
                "insured_value": insured_value.replace(/,/g, ""),
                "current_value":invest_amount.replace(/,/g, ""),
                "frequency": frequency,
                "ppt": ppt,
                "policy_term": policy_term,
                "rider_id": checked,
                "invest_id":investId
            };

            // logical fields - start_date, months

            axios.post(`${apiUrl}investment/add-investment`, Postdata)
                .then(function (response) {
                    SweetAlert.fire(
                        'Life Insurance',
                        'Successfully Updated.',
                        'success'
                    ).then(function () {

                        window.location.href= "/cashflow";

                    });
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Life Insurance',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <div className="row DivJustify">
            <div className="col-lg-8 col-md-12 col-sm-12">
{frequency}
                <div className="financiali-tilte"><h1><Link to="/plan" className="goback"><i className="fas fa-angle-left" /></Link> Add Life Insurance</h1></div>
                <Progress color="success" value="100" className="sm-progress-bar" />
                <div className=" box-shadow Creating-Financial">

                    <div className="input-box selectdiv mb-4">
                        <label> Type </label>



                        <select className="form-control digits" name={"sub_type"} value={sub_type} onChange={handleChange} autoComplete="off">
                            <option value="">Select Type </option>

                            <option value="1">Term</option>
                            <option value="2">Term + Health</option>
                            <option value="3">Traditional</option>
                            <option value="4">ULIP</option>
                            <option value="5">Other</option>


                        </select>
                        {validator.message("sub_type", sub_type, "required", {
                            messages: {
                                required: "Type is required"
                            }
                        })}
                    </div>

                    <div className="input-box ruppys yearly">
                        <label> Policy Name </label>
                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)" name={"instr_name"} value={instr_name} onChange={handleChange} autoComplete="off" />
                        {validator.message("instr_name", instr_name, "required", {
                            messages: {
                                required: "Policy Name is required"
                            }
                        })}

                    </div>



                    <div className="input-box ruppys yearly">
                        <label> Sum Insured </label>
                        <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"insured_value"} value={insured_value} onChange={handleChange} autoComplete="off" />
                        <span className="text-word">{emi_txt}</span>
                        {validator.message("insured_value", insured_value, "required", {
                            messages: {
                                required: "Sum Insured is required"
                            }
                        })}
                        <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                    </div>

                    <div className="input-box selectdiv mb-4">
                        <label>Premium Frequency </label>

                        <select name={"frequency"} value={frequency} className="form-field select-input" onChange={handleChange} autoComplete="off">
                            <option value="">select</option>

                            <option value="12">Monthly</option>
                            <option value="4">Quarterly</option>
                            <option value="2">Half Yearly</option>
                            <option value="1">Yearly</option>
                            <option value="0">Lumpsum</option>
                        </select>

                        {validator.message("frequency", frequency, "required", {
                            messages: {
                                required: "Frequency is required"
                            }
                        })}
                    </div>

                    <div className="input-box ruppys yearly">
                        <label> Premium Amount </label>
                        <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"amount"} value={amount} onChange={handleChange} />
                        <span className="text-word">{amount_txt}</span>
                        {validator.message("amount", amount, "required", {
                            messages: {
                                required: "Premium Amount is required"
                            }
                        })}
                        <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                    </div>


                    <div className="input-box ruppys yearly">
                        <label> Current Value </label>
                        <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"invest_amount"} value={invest_amount} onChange={handleChange} />
                        <span className="text-word">{outstanding_amt_txt}</span>
                        {validator.message("invest_amount", invest_amount, "required", {
                            messages: {
                                required: "Current Value is required"
                            }
                        })}
                        <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="m-t-15 m-checkbox-inline">
                                <label className='fontL'> Select Life Insuranced ? </label><br />
                                {// eslint-disable-next-line
                                    members.map((item, index) => {
                                        return (
                                            <>
                                                <div key={index}>
                                                    {
                                                       profId ===  item.profile_details_id ? (<input className="form-check-input" id={item.profile_details_id} type="radio" value={item.profile_details_id} name="li_assured" onChange={handleChange} checked='checked'/>) : <input className="form-check-input" id={item.profile_details_id} type="radio" value={item.profile_details_id} name="li_assured" onChange={handleChange} />
                                                    }
                                                    <label
                                                        htmlFor={item.profile_details_id} className='ml15'>{item.full_name}</label>
                                                </div>
                                            </>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>


                    <div className="form-group">

                        <div className="row">


                            <div className="col-12">
                                <div className="text-end">
                                    <button className="next" type="button" onClick={handleSubmit}>Save</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}