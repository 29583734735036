import React, { useState, useEffect } from 'react'
import { apiUrl } from '../../constants/global'
import axios from 'axios'
import AddHouse from './AddHouse'
import AddCar from './AddCar'
import AddVacation from './AddVacation'
import AddRetirement from './AddRetirement'
import AddMarriage from './AddMarriage';
import AddEducation from './AddEducation'

export default function CreateGoals() {

    const [gsteps, setgSteps] = useState('');
    const [kids, setKids] = useState('');

    const [defaultgoal, setDefaultgoal] = useState('');

    const [currentexp, setCurrentexp] = useState('');
    const gStepChange = (gsteps) => {
        setgSteps(parseInt(gsteps));
    }

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {
                if (response.data.status === 100) {
                    sessionStorage.setItem('ProfSummary', JSON.stringify(response.data));
                    sessionStorage.setItem('life_stage', response.data.self_data.lifeStage);
                    sessionStorage.setItem('self_dob', response.data.self_data.dob);
                    sessionStorage.setItem('self_gender', response.data.self_data.gender)
                    sessionStorage.setItem('goal_creation', 1);

                    sessionStorage.setItem('IncomeSpouse', response.data.profIncomeData.IncomeSpouse);
                    sessionStorage.setItem('IncomeSelf', response.data.profIncomeData.IncomeSelf);
                    sessionStorage.setItem('total_expense', response.data.profIncomeData.total_expense);
                    sessionStorage.setItem('total_income', response.data.profIncomeData.total_income);

                    sessionStorage.setItem('retire_age', response.data.assumption.retire_age);
                    sessionStorage.setItem('life_expentancy', response.data.assumption.life_expentancy);

                    sessionStorage.setItem('income_grow', response.data.assumption.income_grow);
                    sessionStorage.setItem('post_retire_inflation', response.data.assumption.life_expentancy);
                    sessionStorage.setItem('inflation', response.data.assumption.inflation);

                    sessionStorage.setItem('age', response.data.self_data.current_age);
                    var found = response.data.prof_det.find(function (element) {
                        return element.relation === "self";
                    });

                    if(response.data.health_goals > 0 || response.data.life_goal_cnt > 0){
                        setDefaultgoal(0);
                    }else{
                        setDefaultgoal(1);
                    }
                    setCurrentexp(response.data.familyIncome.expense_self_monthly);
                    var spouse_id = response.data.prof_det.find(function (element) {
                        return element.relation === "spouse";
                    });

                    if(response.data.self_data.lifeStage !== "single"){
                        sessionStorage.setItem('spouse_prof_details_id', spouse_id.profile_details_id);
                    }
                    

                    sessionStorage.setItem('g_profile_details_id', found.profile_details_id);

                    if (sessionStorage.getItem('goalStep') !== null) {

                        console.log('inside' + sessionStorage.getItem('goalStep'));

                        gStepChange(sessionStorage.getItem('goalStep') > 6 ? 1 : sessionStorage.getItem('goalStep'));
                        if (sessionStorage.getItem('goalStep') > 6) {
                            sessionStorage.setItem('goalStep', 1);
                        }
                    } else {
                        sessionStorage.setItem('goalStep', 1);
                        gStepChange(1);
                    }

                    if (response.data.self_data.lifeStage === 'marriedWithKids') {
                        setKids(1);
                        sessionStorage.setItem('kids', 1);
                    } else {
                        sessionStorage.setItem('kids', 0);
                    }
                }
            });
    }, [])

    return (
        <div className="page-wrapper CustomPage">
            <div className="container">
                <div className="theme-form">
        {gsteps}
                    {gsteps === 1 && (<AddRetirement relation="1" title={"Retirement"} gStepChange={gStepChange} gsteps={gsteps} defaultgoal={defaultgoal} progress={20} currentexp={currentexp} />)}

                    {gsteps === 2 && (<AddHouse relation="1" title={"Buy House"} gStepChange={gStepChange} gsteps={gsteps} progress={40} />)}

                    {gsteps === 3 && (<AddCar relation="1" title={"Big Vacation"} gStepChange={gStepChange} steps={gsteps} progress={60} />)}

                    {gsteps === 4 && (<AddVacation relation="1" title={"Car"} gStepChange={gStepChange} gsteps={gsteps} progress={80} />)}

                    {(gsteps === 5 && kids === 1) && (<AddEducation relation="1" title={"Kids Education"} gStepChange={gStepChange} gsteps={gsteps} />)}

                    {(gsteps === 6 && kids === 1) && (<AddMarriage relation="1" title={"Kids Marriage"} gStepChange={gStepChange} gsteps={gsteps} />)}



                </div>
            </div>
        </div>

    )
}
