import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import { apiUrl } from '../constants/global'
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import Knob from "knob";
import SweetAlert from 'sweetalert2'

export default function Plan() {

   const [planStatus, setPlanStatus] = useState();
   const [PlanStepsType, setPlanStepsType] = useState();

   //const [goalCount, setGoalCount] = useState('');
   const [lifeCount, setLifeCount] = useState('');
   const [healthCount, setHealthCount] = useState('');
   //const [offTrack, setOffTrack] = useState('');

   const [assets, setAssets] = useState('');
   const [liabilities, setLiabilities] = useState('');
   const [networth, setNetworth] = useState('');
   const [goals, setGoals] = useState('');

   const [healthPerc, setHealthPerc] = useState('');
   const [lifePerc, setLifePerc] = useState('');

   const [drratio, setDrratio] = useState('');
   const [emiburden, setEmiburden] = useState('');
   const [savingrate, setSavingrate] = useState('');

   // eslint-disable-next-line
   const [emergencyfund, setEmergencyfund] = useState(0);

   const Displayalert = (name) =>{
      if(name === "da_ratio"){
         SweetAlert.fire({ title: "D/A Ratio", text:"Your loans should be less than 1/3 of your total assets." });
      }else if(name === "emi_burden"){
         SweetAlert.fire({ title: "EMI BURDEN", text:"Your EMIs should be less than 40 % of your Income." });
      }else if(name === "saving_rate"){
         SweetAlert.fire({ title: "SAVING RATE", text:"Your savings rate should be  25 %  or more." });
      }else if(name === "emergency_fund"){
         window.location.href = "/emergency-fund";
         //SweetAlert.fire({ title: "EMERGENCY FUND", text:"Emergency Fund" });
      }

   }

   const startPlan = () => {
      const params = { profile_id: sessionStorage.getItem('profile_id') };

      if (PlanStepsType === 'Optional') {
         window.location.href = "plan-status";
      } else {
         if (planStatus === "Continue Plan") {
            window.location.href = 'create-profile';
         } else {
            axios.post(`${apiUrl}get-plan-steps`, params).then(
               (response, data) => {
                  if (response.data.status === 100) {
                     window.location.href = 'create-profile';
                  }
               }
            );
         }
      }


   }

   useEffect(() => {

      sessionStorage.setItem('propery_radio', '/');
      sessionStorage.removeItem('goalStep');

      sessionStorage.removeItem('subType');
      sessionStorage.removeItem('giStep');

      const profile_id = sessionStorage.getItem('profile_id');

      axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
         (response, data) => {

            if (response.data.status === 100) {
               sessionStorage.setItem('ProfSummary', JSON.stringify(response.data));
               sessionStorage.setItem('life_stage', response.data.self_data.lifeStage);
               sessionStorage.setItem('self_dob', response.data.self_data.dob);
               sessionStorage.setItem('self_gender', response.data.self_data.gender)

               sessionStorage.setItem('log_id', response.data.risk_profile.log_id);
               sessionStorage.setItem('riskDescription', response.data.risk_profile.risk_description);


               if (response.data.step_res.length > 0) {
                  const status = response.data.step_res[0].PlanStepsTypeStatus === 'Done' ? response.data.step_res[0].PlanStepsTypeStatus : "Continue Plan";
                  setPlanStatus(status);
                  setPlanStepsType(response.data.step_res[0].PlanStepsType);
               } else {
                  setPlanStatus("Plan Now");
               }
               //setGoalCount(response.data.goal_count);
               setLifeCount(response.data.life_goal_cnt);
               setHealthCount(response.data.health_goals);
               setAssets(response.data.plan_assets);
               setLiabilities(response.data.liability_sum);
               setNetworth(response.data.networth);
               setGoals(response.data.goal_count);
               //setOffTrack(0);
               setHealthPerc(response.data.health_perc);
               setLifePerc(response.data.life_perc);

               setDrratio(response.data.dr_ratio);
               setEmiburden(response.data.emi_burden);
               setSavingrate(response.data.saving_rate);

               setEmergencyfund(response.data.emergency_fund)
               sessionStorage.setItem('goalsCount', response.data.goal_count);

               var giPerc = Knob({
                  value: response.data.health_perc,
                  angleOffset: -10,
                  thickness: 0.175,
                  width: 90,
                  fgColor: "#f44336",
                  readOnly: true,
                  dynamicDraw: true,
                  tickColorizeValues: true,
                  bgColor: '#eef5fb',
                  lineCap: 'round',
                  displayPrevious: false
               });
               var liPerc = Knob({
                  value: response.data.life_perc,
                  angleOffset: -10,
                  thickness: 0.175,
                  width: 90,
                  fgColor: "#f44336",
                  readOnly: true,
                  dynamicDraw: true,
                  tickColorizeValues: true,
                  bgColor: '#eef5fb',
                  lineCap: 'round',
                  displayPrevious: false
               });

               document.getElementById('liPerc').appendChild(liPerc);
               document.getElementById('giPerc').appendChild(giPerc);

            }
         });

   }, []);

   return (
      <React.Fragment>
         <Header />
         <SideMenu />
         <div className="container">
            <div className="row">
               <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="name-box">

                     <h1 className="h1Inn"> Hey {sessionStorage.getItem('full_name')},  </h1>

                     {
                        planStatus === "Continue Plan" ? <span> Financial Plan is getting ready. </span> : <span> Financial Plan is not created yet</span>
                     }

                     {
                        goals > 0 ?
                           (<><button type="button" className="btn Pay-Now float-left" onClick={() => { window.location.href = "/adjust" }}> {"Review / Adjust"}</button>
                              <button type="button" className="btn Pay-Now btn-success float-right" onClick={() => { window.location.href = "/goals" }}> View Goals</button>
                           </>) : <Link type="button" className="btn Pay-Now col-md-3" to="#" onClick={startPlan}> {planStatus}</Link>
                     }

                  </div>
               </div>
               <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="my-goals box-shadow plan">
                     <h1> NET WORTH </h1>
                     <div className="nofi"> <img src="assets/images/nitification.png" alt="notification" /></div>
                     <div className="nofi arrow"> <Link to="/track-portfolio"> <i className="fas fa-chevron-right"></i></Link></div>

                     <div className="networth">
                        <h2> <i className="fas fa-rupee-sign"></i> {networth}</h2>
                     </div>


                     <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12">

                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12 trackon ">



                           <Link to="/track-portfolio" className="green" data-bs-original-title="" title="">Assets <b>{assets} </b> </Link>
                           <Link to={goals > 0 ? "/cashflow" : "#"} className="red" data-bs-original-title="" title=""> Liabilities <b>{liabilities} </b> </Link>
                           {/*
                              goalCount > 0 ? (
                                 <>
                                    <Link to="#" className="green" data-bs-original-title="" title="">Assets <b>{goalCount} </b> </Link>
                                    <Link to="#" className="red" data-bs-original-title="" title=""> Liabilities <b>{offTrack} </b> </Link>
                                 </>
                              ) :
                                 (
                                    <Link to="/add-goal" className="aGoal"> + Add Goal </Link>
                                 )*/
                           }



                        </div>

                     </div>

                  </div>
               </div>
            </div>

            <div className="row">
               <div className="plantitle">
                  <div className="retirement"> <img src="assets/images/family-protoction.png" alt="family" /></div>
                  <div className="goal-name">
                     <h1>Family Protection </h1>
                     <p> Life and Health insurance</p>
                  </div>
                  <div className="right-arrow f-left">
                     <a href="/risk-goals" data-bs-original-title="" title=""> <i className="fas fa-chevron-right"></i></a>
                  </div>

               </div>
            </div>


            <div className="row">

               <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="box-goal plan-in">
                     <div className="my-goals l-blue">
                        <div className="top-goal">
                           <div className="retirement"> <img src="assets/images/heart.png" alt="heart" /></div>
                           <div className="goal-name">
                              <h1>Life Insurance </h1>
                           </div>
                        </div>

                        {


                           lifeCount > 0 ? (lifePerc > 0 ? (lifePerc < 80 ? (<div className="partially"><Link to="/risk-goals">Partially Protected</Link></div>) : (<div className="adequate"><Link to="/risk-goals">Adequate Cover</Link></div>)) : (<div className="unprotected"><Link to="/risk-goals">Not Covered</Link></div>))
                              : (<div className="unprotected"><Link to="#">Not Planned</Link></div>)


                        }

                        <div className="row">
                           <div className="col-lg-9 col-md-9 col-sm-12">
                              <p> Please create a Plan to check if your family will be able to meet its current expenses and future goals. </p>
                           </div>
                           <div className="col-lg-3 col-md-3 col-sm-12 plan-in">
                              <div className="knob1" id="liPerc"></div>
                           </div>
                        </div>
                     </div>

                  </div>

               </div>

               <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="box-goal plan-in">
                     <div className="my-goals l-blue white-bg">
                        <div className="top-goal">
                           <div className="retirement"> <img src="assets/images/health.png" alt="health" /></div>
                           <div className="goal-name">
                              <h1>Health Insurance </h1>
                           </div>
                        </div>

                        {

                           healthCount > 0 ? (healthPerc > 0 ? (healthPerc < 80 ? (<div className="partially"><Link to="/risk-goals">Partially Protected</Link></div>) : (<div className="adequate"><Link to="/risk-goals">Adequate Cover</Link></div>)) : (<div className="unprotected"><Link to="/risk-goals">Not Covered</Link></div>))
                              : (<div className="unprotected"><Link to="#">Not Planned</Link></div>)
                        }



                        <div className="row">
                           <div className="col-lg-9 col-md-9 col-sm-12">
                              <p> Please create a Plan to check if your family will be able to meet any medical emergencies. </p>
                           </div>
                           <div className="col-lg-3 col-md-3 col-sm-12 plan-in">
                              <div className="knob1" id="giPerc"></div>
                           </div>
                        </div>
                     </div>

                  </div>

               </div>

            </div>


            <div className="row">
               <div className="plantitle">
                  <div className="retirement"> <img src="assets/images/family-protoction.png" alt="protoction" /></div>
                  <div className="goal-name">
                     <h1>Financial Health </h1>
                     <p> Life and Health insurance</p>
                  </div>
                  <div className="right-arrow f-left">
                     <a href="/#" data-bs-original-title="" title=""> <i className="fas fa-chevron-right"></i></a>
                  </div>

               </div>
            </div>


            <div className="row">

               <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="my-goals Investment f-helath" onClick={()=> Displayalert('da_ratio')}>
                     <h1> D/A Ratio  </h1>
                     <div className="Ration mb-4">
                        <span className="f-left"><strong>{drratio > 0 ? drratio + ' %' : ' 0 %'}</strong></span>

                        {
                           drratio < 33 ? <span className='f-right'><i className="ion ion-ios-checkmark-circle fa-2x text-success"></i></span> : <span className='f-right'><i className="ion ion-ios-close-circle fa-2x text-danger"></i></span>
                        }

                     </div>
                     <p> Total loan borrowed as a % of total assets. Lower the better.</p>
                  </div>
               </div>

               <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="my-goals Investment f-helath" onClick={()=> Displayalert('emi_burden')}>
                     <h1> EMI Burden  </h1>
                     <div className="Ration mb-4">

                        <span className="f-left">{emiburden > 0 ? emiburden + ' %' : ' 0 %'}</span>

                        {
                           emiburden < 40 ? <span className='f-right'><i className="ion ion-ios-checkmark-circle fa-2x text-success"></i></span> : <span className='f-right'><i className="ion ion-ios-close-circle fa-2x text-danger"></i></span>
                        }

                     </div>
                     <p> Total EMIs paid as a % of Income. Lower the better. </p>
                  </div>
               </div>

               <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="my-goals Investment f-helath Saving" onClick={()=> Displayalert('saving_rate')}>
                     <h1> Saving Rate  </h1>
                     <div className="Ration mb-1">
                        <span className="f-left">{savingrate > 0 ? savingrate + ' %' : ' 0 %'}</span>

                        {
                           savingrate > 25 ? <span className='f-right'><i className="ion ion-ios-checkmark-circle fa-2x text-success"></i></span> : <span className='f-right'><i className="ion ion-ios-close-circle fa-2x text-danger"></i></span>
                        }

                     </div>
                     <p> Savings after Expenses ( Household+EMI+Commitments) as % of Income. Higher the better. </p>
                  </div>
               </div>

               <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="my-goals Investment f-helath Emergency" onClick={()=> Displayalert('emergency_fund')}>
                     <h1> Emergency Fund  </h1>
                     <div className="Ration mb-4">
                        <span className="f-left">{emergencyfund > 0 ? emergencyfund + ' %' : ' 0 %'}</span>
                        {
                           emergencyfund > 25 ? <span className='f-right'><i className="ion ion-ios-checkmark-circle fa-2x text-success"></i></span> : <span className='f-right'><i className="ion ion-ios-close-circle fa-2x text-danger"></i></span>
                        }
                     </div>
                     <p> Available investments in highly liquid instruments for emergencies. </p>
                  </div>
               </div>


            </div>

         </div>
         <div className="clear"></div>
         <Footer />
      </React.Fragment>
   )
}
