import React from 'react';
import RouterPage from './pages/RouterPage';

function App() {
  
  return (
    <React.Fragment>
      <div className="App">
        <RouterPage />
      </div>
    </React.Fragment>

  );
}

export default App;
