import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { LogIn } from 'react-feather';

export default function AdminHeader() {

  let navigate = useNavigate();

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/ulogin");
  }

  return (
    <header>
      <div className="container">
        <div className="logo">
          <Link to="/"><img src="assets/images/logo.svg" alt="Logo" /></Link>
        </div>
        <div className="headerRight">
          <ul className="menuLinks">
            <Link to="" className="menuClose"></Link>
            <li className="profile-nav onhover-dropdown p-0 me-0">
              <div className="media profile-media">
                <div className="media-body">
                  <div className="proIcon"><div className="icon1" title="logout"></div></div>
                </div>
              </div>
              <ul className="profile-dropdown onhover-show-div">
                <li><LogIn /><span onClick={logout}>{"LogOut"}</span></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
