import React, { Fragment, useEffect } from 'react'
//import { Link } from 'react-router-dom'
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../constants/global'
import formatAmount from 'indian-currency-formatter';
import { Dropdown } from 'react-bootstrap'
import { Col, Card, CardHeader, CardBody, TabContent, TabPane, Table, Modal, ModalHeader, ModalBody } from 'reactstrap'

import EditHealth from './Goals/EditHealth';
import EditLife from './Goals/EditLife';
import EditHealthSuggested from './Goals/EditHealthSuggested';
import EditLifeSuggested from './Goals/EditLifeSuggested';
import DataTable from 'react-data-table-component'

import SweetAlert from 'sweetalert2'

export default function RiskGoals() {

    // eslint-disable-next-line
    const [activeTab2, setActiveTab2] = useState('1');
    //const [goalCount, setGoalCount] = useState('');
    // eslint-disable-next-line
    const [targetAmount, setTargetAmount] = useState('');
    // eslint-disable-next-line
    const [goals, setGoals] = useState([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [suggestmodal, setSuggestmodal] = useState(false);
    const suggesttoggle = () => setSuggestmodal(!suggestmodal);
    const [goalData, setgoalData] = useState([]);

    const [goalType, setGoalType] = useState("");

    const tableColumnsLiGi = [
        {
            name: 'Asset Type	',
            selector: 'asset_type',
            sortable: true,
            left: true,
        }, {
            name: 'Sub Type	',
            selector: 'type_name',
            sortable: true,
            left: true,
        },


        {
            name: 'Instrument Name',
            selector: 'instr_name',
            sortable: true,
            left: true,
        },
        {
            name: 'Yearly Premium',
            cell: (row) => row.yearly_amount,
            sortable: false,
            center: true,
            footer: 10000000000
        },
        {
            name: 'Cover Amount ',
            cell: (row) => formatAmount(row.till_date),
            sortable: false,
            center: true,
        },
        {
            name: 'Action',
            cell: (row) => <div><span onClick={() => DeleteAsset(row.invest_id)}><i className='fa fa-trash' style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span><span onClick={() => EditAsset(row.invest_id, row.type)}><i className='fas fa-pencil-alt' style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span></div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    const EditAsset = (id, type) => {

        var url = '';
        switch (type) {
            case 1: //Equity
                url = "/edit-equity/";
                break;
            case 2: //deposits
                url = "/edit-deposits/";
                break;
            case 3: //mf
                url = "/edit-mf/";
                break;
            case 4: //Providend Fund
                url = "/edit-life/";
                break;
            case 5: //Providend Fund
                url = "/edit-ppf/";
                break;

            case 6: //Life Insurance
                url = "/edit-gold/";
                break;

            case 7: //General Insurance
                url = "/edit-real-estate/";
                break;

            case 9: //nps Insurance
                url = "/edit-health/";
                break;
            default:
        }

        window.location.href = url + id;

    }


    const DeleteAsset = (id) => {


        SweetAlert.fire({
            title: "Are you sure?",
            text: "you want to delete this Asset?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FF0000",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            closeOnConfirm: false,
            closeOnCancel: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.post(`${apiUrl}investment/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'invest_id': id }).then(
                    function (response) {
                        SweetAlert.fire(
                            'Assets',
                            'Successfully Deleted.',
                            'success'
                        ).then(function () {
                            window.location.href = "/risk-goals";
                        });
                    }
                )
            }
        })
    }

    const EditGoal = (goal_type, data) => {
        setgoalData(data);
        setModal(!modal);
        setGoalType(goal_type);
    }

    const EditSuggested = (goal_type, data) => {
        setgoalData(data);
        setGoalType(goal_type);
        setSuggestmodal(!suggestmodal);
    }

    const DeleteGoal = (id) => {


        SweetAlert.fire({
            title: "Are you sure?",
            text: "you want to delete this goal?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FF0000",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            closeOnConfirm: false,
            closeOnCancel: false
        })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${apiUrl}goals/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'goal_id': id }).then(
                        function (response) {
                            SweetAlert.fire(
                                'Goals',
                                'Successfully Deleted.',
                                'success'
                            ).then(function () {
                                window.location.href = "/risk-goals";
                            });
                        }
                    )
                }
            });
    }

    const getPortfolio = (e) => {
        getAssetsData(e, 'Y');
    }

    const [assets, setAssets] = useState([]);
    const [ytotal, setYtotal] = useState("");
    const [tilldate, setTilldate] = useState("");
    const [getCheck, setGetCheck] = useState("");
    const [percentage, setPercentage] = useState(0);



    const getAssetsData = (property = "1", primary_flag = "N") => {
        const profile_id = sessionStorage.getItem('profile_id');
        axios.post(`${apiUrl}investment/recommended-assets`, { 'profile_id': profile_id, 'radio_type': property, 'primary_flag': primary_flag }).then(
            (response, data) => {

                setAssets(response.data.main_assets);

                setTilldate(response.data.grand_total.till_date_grand);
                setYtotal(response.data.grand_total.yearly_grand);

                setGetCheck(property);


            });
    }

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {
                if (response.data.status === 100) {
                    setGoals(response.data.risk_goals);
                    //setGoalCount(response.data.off_track_g2);
                    setTargetAmount(response.data.target_amount);

                    setPercentage((response.data.health_perc + response.data.life_perc) / 2);
                }
            });

        getAssetsData(4);

    }, []);

    return (
        <Fragment>
            <Header />
            <SideMenu />
            <div className="container">
                <div className="row">

                    <Modal isOpen={modal} toggle={toggle} size="lg">
                        <ModalHeader toggle={toggle}>{"Edit Goal"}</ModalHeader>
                        <ModalBody>

                            {
                                goalType === 'Life Insurance' && (<EditLife data={goalData} />)
                            }

                            {
                                goalType === 'Health Insurance' && (<EditHealth data={goalData} />)
                            }


                        </ModalBody>
                    </Modal>


                    <Modal isOpen={suggestmodal} toggle={suggesttoggle} size="lg">
                        <ModalHeader toggle={suggesttoggle}>{"Select Option"}</ModalHeader>
                        <ModalBody>

                            {
                                goalType === 'Life Insurance' && (<EditLifeSuggested data={goalData} />)
                            }

                            {
                                goalType === 'Health Insurance' && (<EditHealthSuggested data={goalData} />)
                            }

                        </ModalBody>
                    </Modal>

                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="name-box">
                            <h1 className="h1Inn"> Hey {sessionStorage.getItem('full_name')}, </h1>
                            {
                                goals.length > 0 ? (
                                    <>
                                        <span> Your family is <strong className="text-success">{(percentage > 0 ? percentage < 80 ? 'Partially' : 'Adequate' : 'Not')}</strong> protected.</span>
                                        <button type="button" className="btn Pay-Now float-left" onClick={() => { window.location.href = "/adjust" }}> Review / Adjust</button>


                                    </>
                                ) :
                                    (
                                        <>
                                            <p> Please create a Plan to check if your family will be able to meet its current expenses and future goals. </p>
                                            <button type="button" className="btn Pay-Now" onClick={() => { window.location.href = "/add-goal" }}> ADD Goal</button>
                                        </>
                                    )
                            }


                            <br />
                            {/*<Link to="/track-portfolio" type='button' className='btn btn-primary'> Add Investments</Link>*/}
                        </div>
                    </div>
                    {/*<div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="my-goals box-shadow">
                            <h1> MY GOAL </h1>
                            <div className="nofi"> <img src="assets/images/nitification.png" alt="notification" /></div>
                            <div className="money">
                                <strong> <i className="fas fa-rupee-sign"></i> {targetAmount != null ? targetAmount : 'NA'}</strong>
                                <p>  Total Target Amount</p>
                            </div>
                            <div className="price-risk">
                                <strong>{goalCount > 0 ? goalCount : '0'}	 </strong>
                                <h3> Goals			 </h3>
                                <Link to="#"> <i className="fas fa-chevron-right"></i> </Link>
                            </div>
                            <Link to="/add-goal" className="aGoal"> + Add Goal </Link>
                        </div>
                    </div> */}
                </div>
                <div className="row">

                    {// eslint-disable-next-line
                        goals.map((item, index) => {
                            return (
                                <>
                                    <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                                        <div className="box-goal">
                                            <div className="my-goals">
                                                <div className="top-goal">

                                                    <div className="retirement">{item.goal_type === 'Life Insurance' ? <img src="assets/images/Life.svg" alt="Life Insurance" style={{ "width": "45px" }} /> : <img src="assets/images/Health.svg" alt="Health Insurance" style={{ "width": "45px" }} />} </div>
                                                    <div className="goal-name">
                                                        <h1>{item.goal_type} </h1>
                                                        <p>{item.full_name}</p>
                                                    </div>
                                                    <div className="three-dot">
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
                                                                <i className="fas fa-ellipsis-v" />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => EditGoal(item.goal_type, item)}>Edit</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => DeleteGoal(item.goal_id)}>Delete</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => EditSuggested(item.goal_type, item)}>Select Option</Dropdown.Item>

                                                                <Dropdown.Item href="/add-goal">Add</Dropdown.Item>

                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="Accumulated">
                                                    <div className="money">
                                                        <strong> Current</strong>
                                                        <p> <i className="fas fa-rupee-sign"></i>  {formatAmount(Math.round(item.existing_cover))}</p>
                                                    </div>
                                                    <div className="money">
                                                        <strong> Suggested</strong>
                                                        <p> <i className="fas fa-rupee-sign"></i>  {item.select_opt === 'regular' ? formatAmount(Math.round(item.max_suggested)) : formatAmount(Math.round(item.max_suggested))}</p>
                                                    </div>
                                                </div>
                                                <div className="procees">
                                                    <div className="progress-showcase mt-4">
                                                        <div className="progress sm-progress-bar">
                                                            <div className={item.bar_perc > 80 ? 'progress-bar bg-info' : 'progress-bar bg-danger'} role="progressbar" style={{ 'width': item.bar_perc + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    </div>
                                                    <div className="ontrack">
                                                        <table>
                                                            <tr>
                                                                <td>{item.bar_perc > 80 ? <div className="greendot"> </div> : <div className="reddot"> </div>}   </td>
                                                                <td>{item.bar_perc > 80 ? 'On Track' : 'Off Track'} </td>
                                                                <td style={{ "text-align": "right" }}>  <b> {item.bar_perc} % covered  </b>  </td>

                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            )
                        })}
                </div>

                <div className="row">

                    <Col lg="12 box-col-12" xl="12 xl-100">
                        <Card>
                            <CardHeader>
                                <h5>{"My Policies"} </h5>


                                <div className="mb-3 mt-3">

                                    <div className="m-checkbox-inline">



                                        <label for="edo-ani4" className="form-label" onChange={() => getPortfolio(4)}>
                                            <input
                                                id="edo-ani4"
                                                name="rdo-ani"
                                                type="radio"
                                                className="radio_animated form-check-input"
                                                value={4}
                                                checked={getCheck === 4 ? "checked" : ""}
                                            />{"Life Insurance"}</label>

                                        <label for="edo-ani5" className="form-label" onChange={() => getPortfolio(5)}>
                                            <input
                                                id="edo-ani5"
                                                name="rdo-ani"
                                                type="radio"
                                                className="radio_animated form-check-input"
                                                value={5}
                                                checked={getCheck === 5 ? "checked" : ""}
                                            />{"General Insurance"}</label>


                                    </div>
                                </div>

                            </CardHeader>
                            <CardBody className="tabbed-card">

                                <TabContent activeTab={activeTab2}>
                                    <TabPane tabId="1">
                                        <div className="best-seller-table responsive-tbl">
                                            <div className="item">
                                                <div className="table-responsive product-list">

                                                    <DataTable
                                                        data={assets}
                                                        columns={tableColumnsLiGi}
                                                        striped={true}
                                                        center={true}
                                                        persistTableHead
                                                    />
                                                    <Table>
                                                        <tfoot>
                                                            <th>Total</th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th>{formatAmount(ytotal)}</th>
                                                            <th>{formatAmount(tilldate)}</th>
                                                            <th></th>
                                                        </tfoot>
                                                    </Table>




                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>


                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>


                </div>
            </div>

            <div className="clear"></div>
            <Footer />
        </Fragment>
    )

}
