import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import 'rc-slider/assets/index.css';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { apiUrl } from '../../constants/global';
import SweetAlert from 'sweetalert2'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'
import { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import AddFamily from './../AddFamily';
import {calculateAge} from '../../constants/common'

export default function AddEducation({ title, StepChange, steps, gpsteps, gStepChange, progress }) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [amt, setAmt] = useState("");
    const [pgamt, setPgAmt] = useState("");
    const [gamount, setGamount] = useState("");
    const [pgamount, setPgamount] = useState("");
    const [kidsList, setKidsList] = useState([]);
    const [profDetId, setProfDetId] = useState("");
    const [disable, setDisable] = React.useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [age, setAge] = useState("");
    const [relation, setRelation] = useState("");
    const [goalStep, setGoalStep] = useState(gpsteps);

    const skipGoal = () => {

        let goalStep = sessionStorage.getItem('goalStep');
        let increment = 1;
        if (goalStep >= 3 && sessionStorage.getItem('kids') !== '1') {
            increment = 2;
        }
        sessionStorage.setItem('goalStep', parseInt(goalStep) + increment);
        
        gStepChange(parseInt(goalStep) + increment);
        setGoalStep(parseInt(goalStep) + increment);
    }

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const numtoWord = e => {
        const { value } = e.target;


        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');

        if(e.target.name === 'gamount'){
            setGamount(formatAmount(value.replace(/,/g, "")));
            setAmt(ucwords(amountInWords));
        }else{
            setPgamount(formatAmount(value.replace(/,/g, "")));
            setPgAmt(ucwords(amountInWords));
        }

        
    }


    const getkids = () => {
        const postData = {
            'profile_id': sessionStorage.getItem('profile_id'),
            'relation': 'kids'
        }
        axios.post(`${apiUrl}profile-details/getfamilybyrelation`, postData).then(function (response) {
            setKidsList(response.data);
            if(response.data.length > 0 ){
                setDisable(false);
            }else{
                setDisable(true);
            }
        })
    }

    useEffect(() => {
        getkids();
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;

        setProfDetId(value);
        setAge(calculateAge(e.target[e.target.selectedIndex].getAttribute('g_dob')));
        setRelation(e.target[e.target.selectedIndex].getAttribute('g_relation'));
        const postData = {
            'plan_for': value,
            'goal_type': 'Education'
        }
        axios.post(`${apiUrl}goals/check-plan-for-goal`, postData)
            .then(function (response) {
                console.log(response);
                if (response.data.count > 0) {
                    SweetAlert.fire({
                        toast: true,
                        icon: 'error',
                        title: 'You have already created goal for this profile.',
                        animation: false,
                        position: 'top-middle',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    });
                    setDisable(true);
                } else {
                    setDisable(false);
                }

            });
    };

    const onSubmit = data => {

        const required_after_cal = 17 - age;
        const required_after = required_after_cal < 1 ? 1 : required_after_cal;
        const required_till = required_after + 6;

        const postData = {
            "goal_type": "Education",
            "aspire": "Education",
            "goal_name":"Education",
            "profile_id": sessionStorage.getItem('profile_id'),
            "plan_for": {
                "profile_details_id": profDetId,
                "current_age": age,
                "relation": relation
            },
            "yearly_expense":{
                "0":gamount,
                "1":pgamount
            },
            "no_years":{
                "0":4,
                "1":2
            },
            "goal_params": {
                "age": age,
                "post_inflation": 7,
                "post_return": 7,
                "goal_inflation": 7,
                "required_after": required_after,
                "required_till": required_till
            },
            "risk_profile": {
                "available": false,
                "pre_return": 8,
                "risk_profile_id": sessionStorage.getItem('profile_id')
            },
            "assumptions": {
                "assumption_id": false,
                "inflation": 7.5,
                "income_grow": "10"
            },
            "total_abcd": [{
                "amount": parseInt(gamount) + parseInt(pgamount),
                "frequency": 12,
                "no_of_times": 1,
                "gap": 0
            }],
            "actual": [{
                "actual_y": 0,
                "frequency": 1,
                "actual_ls": 1,
                "grow": "0"

            }]
        };

        axios.post(`${apiUrl}goals/add`, postData)
            .then(function (response) {
                SweetAlert.fire(
                    'Goals',
                    'Added New Goal.',
                    'success'
                ).then(function () {
                    skipGoal();
                })
            })
            .catch(function (error) {
                console.log(error);
                SweetAlert.fire(
                    'Goals',
                    'Failed.',
                    'error'
                )
            });

    };

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/plan-status" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={progress} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            
                            {
                                kidsList.length > 0 ? (
                                    <>
                                        <div className="input-box selectdiv mb-4">
                                            <label> Select Kid </label>
                                            <select className="form-control digits" name={"profDetId"} onChange={handleChange} autoComplete="off">
                                                <option value="">select</option>
                                                {kidsList.map((data, i) => {
                                                    return (
                                                        <option value={data.profile_details_id} g_relation={data.relation} key={i} g_dob={data.dob}>{data.full_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                    <button className="btn btn-outline-primary btn-lg" type="button" onClick={toggle}>New Contacts</button>
                                        <Modal isOpen={modal} toggle={toggle}>
                                            <ModalHeader toggle={toggle}>{"AddContacts"}</ModalHeader>
                                            <ModalBody>
                                                <AddFamily goalStep={goalStep}/>
                                            </ModalBody>
                                        </Modal>
                                    </>
                                )
                            }
                            <div className="input-box ruppys yearly">
                                <label> Amount for Graduation </label>
                                <input type="text" className="form-control userInput" aria-label="Amount " name={gamount} value={gamount} {...register("gamount", { required: true })} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' />
                                <span className="text-word">{amt}</span><br />
                                {errors.gamount && <span className='srv-validation-message'>Amount for Graduation is required</span>}
                                <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            </div>

                            <div className="input-box ruppys yearly">
                                <label> Amount for Post Graduation </label>
                                <input type="text" className="form-control userInput" aria-label="Amount " name={pgamount} value={pgamount} {...register("pgamount", { required: true })} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' />
                                <span className="text-word">{pgamt}</span><br />
                                {errors.pgamount && <span className='srv-validation-message'>Amount for Post Graduation is required</span>}
                                <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            </div>

                            <div className="bottombtn mt-7">
                                <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                <button className="skipBtn" onClick={skipGoal}> SKIP </button>
                                <button className="next" type='submit' disabled={disable}> Next<i className="fas fa-angle-right" /> </button>
                            </div>
                        </form>

                    </div>



                </div>

            </div>
        </Fragment>
    )
}
