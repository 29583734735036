import React, { Fragment } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';



export default function Risk() {
 
   return (
      <Fragment>
         <Header />
         <h1>Portfolio</h1>
         <Footer />
      </Fragment>
   );
}
