import React, { Fragment, useEffect } from 'react'
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import axios from 'axios';
import { apiUrl, BaseUrl } from '../constants/global'
import { useState } from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap'
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts'

export default function RiskView() {

   //eslint-disable-next-line
   const [riskImg, setRiskImg] = useState('');
   const [riskDescription, setRiskDescription] = useState('');
   const [riskLogs, setRiskLogs] = useState([]);
   const [roi, setRoi] = useState();

   const [asset, setAsset] = useState();
   // eslint-disable-next-line
   const [riskAsset, setRiskAsset] = useState();

   const [riskId, setRiskId] = useState();
   const [email, setEmail] = useState();

   const [calRiskAsset, setCalRiskAsset] = useState();
   // eslint-disable-next-line
   const [calSummaryAsset, setCalSummaryAsset] = useState();

   const [apexDonutCharts, setApexDonutCharts] = useState({
      series: [6, 10],
      options: {
         chart: {
            type: 'donut',
            width: 100,
            height: 100
         },
         colors: ['#51bb25', '#fb740d'],
         labels: ["Equity", "Debt"],
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 100
               },
               legend: {
                  position: 'bottom'
               }
            }
         }]
      },
   });


   const [apexAssetCharts, setApexAssetCharts] = useState({
      series: [6, 10],
      options: {
         chart: {
            type: 'donut',
            width: 100,
            height: 100
         },
         colors: ['#51bb25', '#fb740d'],
         labels: ["Allocation", "No Allocation"],
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 100
               },
               legend: {
                  position: 'bottom'
               }
            }
         }]
      },
   });

   const riskPdf = (risk_id) => {

      window.open(`${BaseUrl}risk-profile-pdf/${risk_id}`, "_blank");

   }


   useEffect(() => {

      const profile_id = sessionStorage.getItem('profile_id');
      axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
         (response, data) => {
            if (response.data.status === 100) {
               setRiskImg('assets/images/' + response.data.risk_profile.img);
               setRiskDescription(response.data.risk_profile.risk_description);
               setRiskId(response.data.risk_profile.log_id);
               setRoi(response.data.profgoalSummary.pre_return * 100);
               setEmail(response.data.risk_profile.email);
               setAsset(response.data.profgoalSummary.goal_asset_alloc);
               setRiskAsset(response.data.risk_profile.calc_value);
               if (response.data.risk_profile !== '') {
                  setApexDonutCharts({
                     series: [response.data.risk_profile.max_val, 100 - response.data.risk_profile.max_val],
                     options: {
                        chart: {
                           type: 'donut',
                        },
                        colors: ['#1761c5', '#c1d9f6'],
                        labels: ["Equity", "Debt"],
                        legend: {
                           formatter: function (val, opts) {
                              return val + " - " + opts.w.globals.series[opts.seriesIndex] + '%'
                           }
                        },
                        responsive: [{
                           breakpoint: 480,
                           options: {
                              chart: {
                                 width: 100,
                                 height: 100
                              },
                              legend: {
                                 show: true,
                                 position: 'bottom'
                              }
                           }
                        }]
                     },
                  });
               } else {
                  setApexDonutCharts(0);
               }

               if (response.data.profgoalSummary.goal_asset_alloc !== '') {
                  setApexAssetCharts({
                     series: [Math.round(response.data.profgoalSummary.goal_asset_alloc), 100 - Math.round(response.data.profgoalSummary.goal_asset_alloc)],
                     options: {
                        chart: {
                           type: 'donut',
                        },
                        colors: ['#1761c5', '#c1d9f6'],
                        labels: ["Equity", "debt"],
                        legend: {
                           formatter: function (val, opts) {
                              return val + " - " + opts.w.globals.series[opts.seriesIndex]
                           }
                        },
                        responsive: [{
                           breakpoint: 480,
                           options: {
                              chart: {
                                 width: 100
                              },
                              legend: {
                                 show: true,
                                 position: 'bottom'
                              }
                           }
                        }]
                     },
                  });
               } else {
                  setApexAssetCharts(0);
               }


               const profile_id = sessionStorage.getItem('profile_id');

               axios.post(`${apiUrl}summary/calculate-roi`, { profile_id: profile_id, asset_alloc: response.data.risk_profile.max_val }).then(
                  (response, data) => {
                     setCalRiskAsset((response.data.roi * 100).toFixed(2));
                  });

               axios.post(`${apiUrl}summary/calculate-roi`, { profile_id: profile_id, asset_alloc: response.data.profgoalSummary.goal_asset_alloc }).then(
                  (response, data) => {
                     setCalSummaryAsset((response.data.roi * 100).toFixed(2));
                  });

            }
         });

      axios.get(`${apiUrl}get-risk-logs/${profile_id}`).then(
         (response, data) => {
            setRiskLogs(response.data);

         });

   }, []);

   return (
      <Fragment>
         <Header />
         <SideMenu />
         <div className="container">


            <div className='row mt-4'>

               <div class="col-sm-12 col-xl-6 box-col-6">

                  <div className="name-box">

                     {riskDescription !== undefined ? (
                        <>
                           <h1 className="h1Inn"> Recommended portfolio is <strong>{riskDescription}</strong> </h1>
                           <img className="img-300 img-fluid m-r-20" src={riskImg} alt="looginpage" />
                        </>
                     ) : (
                        (
                           <>
                              <h1 className="h1Inn"> You Investment Risk Profile is not created </h1>
                              <button onClick={() => { window.location = '/risk-profile' }} className="btn-pill btn-air-light btn btn-outline-light txt-dark">Reset</button>
                           </>
                        )
                     )}


                  </div>

               </div>


               <div class="col-sm-12 col-xl-6 box-col-6 mt-4">
                  {riskLogs.length > 0 ?
                     <>
                        <Card className="mb-0">
                           <CardHeader className="d-flex">
                              <span className="f-14 pull-right mt-0"> {"Risk PDF History"}</span>
                           </CardHeader>
                           <CardBody>
                              <div className="best-seller-table responsive-tbl">
                                 <div className="item">
                                    <div className="table-responsive product-list">
                                       <Table borderless>
                                          <thead>
                                             <tr>
                                                <th className="f-22">{"Risk Description"}</th>
                                                <th>{"Date"}</th>
                                                <th>{"PDF"}</th>
                                             </tr>
                                          </thead>
                                          <tbody>

                                             {// eslint-disable-next-line
                                                riskLogs.map((item, index) => {
                                                   return (
                                                      <>
                                                         <tr>
                                                            <td>{item.risk_description}</td>
                                                            <td>{item.created}</td>
                                                            <td>
                                                               {
                                                                  item.ref_doc === null ?
                                                                     'NA' :
                                                                     (
                                                                        <>
                                                                           <a href={BaseUrl + "uploads/riskpdf/" + item.ref_doc} target="_blank" rel="noreferrer"><i className="fas fa-file-pdf fa-2x"></i></a>

                                                                           <Link to="/compose"
                                                                              state={{ 'email': email, 'ref_doc': item.ref_doc, 'path': '/var/www/restapi/public/uploads/riskpdf/', 'subject': 'Risk Profile PDF', ref_url: '/risk-view' }}
                                                                           ><i className="fa fa-envelope fa-2x" aria-hidden="true"></i></Link>
                                                                        </>
                                                                     )
                                                               }


                                                            </td>

                                                         </tr>
                                                      </>
                                                   )
                                                })}


                                          </tbody>
                                       </Table>
                                    </div>
                                 </div>
                              </div>
                           </CardBody>
                        </Card>
                     </> : ''
                  }
               </div>



               <div class="col-sm-12 col-xl-4 box-col-4">
                  <div class="card">
                     <div class="card-header">
                        <h6>Asset Allocation As Per Risk Profile </h6>
                     </div>
                     <div class="card-body apex-chart">
                        <div id="donutchart">

                           {
                              roi === 0 || roi === null ? '' : <Chart options={apexDonutCharts.options} series={apexDonutCharts.series} type="donut" />
                           }
                        </div>
                     </div>
                     <div class="card-footer">
                        <h6>Expected rate of retrun : {calRiskAsset}%</h6>
                     </div>
                  </div>
               </div>

               

               <div class="col-sm-12 col-xl-4 box-col-4">
                  <div class="card">
                     <div class="card-header">
                        <h6>Asset Allocation as Per Goals</h6>
                     </div>
                     <div class="card-body apex-chart">
                        <div id="donutchart">
                           {
                              asset === 0 || asset === null ? '' : <Chart options={apexAssetCharts.options} series={apexAssetCharts.series} type="donut" />
                           }
                        </div>
                     </div>
                     <div class="card-footer">
                     <h6>Expected rate of retrun : {calSummaryAsset}%</h6>
                     </div>
                  </div>
               </div>

               <div class="col-sm-12 col-xl-4 box-col-4">
                  {riskDescription !== undefined ? (
                     <>

                        <div className="name-box">
                           <span>It refers to style of portfolio management that attempts to maximize returns by taking a relatively higher degree of risk.</span>

                           <button onClick={() => { window.location = '/risk-profile' }} className="btn-pill btn-air-light btn btn-outline-light txt-dark">Reset</button>
                           <button className="btn-pill btn-air-light btn btn-outline-light txt-dark" style={{ 'float': 'right' }} onClick={() => riskPdf(riskId)}>RISK PDF</button>
                        </div>

                     </>
                  ) : ''
                  }
               </div>

            </div>


            <div className='row mt-4'>







            </div>

         </div>

         <div className="clear"></div>
         <Footer />
      </Fragment>
   )

}
