import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../constants/global';
import { useState } from 'react';
import formatAmount from 'indian-currency-formatter';

export default function PlanStatus() {

  let navigate = useNavigate();
  const redirectPage = e => {
    navigate(e.target.name);
    sessionStorage.setItem('page_referer', "/plan-status");
  }

  const [profileMembers, setProfileMembers] = useState('');
  const [cashflow, setCashflow] = useState('');

  const [loanCount, setLoanCount] = useState('');
  const [goalCount, setGoalCount] = useState('');
  const [coverCount, setCoverCount] = useState('');
  const [riskProfile, setRiskProfile] = useState('');

  //eslint-disable-next-line
  const [inflow, setInflow] = useState('');
  //eslint-disable-next-line
  const [outflow, setOutflow] = useState('');
  const [riskDescription, setRiskDescription] = useState('');

  useEffect(() => {
    sessionStorage.removeItem('goalStep');
    const profile_id = sessionStorage.getItem('profile_id');
    axios.get(`${apiUrl}profile/planstatuscount/${profile_id}`).then(
      (response, data) => {
        console.log(response.data);
        setProfileMembers(response.data.profile_members);
        setCashflow(response.data.inflow_outflow.count);

        setLoanCount(response.data.loans_count);
        setGoalCount(response.data.goal_count);
        setCoverCount(response.data.cover_count);
        setRiskProfile(response.data.risk_profile);

        setInflow(response.data.inflow_outflow.income);
        setOutflow(response.data.inflow_outflow.expense);

        setRiskDescription(response.data.risk_description);

        sessionStorage.setItem('page', '/plan-status');
      }
    );

  }, []);

  return (
    <Fragment>
      <Header />
      <div className="container">
        <div className="row">
          <h1 className='mt-4'><button className="back"> <i className="fas fa-angle-left"></i> </button> Financial Plan Status</h1>

          <div className="col-lg-7 col-md-7 col-sm-12 px-3 ">
            <div className="name-box-status">
              <h1 className="h1Inn"> Your Profile </h1>
              <div className="nofi-status"> <i className="fas fa-check-circle fa-2x done-step-check"></i></div>
              <span> {profileMembers} Members</span>
              <button type="button" className="btn btn-primary Pay-Now" onClick={redirectPage} name="/profile"> Review </button>
            </div>

            <div className="name-box-status">
              <h1 className="h1Inn"> Cashflow </h1>

              {
                cashflow > 0 ? (
                  <>
                    <div className="nofi-status"> <i className="fas fa-check-circle fa-2x done-step-check"></i></div>
                    <p> Income: <strong><i className="fas fa-rupee-sign"></i> {formatAmount(inflow)}</strong> <br /> Expense: <strong><i className="fas fa-rupee-sign"></i> {formatAmount(outflow)}</strong></p>
                    <button type="button" className="btn btn-primary Pay-Now" onClick={redirectPage} name="/cashflow"> Review </button>
                  </>

                ) : (
                  <>
                    <div className="nofi-status"> <i className="fas fa-times fa-2x danger-step-check"></i></div>
                    <span>No Cashflow</span>
                    <button type="button" className="btn btn-danger Pay-Now" onClick={redirectPage} name="/cashflow"> Start </button>
                  </>
                )
              }


            </div>

            <div className="name-box-status">
              <h1 className="h1Inn"> Your Loans </h1>
              <div className="nofi-status"> <i className="fas fa-exclamation-circle fa-2x warning-step-check"></i></div>
              <span> {loanCount} Liablities</span>
              <button type="button" className="btn btn-primary Pay-Now" onClick={redirectPage} name="/cashflow"> Review </button>
            </div>

            <div className="name-box-status">
              <h1 className="h1Inn"> Risk Profile </h1>


              {
                riskProfile > 0 ? (
                  <>
                    <div className="nofi-status"> <i className="fas fa-check-circle fa-2x done-step-check"></i></div>
                    <span> Your Risk Profile is <strong>{riskDescription}</strong> </span>
                    <button type="button" className="btn btn-primary Pay-Now" onClick={redirectPage} name="/risk-view"> Review </button>
                  </>

                ) : (
                  <>
                    <div className="nofi-status"> <i className="fas fa-times fa-2x danger-step-check"></i></div>
                    <span>Risk Profile is not created</span>
                    <button type="button" className="btn btn-danger Pay-Now" onClick={redirectPage} name="/risk-profile"> Start </button>
                  </>
                )
              }
              
            </div>

            <div className="name-box-status">
              <h1 className="h1Inn"> Your Insurance Cover </h1>
              <div className="nofi-status"> <i className="fas fa-lock fa-1x"></i></div>
              <span> {coverCount} covers</span>
              <button type="button" className="btn btn-success Pay-Now" onClick={redirectPage} name="/risk-plan"> Add Life </button>
              <br />
              <button type="button" className="btn btn-success Pay-Now" onClick={redirectPage} name="/risk-plan-health"> Add Health </button>
            </div>

            <div className="name-box-status">
              <h1 className="h1Inn"> Set Goals </h1>

              {
                goalCount ? (
                  <>
                    <div className="nofi-status"> <i className="fas fa-check-circle fa-2x done-step-check"></i></div>
                    <span> {goalCount} Goals</span>
                    <button type="button" className="btn btn-success Pay-Now" onClick={redirectPage} name="/"> Review </button>
                  </>

                ) : (
                  <>
                    <div className="nofi-status"> <i className="fas fa-times fa-2x danger-step-check"></i></div>
                    <span>{riskProfile > 0 ? 'No Goal':'Risk Profile Not Created' }</span>
                    <button type="button" className="btn btn-success Pay-Now" onClick={redirectPage} name="/goals-creation" disabled={riskProfile > 0 ? '':'disabled'}> Start </button>
                  </>
                )
              }

            </div>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 px-3">

            <div className="card card-mb-faq mt-4">
              <div className="card-header faq-header">
                <h5>Need Help ?</h5>
              </div>
              <div className="card-body faq-body">

                <div className="navigation-option">
                  <ul>
                    <li><i className="fas fa-mobile-alt"></i> Call Us 98190 22600</li>
                    <li>Email Us services@finnovate.in</li>
                    <li>Advisor: Not Mapped</li>
                  </ul>
                  <hr />
                  <ul>

                    <li> <a href="https://wa.me/919819022600" target="_blank" rel='noopener noreferrer'><i className="fab fa-whatsapp"></i> 98190 22600</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div className="clear"></div>
      <Footer />
    </Fragment>
  )
}
