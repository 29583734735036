import React from 'react';
import { useEffect, useState } from 'react';
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import axios from 'axios';
import { apiUrl } from '../constants/global'
import Chart from 'react-apexcharts'
import formatAmount from 'indian-currency-formatter';
import EditProfileAssumption from './EditProfileAssumption';
import SweetAlert from 'sweetalert2'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

export default function Advise() {

   //eslint-disable-next-line
   //const [riskImg, setRiskImg] = useState('');
   //const [riskDescription, setRiskDescription] = useState('');
   const [roi, setRoi] = useState();
   const [riskTitle, setRiskTitle] = useState();
   const [strategyData, setStrategyData] = useState([]);
   const [profileAssumptionModal, setProfileAssumptionModal] = useState(false)
   const profileAssumptionToggle = () => setProfileAssumptionModal(!profileAssumptionModal);
   const [profileAssumption, setProfileAssumption] = useState([]);

   const editprofileassumption = () => {
      sessionStorage.setItem('page_referer', '/advise');
      setProfileAssumptionModal(!profileAssumptionModal);
   }

   const [apexDonutCharts, setApexDonutCharts] = useState({
      series: [6, 10],
      options: {
         chart: {
            type: 'donut',
         },
         colors: ['#51bb25', '#fb740d'],
         labels: ["Equity", "Debt"],
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 300
               },
               legend: {
                  position: 'bottom'
               }
            }
         }]
      },
   });

   const deleteStrategy = (id) => {


      SweetAlert.fire({
         title: "Are you sure?",
         text: "you want to delete this Strategy?",
         type: "warning",
         showCancelButton: true,
         confirmButtonColor: "#FF0000",
         confirmButtonText: "Yes",
         cancelButtonText: "Cancel",
         closeOnConfirm: false,
         closeOnCancel: false
      }).then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {
            axios.post(`${apiUrl}strategy/delete`, { 'id': id, 'profile_id': sessionStorage.getItem('profile_id') }).then(
               function (response) {


                  SweetAlert.fire({
                     toast: true,
                     icon: 'success',
                     title: 'Strategy Successfully Deleted.',
                     animation: false,
                     position: 'top-right',
                     showConfirmButton: false,
                     timer: 2000,
                     timerProgressBar: true,
                     didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                     }
                  }).then(function () {
                     window.location.href = "/advise";
                  });

               }
            )
         }
      })


   }

   useEffect(() => {

      const profile_id = sessionStorage.getItem('profile_id');

      axios.get(`${apiUrl}assumptions/get/${profile_id}`).then(
         (response, data) => {
            setProfileAssumption(response.data);
         }
      );

      axios.get(`${apiUrl}strategy/get/${profile_id}`).then(
         (response, data) => {
            setStrategyData(response.data);
         }
      );

      axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
         (response, data) => {
            if (response.data.status === 100) {
               //setRiskImg('assets/images/' + response.data.risk_profile.img);
               //setRiskDescription(response.data.risk_profile.risk_description);
               setRiskTitle(response.data.risk_profile.risk_description);
               setRoi(response.data.profgoalSummary.pre_return * 100);

               if (response.data.risk_profile !== '') {
                  setApexDonutCharts({
                     series: [response.data.risk_profile.max_val, 100 - response.data.risk_profile.max_val],
                     options: {
                        chart: {
                           type: 'donut',
                        },
                        colors: ['#1761c5', '#c1d9f6'],
                        labels: ["Equity", "Debt"],
                        responsive: [{
                           breakpoint: 480,
                           options: {
                              chart: {
                                 width: 200
                              },
                              legend: {
                                 show: true,
                                 position: 'bottom'
                              }
                           }
                        }]
                     },
                  });
               } else {
                  setApexDonutCharts(0);
               }

            }
         });


   }, []);

   return (
      <React.Fragment>
         <Header />
         <SideMenu />
         <div className="container">

            <div className="row">
               <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2 className="i-retrun mt-3"> Investment Style</h2>
                  <div className="my-goals Investment Expected Advise">
                     <div className="return return_first_tab">


                        <div className="Accumulated">


                           <div className="money">
                              <strong> Investment style </strong>
                              <span> {riskTitle === null || riskTitle === undefined ? 'NA' : riskTitle} </span>
                              <span className="chevron"> <i className="mdi mdi-chevron-down"></i> </span>
                           </div>

                           
                           <Modal isOpen={profileAssumptionModal} toggle={profileAssumptionToggle}>
                              <ModalHeader toggle={profileAssumptionToggle}>{"Edit Profile Assumptions"}</ModalHeader>
                              <ModalBody>
                                 <EditProfileAssumption data={profileAssumption} />
                              </ModalBody>
                           </Modal>

                        </div>
                        <div className="f-16 f-w-600 color176 m-t-40" onClick={() => editprofileassumption()}>(+/-) Assumptions %</div>
                     </div>

                     <div className="prosees">


                        <div id="donutchart">
                           {
                              roi === 0 || roi === null ? '' : <Chart options={apexDonutCharts.options} series={apexDonutCharts.series} type="donut" />
                           }

                        </div>


                     </div>

                  </div>


               </div>
            </div>

            <div className="row mt-5">
               <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="plantitle">

                     <div className=" float-left">
                        <h1>Your Strategies </h1>
                     </div>

                     <div className=" float-right">
                        <a className="aGoal" href="/strategy"> Create New + </a>
                     </div>


                  </div>
               </div>

            </div>

            {// eslint-disable-next-line
               strategyData.map((item, index) => {
                  return (
                     <>
                        <div className="row">
                           <div className="col-lg-8 col-md-8 col-sm-12">
                              <div className="my-goals Investment Expected Advise">
                                 <div className="row">
                                    <div className="col-md-4">
                                       <span>Strategy Name</span>
                                       <h1> {item.strategy_name} </h1>
                                    </div>
                                    <div className="col-md-4">
                                       <span>Portfolio</span>
                                       <div className="f-16 f-w-600 color176">Growth 1, Custom</div>
                                    </div>
                                    <div className="col-md-4">
                                       <span>View Portfolio Details</span>

                                       <span className="aGoal" onClick={() => deleteStrategy(item.id)}> <i className='fas fa-ellipsis-v'></i> </span>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-md-4">
                                       <span>Composition</span>
                                       <h1> - </h1>
                                    </div>
                                    <div className="col-md-4">
                                       <span>Expected ROI</span>
                                       <h1> {Math.round(item.roi)}% </h1>
                                    </div>
                                    <div className="col-md-4">

                                    </div>

                                 </div>
                                 <div className="row">
                                    <div className="col-md-4">
                                       <span>Equity - 60% Fixed Income - 20% <br /> Gold 20% Alternate 0%</span>
                                    </div>
                                    <div className="col-md-4">
                                       <span>No. Of Funds</span>
                                       <h1> 9 </h1>
                                    </div>
                                    <div className="col-md-4">
                                       <span>Rebalance / Review</span>
                                       <h1> Every 6 Months </h1>
                                    </div>

                                 </div>

                                 <div className="lightbox mt-3">
                                    <div className="row">
                                       <div className="col-6 col-md-3 text-center my-2">
                                          <p>Every Month</p>
                                          <h4><strong className="invested_amount">₹ {formatAmount(parseInt(item.monthly_amount))}</strong></h4>
                                       </div>
                                       <div className="col-6 col-md-3 text-center my-2">
                                          <p>One Time</p>
                                          <h4><strong className="investment_gain">₹ {formatAmount(parseInt(item.lumpsum_amount))}</strong></h4>
                                       </div>
                                       <div className="col-6 col-md-3 text-center my-2">
                                          <p>Time Period</p>
                                          <h4><strong className="suggested_fund">{item.duration}</strong></h4>
                                       </div>
                                       <div className="col-6 col-md-3 text-center my-2">
                                          <button type="button" className="btn Pay-Now btn-success float-right"> Execute</button>
                                       </div>
                                    </div>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </>
                  )
               })}



         </div>

      </React.Fragment >
   );
}
