import React, { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import { Button } from 'reactstrap'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';

export default function EditRetirement({ data, refUrl, action }) {

    console.log(data);
    const [validator, showValidationMessage] = useValidator();
    // eslint-disable-next-line
    const [expenses, setExpenses] = useState(formatAmount(data.total_abcd / 12));
    // eslint-disable-next-line
    const [inflation, setInflation] = useState(parseInt(data.goal_inflation));
    // eslint-disable-next-line
    const [retire_age, setRetire_age] = useState(parseInt(data.retire_age));
    // eslint-disable-next-line
    const [life_expectancy, setLife_expectancy] = useState(parseInt(data.life_expectancy));

    const [checked, setChecked] = useState(data.auto_reset === "1" ? '' : 'checked');
    const [auto_reset, setAuto_reset] = useState(data.auto_reset);

    const numtoWord = () => {

    }

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'expenses':
                setExpenses(formatAmount(e.target.value.replace(/,/g, "")));
                break;

            case 'inflation':
                setInflation(e.target.value);
                break;

            case 'retire_age':
                setRetire_age(e.target.value);
                break;

            case 'life_expectancy':
                setLife_expectancy(e.target.value);
                break;

            case 'auto_reset':
                setChecked(checked === 'checked' ? '' : 'checked');
                setAuto_reset(checked === 'checked' ? '1' : '0');
                break;

            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const required_after = retire_age - data.current_age;
            const required_till = life_expectancy - data.current_age;

            const postData = {
                "goal_type": "Retirement",
                "goal_name": "Retirement",
                "goal_id": data.goal_id,
                "aspire": data.aspire,
                "profile_id": data.profile_id,
                "auto_reset": auto_reset,
                "plan_for": {
                    "profile_details_id": data.plan_for,
                    "current_age": data.profile_age,
                    "relation": data.relation
                },
                "goal_params": {
                    "age": data.profile_age,
                    "retire_age": retire_age,
                    "life_expectancy": life_expectancy,
                    "post_inflation": 7,
                    "post_return": 8,
                    "goal_inflation": inflation,
                    "required_after": required_after,
                    "required_till": required_till
                },
                "risk_profile": {
                    "available": false,
                    "pre_return": 10,
                    "risk_profile_id": data.profile_id
                },
                "assumptions": {
                    "assumption_id": false,
                    "inflation": 7.5,
                    "income_grow": "10"
                },
                "total_abcd": [{
                    "amount": expenses.replace(/,/g, ""),
                    "frequency": 12,
                    "no_of_times": 1,
                    "gap": 0
                }],
                "actual": [{
                    "actual_y": 0,
                    "frequency": 1,
                    "actual_ls": 1,
                    "grow": "0"

                }]
            };


            const assumptionData = { profile_id: data.profile_id, assumption: { retire_age: retire_age, life_expentancy: life_expectancy} };

            axios.post(`${apiUrl}profile-assumptions/update_assumption`, assumptionData)
                .then(function (response) {
                    axios.post(`${apiUrl}goals/add`, postData)
                        .then(function (response) {


                            const gpostData = { profile_id: sessionStorage.getItem('profile_id') };
                            axios.post(`${apiUrl}goals/reset`, gpostData)
                                .then(function (response) {
                                    axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                                        (response, data) => {
                                            SweetAlert.fire(
                                                'Goals',
                                                'Successfully Updated Goal.',
                                                'success'
                                            ).then(function () {
                                                window.location.href = refUrl
                                            });
                                        }
                                    );
                                })
                        })
                        .catch(function (error) {
                            console.log(error);
                            SweetAlert.fire(
                                'Goals',
                                'Failed.',
                                'error'
                            )
                        });

                })




        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-row">
                <div className='row'>
                    <div className="col-md-6 input-popup">
                        <label> Current Monthly Expense </label>
                        <input type="text" className="form-control userInput" aria-label="Expense" name={"expenses"} value={expenses} onChange={handleChange} onKeyPress={numtoWord} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("expenses", expenses, "required", {
                            messages: {
                                required: "Expense is required"
                            }
                        })}
                        <div className="ruppy-popup"> <i className="fas fa-rupee-sign" /></div>
                    </div>

                    <div className="col-md-6 input-popup">
                        <label> Inflated at </label>
                        <input type="text" className="form-control" aria-label="Inflation" name={"inflation"} value={inflation} onChange={handleChange} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("inflation", inflation, "required", {
                            messages: {
                                required: "Goal Inflation is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> % </div>
                    </div>


                    <div className="col-md-6 input-popup">
                        <label> Retire Age </label>
                        <input type="text" className="form-control" aria-label="Retire Age" name={"retire_age"} value={retire_age} onChange={handleChange} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("retire_age", retire_age, "required", {
                            messages: {
                                required: "Retire Age is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> Years </div>
                    </div>

                    <div className="col-md-6 input-popup">
                        <label> Plan Till </label>
                        <input type="text" className="form-control" aria-label="Plan Till" name={"life_expectancy"} value={life_expectancy} onChange={handleChange} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("life_expectancy", life_expectancy, "required", {
                            messages: {
                                required: "Plan Till is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> Years </div>
                    </div>


                    {action !== "view" && (
                        <>
                            <div className="col-md-6 input-popup">

                                <label className="text-muted" htmlFor="checkbox1">Click to Disable Auto Reset </label>
                                <input id="checkbox1" type="checkbox" name={"auto_reset"} checked={checked} value={auto_reset} onChange={handleChange} className='autoReset' />

                            </div>
                        </>
                    )

                    }


                </div>

                {action !== "view" && (

                    <div className="form-group">
                        <div className="row">
                            <div className="col-12">
                                <Button color="success" className="mr-1" type="button" onClick={handleSubmit}>Save</Button>
                                <Button color="primary">{"Cancel"}</Button>
                            </div>
                        </div>
                    </div>
                )

                }

            </div>
        </>
    )
}