import React, { Fragment, useState, useEffect } from 'react';
//import GoalDob from './../GoalSteps/GoalDob';
import GoalProfile from './../GoalSteps/GoalProfile';
import GoalRetire from './../GoalSteps/GoalRetire';
import GoalExpense from './../GoalSteps/GoalExpense';

export default function AddRetirement({title, profileName, gStepChange, gsteps, defaultgoal}) {
  const [steps, setSteps] = useState(1);
  const [percentage, setPercentage] = useState(25);
  const [stepCount, setStepCount] = useState(0);

  const StepChange = (step) => {
    setSteps(step);
  }

  useEffect(() => {
    if(sessionStorage.getItem('goal_creation') === '1'){
      setSteps(2);
    }else{
      setSteps(1);
    }
 }, []);

  return (
    <Fragment>
      {steps === 1 && (<GoalProfile title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} setStepCount={setStepCount} goalType={"Retirement"}/>)}

      {steps === 2 && (<GoalExpense title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} gsteps={gsteps} gStepChange={gStepChange}/>)}

      {steps === 3 && (<GoalRetire title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} defaultgoal={defaultgoal}/>)}
    </Fragment>
  );
}
