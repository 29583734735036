import React, { Fragment } from 'react'
import { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';

export default function Step4({ step, ChangeStep , riskId, riskDescription, goalCount}) {

  const [validator, showValidationMessage] = useValidator();

  const [strategyName, setStrategyName] = useState();
  const [duration, setDuration] = useState();

  const handleSubmit = () => {
    if (validator.allValid()) {

      const Postdata = {
        "profile_id": sessionStorage.getItem('profile_id'),
        "risk_id": sessionStorage.getItem('risk'),
        "invest_type": sessionStorage.getItem('investType'),
        "lumpsum_amount": sessionStorage.getItem('lumpsum'),
        "monthly_amount": sessionStorage.getItem('monthly'),
        "strategy_name": strategyName,
        "duration": duration,
      }

      axios.post(`${apiUrl}strategy/add`, Postdata)
        .then(function (response) {
          SweetAlert.fire(
            'Strategy',
            'Successfully Added.',
            'success'
          ).then(function () {
            window.location.href = "/advise";
          })
        })
        .catch(function (error) {
          console.log(error);
          SweetAlert.fire(
            'Strategy',
            'Failed. Something went wrong. Retry again',
            'error'
          )
        });

    } else {
      showValidationMessage(true);
    }
  }

  const handleChange = (e) => {
    if (e.target.name === 'strategyName') {
      setStrategyName(e.target.value);
    } else if (e.target.name === 'duration') {
      setDuration(e.target.value);
    }
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12"> </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="financiali-tilte"></div>
          <div className=" box-shadow Creating-Financial">
            <h1> Set Objective </h1>

            <div className='row'>
              <div className='col-md-6'>
                <div className="input-box ruppys">
                  <label> For? </label>
                  <input type="text" className="form-control" name={"strategyName"} onChange={handleChange} />
                  {validator.message("strategyName", strategyName, "required", {
                    messages: {
                      required: "For is required"
                    }
                  })}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className="input-box ruppys">
                  <label> Duration? </label>
                  <input type="number" className="form-control" name={"duration"} onChange={handleChange} />
                  {validator.message("duration", duration, "required", {
                    messages: {
                      required: "Duration is required"
                    }
                  })}
                </div>
              </div>
            </div>


            <div className="bottombtn">
              <button className="back" disabled> <i className="fas fa-angle-left" /> </button>
              <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12"> </div>
      </div>
    </Fragment>
  )
}