import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { apiUrl } from '../constants/global';
import { LogIn, User } from 'react-feather';
import { isMobile } from 'react-device-detect';
import ToggleMenu from './ToggleMenu';
import Loader from '../pages/Loader';

export default function Header() {

  let navigate = useNavigate();
  

  const page = window.location.pathname;

  const loggedInUser = sessionStorage.getItem("token");
  if (loggedInUser === null) {
    navigate("/sign-in");
  }

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/sign-in");
  }

  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null) {
      axios.get(`${apiUrl}profile-details/getcreateprofile/${profile_id}`).then(
        (response, data) => {
          if (response.data.length > 0) {
            sessionStorage.setItem('CreateProfile', JSON.stringify(response.data[0]));
            sessionStorage.setItem('profile_details_id', response.data[0].profile_details_id);
          }
        });
    } else {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/sign-in";
    }

  }, []);

  return (
    <Fragment>
      <Loader />
      <header>
        <div className="container">
          {
            isMobile && <div className='MobileNav'><ToggleMenu /></div>
          }
          <div className="logo">
            <Link to="/"><img src="/assets/images/logo.svg" alt="Logo" /></Link>
          </div>
          <div className="headerRight">
            <ul className="menuLinks">
              <Link to="" className="menuClose"></Link>
              {sessionStorage.getItem('admin_id') !== null ? <li><Link to="/admin-dashboard">Admin Dashboard </Link></li> : ''}
              <li><Link to="/plan" className={page === '/plan' ? "selected" : ""}>Plan </Link></li>
              <li className="profile-nav onhover-dropdown p-0 me-0">
                <div className="media profile-media">
                  <div className="media-body">
                    <div className="proIcon"><div className="icon1" title="logout"></div></div>
                  </div>
                </div>
                <ul className="profile-dropdown onhover-show-div">
                  <li onClick={() => { window.location = "/profile" }}><User /><span>{"Plan Profile"} </span></li>
                  <li><LogIn /><span onClick={logout}>{"LogOut"}</span></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </Fragment>

  );
}
