import React, { useState } from 'react'
import useValidator from '../../hooks/useValidator'
import { apiUrl } from '../../constants/global'
import axios from "axios";
import SweetAlert from 'sweetalert2'
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'

export default function LifeStage({ StepChange, steps, setPercentage, percentage, stepCount, setStepCount }) {

    const [validator, showValidationMessage] = useValidator();
    const [life_stage, setLifeStage] = useState(sessionStorage.getItem('life_stage'));

    const handleChange = e => {
        console.log(e.target.name);
        const { value } = e.target;
        setLifeStage(value);

        if (value === 'marriedWithKids' || value === 'married') {
            setStepCount(5);
        } else {
            setStepCount(4);
        }
    };

    const handleSubmit = () => {
        if (validator.allValid()) {

            const PostData = {
                "life_stage": life_stage,
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": sessionStorage.getItem("profile_details_id"),
                "step": steps
            }

            axios.post(`${apiUrl}set-life-stage`, PostData)
                .then(function (response) {
                    SweetAlert.fire(
                        'Life Stage',
                        'Life Stage Updated.',
                        'success'
                    ).then(function () {
                        sessionStorage.setItem('life_stage', life_stage);
                        const profile_id = sessionStorage.getItem('profile_id');
                        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
                            (response, data) => {
                                if (response.data.status === 100) {
                                    sessionStorage.setItem('ProfSummary', JSON.stringify(response.data));
                                }
                            });

                        StepChange(steps + 1);
                        setPercentage(percentage + 100 / stepCount);
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Life Stage',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });


        } else {
            showValidationMessage(true);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="financiali-tilte"><Link to="/plan" className="goback"><i className="fas fa-angle-left" /></Link> Creating Your Financial Plan</div>

                    <Progress color="success" value="20" className="sm-progress-bar" />

                    <div className=" box-shadow Creating-Financial">
                        <h1> About Yourself </h1>
                        <div className="input-box selectdiv">
                            <label> Please Select your life stage </label>
                            <select name={"life_stage"} value={life_stage} onChange={handleChange}>
                                <option value="">Select Life Stage </option>
                                <option value="single">Single</option>
                                <option value="married">Married without kids</option>
                                <option value="marriedWithKids">Married with Kids</option>
                                <option value="separatedWithKids">Separated with Kids</option>
                                <option value="-1">Add</option>
                            </select>
                            {validator.message("life_stage", life_stage, "required", {
                                messages: {
                                    required: "Life Stage is required"
                                }
                            })}
                        </div>

                        <div className="bottombtn">
                            <button className="back" disabled> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
            </div>
        </>
    )
}