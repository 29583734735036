import React, { Fragment, useState, useEffect } from 'react';
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import { Card, CardHeader, CardBody, TabContent, TabPane, Modal, ModalHeader, ModalBody, Table } from 'reactstrap'
import axios from 'axios';
import { apiUrl } from '../constants/global';
import AddFamily from './AddFamily';
import { calculateAge } from '../constants/common'
import SweetAlert from 'sweetalert2'

export default function Profile() {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [members, setMembers] = useState([]);
    const [pName, setPName] = useState();
    const [pEmail, setPEmail] = useState();
    const [pMobile, setPMobile] = useState();
    const [pGender, setPGender] = useState();
    const [goalData, setgoalData] = useState([]);

    const EditMember = (data) =>{
        setgoalData(data);
        setModal(!modal);
    }

    const DeleteMember = (id) => {


        SweetAlert.fire({
          title: "Are you sure?",
          text: "you want to delete this Member?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#FF0000",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnConfirm: false,
          closeOnCancel: false
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.post(`${apiUrl}profile-details/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'profile_details_id': id }).then(
              function (response) {


                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Member Successfully Deleted.',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                       toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                       toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                 }).then(function () {
                    window.location.href = "/profile";
                 });

              }
            )
          }
        })
      }

      

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');

        axios.get(`${apiUrl}profile-details/getfamily/${profile_id}`).then(
            (response, data) => {
                setMembers(response.data);

                if(response.data[0].relation === "self"){
                    setPName(response.data[0].full_name);
                    setPEmail(response.data[0].email);
                    setPMobile(response.data[0].phone);
                    setPGender(response.data[0].gender);
                    
                }
            });

    }, []);

    return (
        <Fragment>
            <Header />
            <SideMenu />

            <div className="container">
                <div className="email-wrap bookmark-wrap">
                    <div className="row">
                        <h1 className='mt-4'> My Profile</h1>
                        <div className="col-xl-3 box-col-6">
                            <div className="email-left-aside">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="email-app-sidebar left-bookmark">
                                            <div className="media">
                                                <div className="media-size-email"><img className="me-3 rounded-circle" src="../assets/images/user/user.png" alt="" /></div>
                                                <div className="media-body">
                                                    <h6 className="f-w-600">{pName} <span style={{"font-size":"12px"}}>({pGender})</span></h6>
                                                    <p>{pEmail}</p>
                                                    <p>{pMobile}</p>
                                                </div>
                                            </div>

                                            <ul className="nav main-menu contact-options" role="tablist">
                                                <li className="nav-item">
                                                    <button className="badge-light-primary btn-block btn-mail w-100" type="button" onClick={toggle}> New Contacts</button>
                                                    
                                                    <Modal isOpen={modal} toggle={toggle}>
                                                        <ModalHeader toggle={toggle}>{"AddContacts"}</ModalHeader>
                                                        <ModalBody>
                                                            <AddFamily data={goalData}/>
                                                        </ModalBody>
                                                    </Modal>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-9 col-md-12 box-col-12">
                            <div className="email-right-aside bookmark-tabcontent contacts-tabs"></div>
                            <div className="email-body radius-left"></div>
                            <div className="pl-0">
                                <TabContent activeTab={"1"}>
                                    <TabPane tabId="1">
                                        <Card className="mb-0">
                                            <CardHeader className="d-flex">
                                                <span className="f-14 pull-right mt-0">{members.length} {"Contacts"}</span>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="best-seller-table responsive-tbl">
                                                    <div className="item">
                                                        <div className="table-responsive product-list">
                                                            <Table borderless>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="f-22">{"Name"}</th>
                                                                        <th>{"Date"}</th>
                                                                        <th>{"Relation"}</th>
                                                                        <th className="text-right">{"Action"}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {// eslint-disable-next-line
                                                                        members.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="d-inline-block align-middle"><img className="img-40 m-r-15 rounded-circle align-top" src="assets/images/Education.svg" alt="" />
                                                                                                <div className="status-circle bg-primary"></div>
                                                                                                <div className="d-inline-block"><span>{item.full_name}</span>
                                                                                                    <p className="font-roboto">{"Age:" + calculateAge(item.dob)}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{item.dob_display}</td>
                                                                                        <td>{item.relation}</td>
                                                                                        <td className="text-right">
                                                                                            {
                                                                                                item.relation === 'self' ? <span onClick={() => EditMember(item)}><i className="fas fa-pencil-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span> : (<div>
                                                                                                    <span onClick={() => DeleteMember(item.profile_details_id)}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span>
                                                                                                    <span onClick={() => EditMember(item)}><i className="fas fa-pencil-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span>
                                                                                                </div>)
                                                                                            }
                                                                                            

                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })}



                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="clear"></div>
            <Footer />
        </Fragment>
    );
}
