import React, { useState, useEffect } from 'react'
import useValidator from '../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import numWords from 'num-words';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../constants/global';
import { Progress } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'

export default function EditEmi() {

    let { emiId } = useParams();

    const [validator, showValidationMessage] = useValidator();
    const [sub_type, setSubType] = useState();
    const [loan_amount, setloan_amount] = useState();
    const [outstanding_amt, setoutstanding_amt] = useState();
    const [emi, setemi] = useState()
    const ucwords = require('ucwords');
    const [profileId, setProfileId] = useState();
    const [profileDetId, setProfileDetId] = useState();
    const [loan_amount_txt, setLoanAmt] = useState("");
    const [outstanding_amt_txt, setOutAmt] = useState("");
    const [emi_txt, setEmiAmt] = useState("");

    useEffect(() => {

        axios.get(`${apiUrl}prof-liability/edit/${emiId}`).then(
            (response, data) => {

                setSubType(formatAmount(response.data.sub_type));
                setloan_amount(formatAmount(response.data.loan_amount));
                setoutstanding_amt(formatAmount(response.data.outstanding_loan));
                setemi(formatAmount(response.data.emi));

                setLoanAmt(ucwords(numWords(response.data.loan_amount)));
                setOutAmt(ucwords(numWords(response.data.outstanding_loan)));
                setEmiAmt(ucwords(numWords(response.data.emi)));
                setProfileId(response.data.profile_id);
                setProfileDetId(response.data.profile_details_id);
            });

    }, [emiId, ucwords])

    const handleChange = (e) => {
        console.log(e.target.name);
        const { value } = e.target;

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));

        if (e.target.name === 'sub_type') {
            setSubType(value);
        } else if (e.target.name === 'loan_amount') {
            setloan_amount(formatAmount(value.replace(/,/g, "")));
            setLoanAmt(ucwords(amountInWords));
        } else if (e.target.name === 'outstanding_amt') {
            setoutstanding_amt(formatAmount(value.replace(/,/g, "")));
            setOutAmt(ucwords(amountInWords));
        } else if (e.target.name === 'emi') {
            setemi(formatAmount(value.replace(/,/g, "")));
            setEmiAmt(ucwords(amountInWords));
        }

    };

    const deleteEmi = (emiId) => {
        SweetAlert.fire({
            title: "Are you sure?",
            text: "you want to delete this EMI?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FF0000",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            closeOnConfirm: false,
            closeOnCancel: false
        })
        .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                    axios.post(`${apiUrl}prof-liability/delete`, {profile_id:profileId, id:emiId}).then(
                        function (response) {
                            SweetAlert.fire(
                                'EMI',
                                'Successfully Deleted.',
                                'success'
                            ).then(function () {
                                window.location.href = "/cashflow";
                            });
                        }
                    )
                }
            });
    }


    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const Postdata = {
                "profile_id": profileId,
                "profile_details_id": profileDetId,
                "co_name": profileId,
                "owner": profileId,
                "instr_name": "Loan",
                "type": 20,
                "sub_type": sub_type,
                "loan_amount": loan_amount.replace(/,/g, ""),
                "outstanding_loan": outstanding_amt.replace(/,/g, ""),
                "emi": emi.replace(/,/g, ""),
                "liability_id": emiId
            };

            axios.post(`${apiUrl}prof-liability`, Postdata)
                .then(function (response) {
                    SweetAlert.fire(
                        'Loans',
                        'Loan Updated Successfully!!!',
                        'success'
                    ).then(function () {

                        window.location.href = "/cashflow";

                    });
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Loans',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });


        } else {
            showValidationMessage(true);
        }
    }

    return (
        <div>
            <div className="tap-top"><i data-feather="chevrons-up"></i></div>
            <div className="page-wrapper CustomPage">
                <div className="container">
                    <div className="row DivJustify">
                        <div className="col-lg-8 col-md-12 col-sm-12">


                            <div className="financiali-tilte"><Link to="/cashflow" className="goback"><i className="fas fa-angle-left" /></Link>{"Edit Loan"}</div>
                            <Progress color="success" value="100" className="sm-progress-bar" />
                            <div className=" box-shadow Creating-Financial">

                                <div className="input-box selectdiv mb-4">
                                    <label> Loan Type </label>
                                    <select className="form-control digits" name={"sub_type"} value={sub_type} onChange={handleChange} autoComplete="off">
                                        <option value="">Select Loan Type </option>
                                        <option value="1">Home Loan</option>
                                        <option value="2">Car Loan</option>
                                        <option value="3">Personal Loan</option>
                                        <option value="4">LAP</option>
                                        <option value="5">Gold Loan</option>
                                        <option value="6">Others</option>
                                    </select>
                                    {validator.message("sub_type", sub_type, "required", {
                                        messages: {
                                            required: "Loan Type is required"
                                        }
                                    })}
                                </div>

                                <div className="input-box ruppys yearly">
                                    <label> Amount Availed </label>
                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"loan_amount"} value={loan_amount} onChange={handleChange} autoComplete="off" />
                                    <span>{loan_amount_txt}</span>
                                    {validator.message("loan_amount", loan_amount, "required", {
                                        messages: {
                                            required: "Loan Amount is required"
                                        }
                                    })}
                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                </div>

                                <div className="input-box ruppys yearly">
                                    <label> EMI </label>
                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"emi"} value={emi} onChange={handleChange} autoComplete="off" />
                                    <span>{emi_txt}</span>
                                    {validator.message("emi", emi, "required", {
                                        messages: {
                                            required: "Loan EMI is required"
                                        }
                                    })}
                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                </div>

                                <div className="input-box ruppys yearly">
                                    <label> Current Total Oustanding Amount </label>
                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"outstanding_amt"} value={outstanding_amt} onChange={handleChange} autoComplete="off" />
                                    <span>{outstanding_amt_txt}</span>
                                    {validator.message("outstanding_amt", outstanding_amt, "required", {
                                        messages: {
                                            required: "Loan Outstanding amount is required"
                                        }
                                    })}
                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12">
                                        
                                        <div className="text-start">
                                        
                                            </div>
                                            <div className="text-end">
                                            <button className="btn-square btn btn-danger btn-xs EmiDelete" onClick={()=>deleteEmi(emiId)}>Delete</button>
                                                <button className="next" type="button" onClick={handleSubmit}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
