import React, { useState } from 'react'

import AddLoan from './LoanProfile/AddLoan';
import AddLoanChoice from './LoanProfile/AddLoanChoice';

export default function Loans() {

  const [steps, setSteps] = useState(1);

  const StepChange = (step) => {
    setSteps(step);
  } 

  return (
    <div>
      <div className="tap-top"><i data-feather="chevrons-up"></i></div>
      <div className="page-wrapper CustomPage">
        <div className="container">



          {steps === 1 && (<AddLoanChoice StepChange={StepChange} />)}
          {steps === 2 && (<AddLoan StepChange={StepChange} />)}



        </div>
      </div>
    </div>
  )
}