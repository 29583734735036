import React, { Fragment, useState } from 'react'
import AddInsurance from './RiskProfile/AddInsurance';
import AddInsuranceChoice from './RiskProfile/AddInsuranceChoice';

export default function Insurance() {

  const [steps, setSteps] = useState(1);
  const StepChange = (step) => {
    setSteps(step);
  }

  return (
    <Fragment>
      <div className="tap-top"><i data-feather="chevrons-up"></i></div>
      <div className="page-wrapper CustomPage">
        <div className="container">
          {steps === 1 && (<AddInsuranceChoice StepChange={StepChange} />)}
          {steps === 2 && (<AddInsurance StepChange={StepChange} />)}
        </div>
      </div>
    </Fragment>
  )
}
