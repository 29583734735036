import React, { useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Link } from 'react-router-dom'

export default function Income({ StepChange, steps, setPercentage, percentage, stepCount, setStepCount }) {

    const [validator, showValidationMessage] = useValidator();
    const [primary_income, setPrimaryIncome] = useState("");
    const [other_income, setOtherIncome] = useState("");
    const [rental_income, setRentalIncome] = useState("");
    const [primaryFreq, setPrimaryF] = useState(12);
    const [otherFreq, setOtherF] = useState(12);
    const [rentalFreq, setRentalF] = useState(12);



    useEffect(() => {
        let profSumm = JSON.parse(sessionStorage.getItem('ProfSummary'));

        profSumm.incomes.forEach((value, index) => {


            if (value.sub_type === "IncomeSelf") {
                setPrimaryIncome(formatAmount(value.total_amount / value.frequency));
                setPrimaryF(value.frequency);
            } else if (value.sub_type === "Bonus") {
                setOtherIncome(formatAmount(value.total_amount/value.frequency));
                setOtherF(value.frequency);
            } else if (value.sub_type === "RentalIncome") {
                setRentalIncome(formatAmount(value.total_amount / value.frequency));
                setRentalF(value.frequency);
            } else if (value.sub_type === "IncomeSpouse") {
                sessionStorage.setItem('IncomeSpouse', value.total_amount)
            }

        });
    }, [])


    const previousButton = () => {
        StepChange(steps - 1);
        setPercentage(percentage - 20);
    }

    const handleChange = e => {
        const { value } = e.target;
        if (e.target.name === 'primary_income') {
            setPrimaryIncome(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'other_income') {
            setOtherIncome(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'rental_income') {
            setRentalIncome(formatAmount(value.replace(/,/g, "")));
        }

        numtoWord(e);
    };

    const handleSubmit = () => {
        if (validator.allValid()) {

            const Postdata = {
                "profile_id": sessionStorage.getItem('profile_id'),
                "type": 1,
                "consider": 1,
                "step": steps,
                "income": {
                    "IncomeSelf": {
                        "sub_type": "IncomeSelf", "amount": primary_income, "frequency": primaryFreq
                    }
                }

            };

            if (other_income !== '') {
                Postdata.income.OtherIncome = {
                    "sub_type": "Bonus", "amount": other_income, "frequency": otherFreq
                };
            }

            if (rental_income !== '') {
                Postdata.income.RentalIncome = {
                    "sub_type": "RentalIncome", "amount": rental_income, "frequency": rentalFreq
                };
            }

            axios.post(`${apiUrl}profile-income`, Postdata)
                .then(function (response) {
                    SweetAlert.fire(
                        'Income',
                        'Self Incomme Updated.',
                        'success'
                    ).then(function () {
                        const profile_id = sessionStorage.getItem('profile_id');
                        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
                            (response, data) => {
                                if (response.data.status === 100) {
                                    sessionStorage.setItem('ProfSummary', JSON.stringify(response.data));
                                }
                            });

                        StepChange(steps + 1);
                        setPercentage(percentage + 100 / stepCount);
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Income',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    };


    const [primary_amt, setPrimary_amt] = useState("");
    const [other_amt, setOther_amt] = useState("");
    const [rental_amt, setRental_amt] = useState("");



    const numtoWord = e => {

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');

        if (e.target.name === 'primary_income') {
            setPrimary_amt(ucwords(amountInWords));
        } else if (e.target.name === 'other_income') {
            setOther_amt(ucwords(amountInWords));
        } else if (e.target.name === 'rental_income') {
            setRental_amt(ucwords(amountInWords));
        }



    }

    return (
        <>


            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="financiali-tilte"><Link to="/plan" className="goback"><i className="fas fa-angle-left" /></Link> Creating Your Financial Plan</div>
                    <div className=" box-shadow Creating-Financial">
                        <h1> About Cashflow: Income </h1>



                        <div className="input-box ruppys">
                            <label> Primary Income </label>
                            <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"primary_income"} value={primary_income} onChange={handleChange} onKeyPress={numtoWord} />
                            <div className="intext text-word"> {primary_amt} </div>
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={primaryFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="primary" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                setPrimaryF(checked === true ? 1 : 12)
                            }} />
                            {validator.message("primary_income", primary_income, "required", {
                                messages: {
                                    required: "Primary Income is required"
                                }
                            })}
                        </div>
                        <div className="input-box ruppys yearly">
                            <label> Bonus, Dividends etc? </label>
                            <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"other_income"} value={other_income} onChange={handleChange} />

                            <div className="intext text-word"> {other_amt} </div>
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={otherFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="primary" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                setOtherF(checked === true ? 1 : 12)
                            }} />
                        </div>
                        <div className="input-box ruppys yearly">
                            <label> Rental Income, if any </label>
                            <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"rental_income"} value={rental_income} onChange={handleChange} />

                            <div className="intext text-word"> {rental_amt} </div>
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={rentalFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="primary" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                setRentalF(checked === true ? 1 : 12)
                            }} />
                        </div>
                        <div className="bottombtn">
                            <button type="button" className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type="button" onClick={handleSubmit}>{"Next"}<i className="fas fa-angle-right" /></button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
            </div>

        </>
    )
}
