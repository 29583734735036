import React, { Fragment, useState } from 'react'
import LifeStage from './CreateProfile/LifeStage';
import BasicDetails from './CreateProfile/BasicDetails';
import Income from './CreateProfile/Income';
import Spouse from './CreateProfile/Spouse';
import Expense from './CreateProfile/Expense';
import { useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../constants/global';
import Loader from './Loader';

export default function CreateProfile() {

  const [steps, setSteps] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [stepCount, setStepCount] = useState(0);

  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
      (response, data) => {


        if (response.data.status === 100) {
          sessionStorage.setItem('ProfSummary', JSON.stringify(response.data));
          sessionStorage.setItem('life_stage', response.data.self_data.lifeStage);
          sessionStorage.setItem('self_dob', response.data.self_data.dob);
          sessionStorage.setItem('self_gender', response.data.self_data.gender)
          if (response.data.step_res.length > 0) {
            setSteps(response.data.step_res[0].idPlanSteps > 5 ? 1 : response.data.step_res[0].idPlanSteps);
          }
        }
      });
  }, [])



  const StepChange = (step) => {
    setSteps(step);
  }

  return (
    <Fragment>
      <Loader />
      <div className="graybg">

        <form className="theme-form">
          {steps === 1 && (<LifeStage StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} setStepCount={setStepCount} />)}

          {steps === 2 && (<BasicDetails StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}

          {steps === 3 && (<Income StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}

          {steps === 4 && (<Expense StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}

          {(steps === 5 && sessionStorage.getItem('life_stage') !== "single") && (<Spouse StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}

        </form>

      </div>
    </Fragment>

  )
}