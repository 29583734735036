import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Input, Col } from 'reactstrap'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { apiUrl } from '../../constants/global';
import SweetAlert from 'sweetalert2'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'

export default function WealthForm({ title, StepChange, steps, gpsteps, gStepChange, progress, goalName }) {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [start_in, setUserval] = useState(5);
  const [value, setvalue] = useState();
  const [amt, setAmt] = useState("");
  const [expense, setExpense] = useState("");

  const skipGoal = () => {

    let goalStep = sessionStorage.getItem('goalStep');
    let increment = 1;
    if(sessionStorage.getItem('life_stage') === "single" || sessionStorage.getItem('life_stage') === "married"){
      increment = 1;
      if (goalStep >= 4) {
        window.location.href = '/goals';
      }
    }else{
      if (goalStep >= 3 && sessionStorage.getItem('kids') !== '1') {
        increment = 2;
      }
    }

    
    console.log('increment'+increment);
    sessionStorage.setItem('goalStep', parseInt(goalStep) + increment);
    gStepChange(parseInt(goalStep) + increment);
  }

  const previousButton = () => {
    StepChange(steps - 1);
  }

  const onSliderChange = value => {
    setvalue(value);
    setUserval(value);
  };

  const onAfterChange = value => {
    setUserval(null);
  };

  const handleInputChange = (event) => {
    setvalue(event.target.value);
    setUserval(event.target.value);
};

  const numtoWord = e => {
    const { value } = e.target;
    setExpense(formatAmount(value.replace(/,/g, "")));

    const amountInWords = numWords(e.target.value.replace(/,/g, ""));
    const ucwords = require('ucwords');
    setAmt(ucwords(amountInWords));
  }

  const handleBlur = () => {
    if (start_in < 0) {
      setUserval(0);
    } else if (start_in > 100) {
      setUserval(100);
    }
  };

  const onSubmit = data => {

    const age = 45;
    const life_expectancy = 85;
    const relation = "self";
    const required_after = value;
    const required_till = value;
    const postData = {
      "goal_type": "Wealth",
      "goal_name": goalName,
      "aspire": "Buy " + goalName,
      "profile_id": sessionStorage.getItem('profile_id'),
      "plan_for": {
        "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
        "current_age": sessionStorage.getItem('current_age'),
        "relation": relation
      },
      "goal_params": {
        "age": age,
        "retire_age": value,
        "life_expectancy": life_expectancy,
        "post_inflation": 7,
        "post_return": 8,
        "goal_inflation": 7,
        "required_after": required_after,
        "required_till": required_till
      },
      "risk_profile": {
        "available": false,
        "pre_return": 10,
        "risk_profile_id": sessionStorage.getItem('profile_id')
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": data.expense.replace(/,/g, ""),
        "frequency": 12,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };

    axios.post(`${apiUrl}goals/add`, postData)
      .then(function (response) {

        axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
          (response, data) => {
            SweetAlert.fire(
              'Goals',
              'Added New Goal.',
              'success'
            ).then(function () {
              skipGoal();
            })
          }
        );


      }).catch(function (error) {
        console.log(error);
        SweetAlert.fire(
          'Goals',
          'Failed.',
          'error'
        )
      });;

  };

  useEffect(() => {
    setvalue(5);
}, []);

  return (
    <Fragment>
      <div className="row DivJustify">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="financiali-tilte"><Link to="/plan-status" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
          <Progress color="success" value={progress} className="sm-progress-bar" />
          <div className=" box-shadow Creating-Financial">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="input-box ruppys yearly">
                <label> Required Amount as of Today </label>
                <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name="expense" value={expense} {...register("expense", { required: true })} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' />
                <span className="text-word">{amt}</span><br />
                {errors.expense && <span className='srv-validation-message'>Amount is required</span>}
                <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
              </div>

              <div className="input-box ruppys yearly">
                <div md="10">
                  <label>{"In how many years ?"}</label></div>
                <div md="2">
                  <Input
                    value={start_in}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    type="text"
                    style={{ marginTop: '50px' }}
                  />
                </div>
                <Col md="12">
                  <Slider
                    onChange={onSliderChange}
                    onAfterChange={onAfterChange}
                    min={1}
                    max={20}
                    defaultValue={value}
                    value={value}
                  />
                </Col>
              </div>

              <div className="bottombtn mt-7">
                <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                <button className="skipBtn" onClick={skipGoal}> SKIP </button>
                <button className="next" type='submit'> Next<i className="fas fa-angle-right" /> </button>
              </div>
            </form>

          </div>



        </div>

      </div>
    </Fragment>
  )
}
