import React, { useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import numWords from 'num-words';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";

export default function AddGold() {

    const [validator, showValidationMessage] = useValidator();
    const ucwords = require('ucwords');
    const [JobData, setJobData] = useState([])


    const [invest_amount, setinvest_amount] = useState("");
    const [outstanding_amt_txt, setOutAmt] = useState("");
    const [sub_type, setsub_type] = useState("");
    const [investor_name, setInvestor_name] = useState("");
    const [frequency, setFrequency] = useState("");
    const [amount, setAmount] = useState("");
    const [amount_txt, setAmount_txt] = useState("");
    let { Id } = useParams();
    const [invest_id, setInvest_id] = useState("");

    useEffect(() => {
        let profSumm = JSON.parse(sessionStorage.getItem('ProfSummary'));
        setJobData(profSumm.prof_det);

        if (Id != null) {
            axios.get(`${apiUrl}investment/edit/${Id}`).then(
                (response, data) => {
                    if (response.data.invest_id != null) {
                        setInvestor_name(response.data.profile_details_id);
                        setAmount(formatAmount(response.data.invest_amount))
                        setsub_type(response.data.sub_type);
                        setinvest_amount(formatAmount(response.data.current_value));
                        setInvest_id(response.data.invest_id);
                        setFrequency(response.data.frequency);

                    }
                });
        }
    }, [Id])


    const handleChange = (e) => {
        console.log(e.target.name);
        const { value } = e.target;

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));

        if (e.target.name === 'invest_amount') {
            setinvest_amount(formatAmount(value.replace(/,/g, "")));
            setOutAmt(ucwords(amountInWords));
        } else if (e.target.name === 'amount') {
            setAmount(formatAmount(value.replace(/,/g, "")));
            setAmount_txt(ucwords(amountInWords));
        } else if (e.target.name === 'sub_type') {
            setsub_type(value);
        } else if (e.target.name === 'investor_name') {
            setInvestor_name(value);
        } else if (e.target.name === 'frequency') {
            setFrequency(value);
        }

    };


    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const Postdata = {
                "prop_id": sessionStorage.getItem('profile_id'),
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": investor_name,
                "type": 6,
                "owner": sessionStorage.getItem('profile_id'),
                "investor_name": investor_name,
                "sub_type": sub_type,
                "frequency": frequency,
                "current_value": invest_amount.replace(/,/g, ""),
                "amount": amount.replace(/,/g, ""),
                "invest_amount": amount.replace(/,/g, ""),
                "invest_id": invest_id
            };

            axios.post(`${apiUrl}investment/add-investment`, Postdata)
                .then(function (response) {


                    SweetAlert.fire({
                        toast: true,
                        icon: 'success',
                        title: 'GOLD Successfully added/updated.',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    }).then(function () {
                        window.location.href = "/track-portfolio";
                    });

                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'GOLD',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <div className="row DivJustify">
            <div className="col-lg-8 col-md-12 col-sm-12">

                <div className="financiali-tilte"><h1><Link to="/track-portfolio" className="goback"><i className="fas fa-angle-left" /></Link> Add Gold</h1></div>
                <Progress color="success" value="100" className="sm-progress-bar" />
                <div className=" box-shadow Creating-Financial">

                    <div className="input-box selectdiv mb-4">
                        <label> Type </label>



                        <select className="form-control digits" name={"sub_type"} value={sub_type} onChange={handleChange} autoComplete="off">
                            <option value="">Select Type </option>
                            <option value="1">Bar</option>
                            <option value="2">Jewellery</option>
                            <option value="3">Scheme</option>
                        </select>
                        {validator.message("sub_type", sub_type, "required", {
                            messages: {
                                required: "Type is required"
                            }
                        })}
                    </div>

                    <div className="input-box selectdiv mb-4">
                        <label> Investment Type </label>

                        <select name={"frequency"} value={frequency} className="form-field select-input" onChange={handleChange} autoComplete="off">
                            <option value="12">Monthly</option>
                            <option value="4">Quarterly</option>
                            <option value="2">Half Yearly</option>
                            <option value="1">Yearly</option>
                            <option value="0">Lumpsum</option>
                        </select>

                        {validator.message("frequency", frequency, "required", {
                            messages: {
                                required: "Investment Type is required"
                            }
                        })}
                    </div>


                    <div className="input-box selectdiv mb-4">
                        <label> Investor Name </label>



                        <select className="form-control digits" name={"investor_name"} value={investor_name} onChange={handleChange} autoComplete="off">
                            <option value="">Select Investor Name </option>
                            {JobData.map((data, i) => {
                                return (
                                    <option value={data.profile_details_id} key={i} g_dob={data.dob}>{data.full_name}</option>
                                )
                            })}
                        </select>
                        {validator.message("investor_name", investor_name, "required", {
                            messages: {
                                required: "Investor Name is required"
                            }
                        })}
                    </div>


                    <div className="input-box ruppys yearly">
                        <label> Amount </label>
                        <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"amount"} value={amount} onChange={handleChange} />
                        <span className="text-word">{amount_txt}</span>
                        {validator.message("amount", amount, "required", {
                            messages: {
                                required: "Amount is required"
                            }
                        })}
                        <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                    </div>


                    <div className="input-box ruppys yearly">
                        <label> Current Total Value </label>
                        <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"invest_amount"} value={invest_amount} onChange={handleChange} />
                        <span className="text-word">{outstanding_amt_txt}</span>
                        {validator.message("invest_amount", invest_amount, "required", {
                            messages: {
                                required: "Current Value is required"
                            }
                        })}
                        <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                    </div>




                    <div className="form-group">

                        <div className="row">


                            <div className="col-12">
                                <div className="text-end">
                                    <button className="next" type="button" onClick={handleSubmit}>Save</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
