//get-income-expense
import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import useValidator from '../../hooks/useValidator'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export default function EditExpense({ data }) {

    const [validator, showValidationMessage] = useValidator();
    const [expesne, setExpesne] = useState([]);

    // eslint-disable-next-line
    const [Household, setHousehold] = useState();
    // eslint-disable-next-line
    const [UtilityBills, setUtilityBills] = useState('');
    // eslint-disable-next-line
    const [ChildrenEducation, setChildrenEducation] = useState('');
    // eslint-disable-next-line
    const [Lifestyle, setLifestyle] = useState('');
    // eslint-disable-next-line
    const [Vacation, setVacation] = useState('');
    // eslint-disable-next-line
    const [rentalExpense, setRentalExpense] = useState('');

    // eslint-disable-next-line
    const [HouseholdFreq, setHouseholdFreq] = useState('');
    // eslint-disable-next-line
    const [UtilityBillsFreq, setUtilityBillsFreq] = useState('');
    // eslint-disable-next-line
    const [ChildrenEducationFreq, setChildrenEducationFreq] = useState('');
    // eslint-disable-next-line
    const [LifestyleFreq, setLifestyleFreq] = useState('');
    // eslint-disable-next-line
    const [VacationFreq, setVacationFreq] = useState('');
    // eslint-disable-next-line
    const [rentalExpenseFreq, setRentalExpenseFreq] = useState('');


    // eslint-disable-next-line
    const [HouseholdId, setHouseholdId] = useState('');
    // eslint-disable-next-line
    const [UtilityBillsId, setUtilityBillsId] = useState('');
    // eslint-disable-next-line
    const [ChildrenEducationId, setChildrenEducationId] = useState('');
    // eslint-disable-next-line
    const [LifestyleId, setLifestyleId] = useState('');
    // eslint-disable-next-line
    const [VacationId, setVacationId] = useState('');
    // eslint-disable-next-line
    const [rentalExpenseId, setRentalExpenseId] = useState('');

    useEffect(() => {
        const Params = { profile_id: sessionStorage.getItem('profile_id'), type: 2 };

        axios.post(`${apiUrl}get-income-expense`, Params).then(
            (response, data) => {
                setExpesne(response.data);
                response.data
                    .forEach(item => {
                        switch (item.type_name_sf) {

                            case 'Household':
                                setHouseholdId(item.income_id);
                                setHouseholdFreq(item.frequency);
                                setHousehold(item.amount);
                                break;

                            case 'UtilityBills':
                                setUtilityBillsId(item.income_id);
                                setUtilityBillsFreq(item.frequency);
                                setUtilityBills(item.amount);
                                break;

                            case 'ChildrenEducation':
                                setChildrenEducationId(item.income_id);
                                setChildrenEducationFreq(item.frequency);
                                setChildrenEducation(item.amount);
                                break;

                            case 'Lifestyle':
                                setLifestyleId(item.income_id);
                                setLifestyleFreq(item.frequency);
                                setLifestyle(item.amount);
                                break;

                            case 'Vacation':
                                setVacationId(item.income_id);
                                setVacationFreq(item.frequency);
                                setVacation(item.amount);
                                break;

                            case 'rentalExpense':
                                setRentalExpenseId(item.income_id);
                                setRentalExpenseFreq(item.frequency);
                                setRentalExpense(item.amount);
                                break;

                            default:
                                break;
                        }
                    })
            }
        );

    }, []);

    const FrequencyChange = (e, id) => {

        const value = e === false ? 12 : 1;
        switch (id) {

            case 'Household':
                setHouseholdFreq(value);
                break;

            case 'UtilityBills':
                setUtilityBillsFreq(value);
                break;

            case 'ChildrenEducation':
                setChildrenEducationFreq(value);
                break;

            case 'Lifestyle':
                setLifestyleFreq(value);
                break;

            case 'Vacation':
                setVacationFreq(value);
                break;

            case 'rentalExpense':
                setRentalExpenseFreq(value);
                break;

            default:
                break;
        }

    }
    const handleChange = (e) => {
        let income_id = e.target.getAttribute("income_id");
        switch (e.target.name) {

            case 'Household':
                setHousehold(e.target.value);
                setHouseholdId(income_id);
                break;

            case 'UtilityBills':
                setUtilityBills(e.target.value);
                setUtilityBillsId(income_id);
                break;

            case 'ChildrenEducation':
                setChildrenEducation(e.target.value);
                setChildrenEducationId(income_id);
                break;

            case 'Lifestyle':
                setLifestyle(e.target.value);
                setLifestyleId(income_id);
                break;

            case 'Vacation':
                setVacation(e.target.value);
                setVacationId(income_id);
                break;

            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const postData = {
                profile_id: sessionStorage.getItem('profile_id'),
                type: 2,
                data: [{
                    income_id: HouseholdId,
                    amount: Household,
                    frequency: HouseholdFreq,
                    sub_type: "Household"
                },
                {
                    income_id: UtilityBillsId,
                    amount: UtilityBills,
                    frequency: UtilityBillsFreq,
                    sub_type: "UtilityBills"
                },
                {
                    income_id: ChildrenEducationId,
                    amount: ChildrenEducation,
                    frequency: ChildrenEducationFreq,
                    sub_type: "ChildrenEducation"
                },
                {
                    income_id: LifestyleId,
                    amount: Lifestyle,
                    frequency: LifestyleFreq,
                    sub_type: "Lifestyle"
                },
                {
                    income_id: VacationId,
                    amount: Vacation,
                    frequency: VacationFreq,
                    sub_type: "Vacation"
                }]
            };

            axios.post(`${apiUrl}update-income-expense`, postData)
                .then(function (response) {

                    axios.post(`${apiUrl}goals/reset`, { profile_id: sessionStorage.getItem('profile_id') })
                        .then(function (response) {

                            axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                                (response, data) => {


                                    SweetAlert.fire({
                                        toast: true,
                                        icon: 'success',
                                        title: 'Successfully Updated Expense.',
                                        animation: false,
                                        position: 'top-right',
                                        showConfirmButton: false,
                                        timer: 2000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                           toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                           toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                                        }
                                     }).then(function () {
                                        window.location.href = "/adjust"
                                     });

                                }
                            );
                        })

                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Expense',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-bookmark">
                <div className="form-row">

                    {expesne.map((item, index) => {

                        return (
                            <>
                                <div className="mb-3 col-md-12 my-0" key={index}>

                                    {
                                        item.type_name_sf === 'Household' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" checked={HouseholdFreq === 1 ? true : false} width={70} height={40} onlabel='YEARLY' offlabel='MONTHLY' style="toggleBtn toggleBtnC" onstyle="success" offstyle="danger" size="sm" onChange={(checked) => {
                                                        FrequencyChange(checked, 'Household');
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'UtilityBills' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" checked={UtilityBillsFreq === 1 ? true : false} width={70} height={40} onlabel='YEARLY' offlabel='MONTHLY' style="toggleBtn toggleBtnC" onstyle="success" offstyle="danger" size="sm" onChange={(checked) => {
                                                        FrequencyChange(checked, 'UtilityBills');
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'ChildrenEducation' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" checked={ChildrenEducationFreq === 1 ? true : false} width={70} height={40} onlabel='YEARLY' offlabel='MONTHLY' style="toggleBtn toggleBtnC" onstyle="success" offstyle="danger" size="sm" onChange={(checked) => {
                                                        FrequencyChange(checked, 'ChildrenEducation');
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'Lifestyle' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" checked={LifestyleFreq === 1 ? true : false} width={70} height={40} onlabel='YEARLY' offlabel='MONTHLY' style="toggleBtn toggleBtnC" onstyle="success" offstyle="danger" size="sm" onChange={(checked) => {
                                                        FrequencyChange(checked, 'Lifestyle');
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'Vacation' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" checked={VacationFreq === 1 ? true : false} width={70} height={40} onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" size="sm" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                                        FrequencyChange(checked, 'Vacation');
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'rentalExpense' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" checked={rentalExpenseFreq === 1 ? true : false} width={70} height={40} onlabel='YEARLY' offlabel='MONTHLY' style="toggleBtn toggleBtnC" onstyle="success" offstyle="danger" size="sm" onChange={(checked) => {
                                                        FrequencyChange(checked, 'rentalExpense');
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }


                                </div>
                            </>
                        );
                    })}
                </div>

                <div className='savecancel_btn'>
                    <Button color="success" onClick={handleSubmit}>{"Save"}</Button>
                    <Button color="primary" className="ml-1">{"Cancel"}</Button>
                </div>
            </div>
        </>
    )
}