import React, { useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import axios from "axios";
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';

export default function Spouse({ StepChange, steps, setPercentage, percentage, stepCount }) {

    const [validator, showValidationMessage] = useValidator();
    const [spouse_name, setSpouseName] = useState("");
    const [spouse_dob, setSpouseDob] = useState("");
    const [spouse_primary_income, setSpousePrimaryIncome] = useState("");
    const [spouseAmt, setSpouseAmt] = useState("");

    const previousButton = () => {
        StepChange(steps - 1);
        setPercentage(percentage - 100 / stepCount);
    }

    const handleChange = e => {
        const { value } = e.target;
        if (e.target.name === 'spouse_name') {
            setSpouseName(value);
        } else if (e.target.name === 'spouse_dob') {
            setSpouseDob(value);
        } else if (e.target.name === 'spouse_primary_income') {
            setSpousePrimaryIncome(formatAmount(value.replace(/,/g, "")));
            numtoWord(e);
        }
    };


    useEffect(() => {
        let profSumm = JSON.parse(sessionStorage.getItem('ProfSummary'));

        setPercentage(parseInt(profSumm.self_data.idPlanStatus) * 20);

        profSumm.prof_det.forEach((value, index) => {


            if (value.relation === "spouse") {
                setSpouseName(value.full_name);
                setSpouseDob(value.dob);
                sessionStorage.setItem('spouse_profile_details_id', value.profile_details_id);
            }

        });

        profSumm.incomes.forEach((value, index) => {

            if (value.sub_type === "IncomeSpouse") {
                setSpousePrimaryIncome(value.total_amount)
            }

        });
        // eslint-disable-next-line
    }, [])

    const [primaryFreq, setPrimaryF] = useState(12);

    const numtoWord = e => {

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');

        setSpouseAmt(ucwords(amountInWords));
    }

    const handleSubmitNext = (e) => {
        if (validator.allValid()) {

            //routeChange();



            const PostData = {
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": sessionStorage.getItem('spouse_profile_details_id'),
                "dob": spouse_dob,
                "name": spouse_name,
                "full_name":spouse_name,
                "relation": "spouse"
            };


            axios.post(`${apiUrl}profile-details/create`, PostData)
                .then(function (response) {

                    const IncomeData = {
                        "profile_id": sessionStorage.getItem('profile_id'),
                        "type": 1,
                        "consider": 1,
                        "frequency": 1,
                        "step": steps,
                        "income": {
                            "IncomeSpouse": {
                                "sub_type": "IncomeSpouse", "amount": spouse_primary_income, "frequency": primaryFreq
                            }
                        }

                    };

                    axios.post(`${apiUrl}profile-income`, IncomeData)
                        .then(function (response) {
                            SweetAlert.fire(
                                'Spouse Details',
                                'Spouse Details Added.',
                                'success'
                            ).then(function () {
                                
                                window.location = "/plan-status";
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                            SweetAlert.fire(
                                'Spouse Details',
                                'Failed. Something went wrong. Retry again',
                                'error'
                            )
                        });

                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Spouse Details',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });


        } else {
            // validator.showMessages();
            // rerender to show messages for the first time
            showValidationMessage(true);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="financiali-tilte"><Link to="/plan" className="goback"><i className="fas fa-angle-left" /></Link> Creating Your Financial Plan</div>

                    <Progress color="success" value={percentage} className="sm-progress-bar" />

                    <div className=" box-shadow Creating-Financial">
                        <h1> Your spouse details </h1>
                        <div className="input-box ">
                            <label>Name of your spouse </label>
                            <input type="text" className="form-control userInput" name={"spouse_name"} value={spouse_name} onChange={handleChange} />
                            {validator.message("spouse_name", spouse_name, "required", {
                                messages: {
                                    required: "Name is required"
                                }
                            })}
                            <div className="ruppy"> <i className="fas fa-user"></i></div>
                        </div>
                        <div className="input-box date">
                            <label> DOB </label>
                            <input className="form-control userInput mb-1" maxLength="4" pattern="[1-9][0-9]{3}" max={"9999-12-31"} type="date" name={"spouse_dob"} value={spouse_dob} onChange={handleChange} />
                            {validator.message("spouse_dob", spouse_dob, "required", {
                                messages: {
                                    required: "DOB is required"
                                }
                            })}
                            <div className="ruppy"> <i className="fas fa-calendar-alt"></i></div>
                        </div>

                        <div className="input-box ruppys yearly">
                            <label> Spouse income </label>
                            <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"spouse_primary_income"} value={spouse_primary_income} onChange={handleChange} onKeyPress={numtoWord} autoComplete='off' />
                            {validator.message("spouse_primary_income", spouse_primary_income, "required", {
                                messages: {
                                    required: "required"
                                }
                            })}
                            <div className="intext text-word"> {spouseAmt} </div>
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={primaryFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                setPrimaryF(checked === true ? 1 : 12)
                            }} />
                        </div>
                        <div className="bottombtn">

                            <button className="back" type="button" onClick={previousButton}><i className="fas fa-angle-left" /></button>
                            <button className="next" type="button" onClick={handleSubmitNext}>Next<i className="fas fa-angle-right" /></button>

                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
            </div>
        </>
    )
}
