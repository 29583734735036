//get-income-expense
import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import useValidator from '../../hooks/useValidator'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export default function EditIncome({ data }) {

    const [validator, showValidationMessage] = useValidator();
    const [income, setIncome] = useState([]);

    // eslint-disable-next-line
    const [IncomeSelf, setIncomeSelf] = useState('');
    // eslint-disable-next-line
    const [IncomeSpouse, setIncomeSpouse] = useState('');
    // eslint-disable-next-line
    const [RentalIncome, setRentalIncome] = useState('');
    // eslint-disable-next-line
    const [InvestmentIncome, setInvestmentIncome] = useState('');
    // eslint-disable-next-line
    const [Bonus, setBonus] = useState('');

    // eslint-disable-next-line
    const [IncomeSelfFreq, setIncomeSelfFreq] = useState('');
    // eslint-disable-next-line
    const [IncomeSpouseFreq, setIncomeSpouseFreq] = useState('');
    // eslint-disable-next-line
    const [RentalIncomeFreq, setRentalIncomeFreq] = useState('');
    // eslint-disable-next-line
    const [InvestmentIncomeFreq, setInvestmentIncomeFreq] = useState('');
    // eslint-disable-next-line
    const [BonusFreq, setBonusFreq] = useState('');


    // eslint-disable-next-line
    const [IncomeSelfId, setIncomeSelfId] = useState('');
    // eslint-disable-next-line
    const [IncomeSpouseId, setIncomeSpouseId] = useState('');
    // eslint-disable-next-line
    const [RentalIncomeId, setRentalIncomeId] = useState('');
    // eslint-disable-next-line
    const [InvestmentIncomeId, setInvestmentIncomeId] = useState('');
    // eslint-disable-next-line
    const [BonusId, setBonusId] = useState('');

    useEffect(() => {
        const Params = { profile_id: sessionStorage.getItem('profile_id'), type: 1 };
        axios.post(`${apiUrl}get-income-expense`, Params).then(
            (response, data) => {
                setIncome(response.data);
                console.log(response.data);

                response.data
                    .forEach(item => {
                        switch (item.type_name_sf) {

                            case 'IncomeSelf':
                                setIncomeSelfId(item.income_id);
                                setIncomeSelfFreq(item.frequency);
                                setIncomeSelf(item.amount);
                                break;

                            case 'IncomeSpouse':
                                setIncomeSpouseId(item.income_id);
                                setIncomeSpouseFreq(item.frequency);
                                setIncomeSpouse(item.amount);
                                break;

                            case 'RentalIncome':
                                setRentalIncomeId(item.income_id);
                                setRentalIncomeFreq(item.frequency);
                                setRentalIncome(item.amount);
                                break;

                            case 'InvestmentIncome':
                                setInvestmentIncomeId(item.income_id);
                                setInvestmentIncomeFreq(item.frequency);
                                setInvestmentIncome(item.amount);
                                break;

                            case 'Bonus':
                                setBonusId(item.income_id);
                                setBonusFreq(item.frequency);
                                setBonus(item.amount);
                                break;

                            default:
                                break;
                        }

                    })

            }
        );

    }, []);

    const handleChange = (e) => {
        let income_id = e.target.getAttribute("income_id");
        switch (e.target.name) {

            case 'IncomeSelf':
                setIncomeSelf(e.target.value);
                setIncomeSelfId(income_id);
                break;

            case 'IncomeSpouse':
                setIncomeSpouse(e.target.value);
                setIncomeSpouseId(income_id);
                break;

            case 'RentalIncome':
                setRentalIncome(e.target.value);
                setRentalIncomeId(income_id);
                break;

            case 'InvestmentIncome':
                setInvestmentIncome(e.target.value);
                setInvestmentIncomeId(income_id);
                break;

            case 'Bonus':
                setBonus(e.target.value);
                setBonusId(income_id);
                break;

            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const postData = {
                profile_id: sessionStorage.getItem('profile_id'),
                type: 1,
                data: [{
                    income_id: IncomeSelfId,
                    amount: IncomeSelf,
                    frequency: IncomeSelfFreq,
                    sub_type: "IncomeSelf"
                },
                {
                    income_id: IncomeSpouseId,
                    amount: IncomeSpouse,
                    frequency: IncomeSpouseFreq,
                    sub_type: "IncomeSpouse"
                },
                {
                    income_id: RentalIncomeId,
                    amount: RentalIncome,
                    frequency: RentalIncomeFreq,
                    sub_type: "RentalIncome"
                },
                {
                    income_id: InvestmentIncomeId,
                    amount: InvestmentIncome,
                    frequency: InvestmentIncomeFreq,
                    sub_type: "InvestmentIncome"
                },
                {
                    income_id: BonusId,
                    amount: Bonus,
                    frequency: BonusFreq,
                    sub_type: "Bonus"
                }]
            };

            axios.post(`${apiUrl}update-income-expense`, postData)
                .then(function (response) {

                    axios.post(`${apiUrl}goals/reset`, { profile_id: sessionStorage.getItem('profile_id') })
                        .then(function (response) {

                            axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                                (response, data) => {

                                    SweetAlert.fire({
                                        toast: true,
                                        icon: 'success',
                                        title: 'Successfully Updated Income.',
                                        animation: false,
                                        position: 'top-right',
                                        showConfirmButton: false,
                                        timer: 2000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                           toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                           toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                                        }
                                     }).then(function () {
                                        window.location.href = "/adjust"
                                     });

                                }
                            );
                        })

                    
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Income',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-bookmark">
                <div className="form-row">
                    {income.map((item, index) => {

                        return (
                            <>

                                <div className="mb-3 col-md-12 my-0" key={index}>
                                    {
                                        item.type_name_sf === 'IncomeSelf' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" width={70} height={40} checked={IncomeSelfFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                                        setIncomeSelfFreq(checked === true ? 1 : 12)
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'IncomeSpouse' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" width={70} height={40} checked={IncomeSpouseFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                                        setIncomeSpouseFreq(checked === true ? 1 : 12)
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'RentalIncome' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" width={70} height={40} checked={RentalIncomeFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                                        setRentalIncomeFreq(checked === true ? 1 : 12)
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'InvestmentIncome' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" width={70} height={40} checked={InvestmentIncomeFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                                        setInvestmentIncomeFreq(checked === true ? 1 : 12)
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        item.type_name_sf === 'Bonus' && (
                                            <>
                                                <div className="input-box ruppys yearly">
                                                    <label>{item.type_name} </label>
                                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={item.type_name_sf} defaultValue={item.amount} onChange={handleChange} income_id={item.income_id} />
                                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                                    { /* eslint-disable-next-line */}
                                                    <BootstrapSwitchButton id="btnself" width={70} height={40} checked={BonusFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                                        setBonusFreq(checked === true ? 1 : 12)
                                                    }} />
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                            </>
                        );
                    })}
                </div>
                <div className='savecancel_btn'>
                    <Button color="success" onClick={handleSubmit}>{"Save"}</Button>
                    <Button color="primary" className="ml-1">{"Cancel"}</Button>
                </div>
            </div>
        </>
    )
}
