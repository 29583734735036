import React, { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import { Button } from 'reactstrap'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import numWords from 'num-words';

export default function EditEducation({ data, refUrl, action }) {

    console.log(JSON.parse(data.goal_data));

    const [validator, showValidationMessage] = useValidator();
    // eslint-disable-next-line
    const [goalData, setGoalData] = useState(JSON.parse(data.goal_data))
    // eslint-disable-next-line
    console.log(goalData);
    const [inflation, setInflation] = useState(parseInt(data.goal_inflation));
    const [gamount, setGamount] = useState(formatAmount(goalData[0] * goalData[2]));
    const [pgamount, setPgamount] = useState(formatAmount(goalData[1] * goalData[3]));
    const [amt, setAmt] = useState("");
    const [pgamt, setPgAmt] = useState("");
    // eslint-disable-next-line
    const [gyear, setGyear] = useState(goalData[2]);
    // eslint-disable-next-line
    const [pgyear, setPgyear] = useState(goalData[3]);
    
    const [loan_perc, setLoan_perc] = useState(data.loan_perc);
    const [loan_tenure, setLoan_tenure] = useState(data.loan_tenure);
    const [loan_rate, setLoan_rate] = useState(data.loan_rate);

    const numtoWord = e => {
        const { value } = e.target;


        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');

        if (e.target.name === 'gamount') {
            setGamount(formatAmount(value.replace(/,/g, "")));
            setAmt(ucwords(amountInWords));
        } else if (e.target.name === 'inflation') {
            setInflation(e.target.value);
        }else if (e.target.name === 'gyear') {
            setGyear(e.target.value);
        }else if (e.target.name === 'pgyear') {
            setPgyear(e.target.value);
        }else if (e.target.name === 'loan_perc') {
            setLoan_perc(e.target.value);
        }else if (e.target.name === 'loan_tenure') {
            setLoan_tenure(e.target.value);
        }else if (e.target.name === 'loan_rate') {
            setLoan_rate(e.target.value);
        } else {
            setPgamount(formatAmount(value.replace(/,/g, "")));
            setPgAmt(ucwords(amountInWords));
        }

    }

    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const age = data.current_age;
            const required_after_cal = 17 - age;
            const required_after = required_after_cal < 1 ? 1 : required_after_cal;
            const required_till = required_after + parseInt(gyear) + parseInt(pgyear);

            const postData = {
                "goal_type": "Education",
                "goal_id": data.goal_id,
                "aspire": data.aspire,
                "profile_id": data.profile_id,
                "plan_for": {
                    "profile_details_id": data.profile_details_id,
                    "current_age": data.current_age,
                    "relation": data.relation
                },
                "yearly_expense": {
                    "0": gamount.replace(/,/g, ""),
                    "1": pgamount.replace(/,/g, "")
                },
                "no_years": {
                    "0": gyear,
                    "1": pgyear
                },
                "goal_params": {
                    "age": data.current_age,
                    "post_inflation": 7,
                    "post_return": 7,
                    "goal_inflation": inflation,
                    "required_after": required_after,
                    "required_till": required_till,
                    "loan_perc":loan_perc,
                    "loan_tenure": loan_tenure,
                    "loan_rate": loan_rate
                },
                "risk_profile": {
                    "available": false,
                    "pre_return": 8,
                    "risk_profile_id": data.profile_id,
                },
                "assumptions": {
                    "assumption_id": false,
                    "inflation": 7.5,
                    "income_grow": "10"
                },
                "total_abcd": [{
                    "amount": parseInt(gamount.replace(/,/g, "")) + parseInt(pgamount.replace(/,/g, "")),
                    "frequency": 12,
                    "no_of_times": 1,
                    "gap": 0
                }],
                "actual": [{
                    "actual_y": 0,
                    "frequency": 1,
                    "actual_ls": 1,
                    "grow": "0"

                }]
            };


            axios.post(`${apiUrl}goals/add`, postData)
                .then(function (response) {
                    axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                        (response, data) => {
                            SweetAlert.fire(
                                'Goals',
                                'Successfully Updated Goal.',
                                'success'
                            ).then(function () {
                                window.location.href = refUrl
                            })
                        }
                    );
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-row">
                <div className='row'>
                    <div className="col-md-4 input-popup">
                        <label> Total for Graduation </label>
                        <input type="text" className="form-control userInput" aria-label="Amount " name={"gamount"} value={gamount} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' readOnly={action === "view" ? true : false} />
                        {validator.message("gamount", gamount, "required", {
                            messages: {
                                required: "Amount for Graduation is required"
                            }
                        })}
                        <span className="text-word">{amt}</span><br />
                        <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                    </div>

                    <div className="col-md-4 input-popup">
                        <label> Total for Post Graduation </label>
                        <input type="text" className="form-control userInput" aria-label="Amount " name={"pgamount"} value={pgamount} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' readOnly={action === "view" ? true : false} />
                        {validator.message("pgamount", pgamount, "required", {
                            messages: {
                                required: "Amount for Post Graduation is required"
                            }
                        })}
                        <span className="text-word">{pgamt}</span><br />
                        <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>

                    </div>


                    <div className="col-md-4 input-popup">
                        <label> Assumed Inflation</label>
                        <input type="text" className="form-control" aria-label="Retire Age" name={"inflation"} defaultValue={inflation} onChange={numtoWord} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("inflation", inflation, "required", {
                            messages: {
                                required: "Inflation is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> %  </div>
                    </div>


                    <div className="col-md-4 input-popup">
                        <label> Graduation Years </label>
                        <input type="text" className="form-control" aria-label="Amount " name={"gyear"} value={gyear} onChange={numtoWord} autoComplete='off' readOnly={action === "view" ? true : false} />
                        {validator.message("gyear", gyear, "required", {
                            messages: {
                                required: "Graduation Years is required"
                            }
                        })}

                    </div>

                    <div className="col-md-4 input-popup">
                        <label> Post Graduation Years </label>
                        <input type="text" className="form-control" aria-label="Amount " name={"pgyear"} value={pgyear} onChange={numtoWord} autoComplete='off' readOnly={action === "view" ? true : false} />
                        {validator.message("pgyear", pgyear, "required", {
                            messages: {
                                required: "Post Graduation Years is required"
                            }
                        })}
                    </div>

                    <div className="col-md-4 input-popup">
                        <label> Loan % </label>
                        <input type="text" className="form-control" aria-label="Loan Percentafe" name={"loan_perc"} value={loan_perc} onChange={numtoWord} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("loan_perc", loan_perc, "required", {
                            messages: {
                                required: "Loan Percentage is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> %  </div>
                    </div>

                    <div className="col-md-4 input-popup">
                        <label> Loan Rate </label>
                        <input type="text" className="form-control" aria-label="Loan Percentafe" name={"loan_rate"} value={loan_rate} onChange={numtoWord} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("loan_rate", loan_rate, "required", {
                            messages: {
                                required: "Loan Rate is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> %  </div>
                    </div>

                    <div className="col-md-4 input-popup">
                        <label> Loan Tenure </label>
                        <input type="text" className="form-control" aria-label="Loan Percentafe" name={"loan_tenure"} value={loan_tenure} onChange={numtoWord} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("loan_tenure", loan_tenure, "required", {
                            messages: {
                                required: "Loan Tenure is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> %  </div>
                    </div>


                </div>
                {action !== "view" && (
                    <div className="form-group">
                        <div className="row">
                            <div className="col-12">
                                <Button color="success" className="mr-1" type="button" onClick={handleSubmit}>Save</Button>
                                <Button color="primary">{"Cancel"}</Button>
                            </div>
                        </div>
                    </div>
                )

                }

            </div>
        </>
    )
}