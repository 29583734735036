import React, { Fragment, useState } from 'react';
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import { Card, CardBody, Label, Input, Button, FormGroup, CardHeader } from 'reactstrap'
import axios from 'axios';
import { apiUrl } from '../constants/global';
import SweetAlert from 'sweetalert2'
import useValidator from '../hooks/useValidator'
import CKEditor from "react-ckeditor-component";
import { useLocation } from "react-router-dom";

export default function Compose() {

    const location = useLocation();

    // eslint-disable-next-line
    const [email, setEmail] = useState(location.state.email);
    // eslint-disable-next-line
    const [subject, setSubject] = useState(location.state.subject);
    // eslint-disable-next-line
    const [message, setMessage] = useState("");

    // eslint-disable-next-line
    const [path, setPath] = useState(location.state.path);
    // eslint-disable-next-line
    const [refUrl, setRefUrl] = useState(location.state.ref_url);

    // eslint-disable-next-line
    const [filename, setFilename] = useState(location.state.ref_doc);

    const [validator, showValidationMessage] = useValidator();

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'email':
                setEmail(e.target.value);
                break;

            case 'subject':
                setSubject(e.target.value);
                break;

            case 'message':
                setMessage(e.target.value);
                break;

            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        if (validator.allValid()) {
            SweetAlert.fire({
                title: "Email PDF",
                text: "Click on Yes to Sent Email",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#FF0000",
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
                closeOnConfirm: false,
                closeOnCancel: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.post(`${apiUrl}fpplanstatus/mail`, { 'profile_id': sessionStorage.getItem("profile_id"), 'to': 'anoj.tambe@finnovate.in', 'path': path, 'filename': filename, 'email': email, message:message, subject:subject }).then(
                            (response, data) => {
                                SweetAlert.fire(
                                    'EMail',
                                    'Successfully Sent.',
                                    'success'
                                ).then(function () {
                                    window.location.href = refUrl;
                                });
                            }
                        );
                    }
                });
        } else {
            showValidationMessage(true);
        }
    }

    return (
        <Fragment>
            <Header />
            <SideMenu />

            <div className="container">
                <div className="email-wrap bookmark-wrap">
                    <div className="row">
                        <h1 className='mt-4'> Compose Mail</h1>



                        <div className="col-xl-12 col-md-12 box-col-12">
                            <div className="email-right-aside bookmark-tabcontent contacts-tabs"></div>
                            <div className="email-body radius-left"></div>
                            <div className="pl-0">

                                <Card className="mb-0">
                                    <CardHeader className="d-flex">
                                        <h4 className="mb-0">{"New Message"}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div className="email-compose">

                                                <div className="email-wrapper">
                                                    <form className="theme-form">
                                                        <FormGroup>
                                                            <Label className="col-form-Label pt-0">{"To"}</Label>
                                                            <Input className="form-control" type="email" name='email' value={email} onChange={handleChange} />
                                                            {validator.message("email", email, "required|email", {
                                                                messages: {
                                                                    required: "Email is required"
                                                                }
                                                            })}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label >{"Subject"}</Label>
                                                            <Input className="form-control" type="text" name="subject" value={subject} onChange={handleChange} />
                                                            {validator.message("subject", subject, "required", {
                                                                messages: {
                                                                    required: "subject is required"
                                                                }
                                                            })}
                                                        </FormGroup>
                                                        <FormGroup className="mb-0">
                                                            <Label>{"Messages"}</Label>
                                                            <CKEditor
                                                                activeclassName="p10"
                                                                name="message"
                                                                value={message}
                                                                onChange={handleChange}
                                                            />


                                                        </FormGroup>

                                                        <FormGroup>
                                                            <label>File Name:</label><br />
                                                            {
                                                                filename !== "" ?
                                                                    (
                                                                        <>
                                                                            <i className="fa fa-paperclip" aria-hidden="true"></i>  <span> {filename}</span>
                                                                        </>
                                                                    ) : <Input className="form-control" type="file" name='filename'/>

                                                            }

                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Button color="success" className="btn-mail text-center mb-0 mt-0" onClick={handleSubmit}>
                                                                {"Send"}
                                                            </Button>
                                                        </FormGroup>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clear"></div>
            <Footer />
        </Fragment>
    );

}