import React, { Fragment, useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { apiUrl } from '../../constants/global';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import AddFamily from './../AddFamily';

export default function GoalProfile({ title, StepChange, steps, setPercentage, percentage, goalType }) {

    console.log(goalType);
    const [validator, showValidationMessage] = useValidator();
    const [profile_id, setprofile_id] = useState()
    const [dob, setDOB] = useState("");
    const [JobData, setJobData] = useState([])
    const [disable, setDisable] = React.useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const handleChange = (e) => {
        const { value } = e.target;
        if (e.target.name === 'dob') {
            setDOB(value);
        } else {

            if(value === '-1'){
                toggle();
                console.log('addd');
            }else{
                
                sessionStorage.setItem("g_dob", e.target[e.target.selectedIndex].getAttribute('g_dob'));
                setprofile_id(value);
                setDOB(e.target[e.target.selectedIndex].getAttribute('g_dob'));
                sessionStorage.setItem("g_relation", e.target[e.target.selectedIndex].getAttribute('g_relation'));
                sessionStorage.setItem("g_name", e.target[e.target.selectedIndex].getAttribute('g_name'))
            }

        }
        const postData = {
            'plan_for': value,
            'goal_type': goalType
        }
        axios.post(`${apiUrl}goals/check-plan-for-goal`, postData)
            .then(function (response) {
                if (response.data.count > 0) {
                    SweetAlert.fire({
                        toast: true,
                        icon: 'error',
                        title: 'You have already created goal for this profile.',
                        animation: false,
                        position: 'top-middle',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    });
                    setDisable(true);
                } else {
                    setDisable(false);
                }
                setDisable(false);
            });
    };

    const handleSubmit = () => {
        if (validator.allValid()) {
            sessionStorage.setItem('g_profile_details_id', profile_id);
            sessionStorage.setItem("g_dob", dob);
            StepChange(steps + 1);
            setPercentage(percentage + 25);
        } else {
            showValidationMessage(true);
        }
    };

    useEffect(() => {
        //const profile = JSON.parse(sessionStorage.getItem('ProfSummary'));
        
        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile-details/getfamily/${profile_id}`).then(
            (response, data) => {
                //console.log(response.data);
                setJobData(response.data);
            }
         );

    }, [])

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <h1> This Goal is for? </h1>
                        <div className="input-box selectdiv mb-4">
                            <label> Select Profile </label>
                            <select className="form-control digits" name={"profile_id"} value={profile_id} onChange={handleChange} autoComplete="off">
                                <option value="">select</option>
                                {JobData.map((data, i) => {
                                    return (
                                        <option value={data.profile_details_id} key={i} g_dob={data.dob} g_relation={data.relation} g_name={data.full_name}>{data.full_name}</option>
                                    )
                                })}
                                <option value="-1">Add</option>
                            </select>
                            {validator.message("profile_id", profile_id, "required", {
                                messages: {
                                    required: "Profile is required"
                                }
                            })}
                        </div>
                        <div className="input-box date">
                            <label> Date of Birth </label>
                            <input className="form-control mb-1" maxLength="4" pattern="[1-9][0-9]{3}" max={"9999-12-31"} type="date" name={"dob"} value={dob} onChange={handleChange} />
                            {validator.message("dob", dob, "required", {
                                messages: {
                                    required: "DOB is required"
                                }
                            })}
                            <span></span>
                        </div>
                        <div className="bottombtn">
                            
                            <button className="next" type='button' onClick={handleSubmit} disabled={disable}> Next<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modal} toggle={toggle}>
                                            <ModalHeader toggle={toggle}>{"AddContacts"}</ModalHeader>
                                            <ModalBody>
                                                <AddFamily />
                                            </ModalBody>
                                        </Modal>
            </div>
        </Fragment>
    )
}