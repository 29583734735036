import React, { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import { Button } from 'reactstrap'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';

export default function EditHealth({ data }) {

    console.log(data);
    const [validator, showValidationMessage] = useValidator();
    // eslint-disable-next-line
    const [income, setIncome] = useState(formatAmount(data.income));
    // eslint-disable-next-line
    const [dependents, setDependents] = useState(data.gi_dependents);

    const [checked, setChecked] = useState(data.auto_reset === "1" ? '' : 'checked');
    const [auto_reset, setAuto_reset] = useState();

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'income':
                setIncome(formatAmount(e.target.value.replace(/,/g, "")));
                break;

            case 'dependents':
                setDependents(e.target.value);
                break;

            case 'auto_reset':
                setChecked(checked === 'checked' ? '' : 'checked');
                setAuto_reset(checked === 'checked' ? '1' : '0');
                break;

            default:
                break;
        }
    };

    const numtoWord = () => {

    }

    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const age = 44;
            const postData = {
                "goal_type": "Health Insurance",
                "goal_id": data.goal_id,
                "aspire": data.aspire,
                "profile_id": data.profile_id,
                "profile_details_id": data.profile_details_id,
                "auto_reset": auto_reset,
                "income_grow": 7,
                "inflation": 6,
                "income": income.replace(/,/g, ""),
                "gi_dependents": dependents,
                "dob": age,
                "api_type": 'insert'
            };

            axios.post(`${apiUrl}goals/addhealth`, postData)
                .then(function (response) {
                    SweetAlert.fire(
                        'Goals',
                        'Successfully Updated Goal.',
                        'success'
                    ).then(function () {
                        window.location.href = "/risk-goals"
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-row">
                <div className='row'>
                    <div className="col-md-6 input-popup">
                        <label> Yearly Income as of Today </label>
                        <input type="text" className="form-control userInput" aria-label="Expense" name={"income"} value={income} onChange={handleChange} onKeyPress={numtoWord} autoComplete="off" />
                        {validator.message("income", income, "required", {
                            messages: {
                                required: "Income is required"
                            }
                        })}
                        <div className="ruppy-popup"> <i className="fas fa-rupee-sign" /></div>
                    </div>

                    <div className="col-md-6 input-popup">
                        <label> No of Dependents </label>
                        <input type="text" className="form-control" aria-label="No of Dependents" name={"dependents"} value={dependents} onChange={handleChange} autoComplete="off" />
                        {validator.message("dependents", dependents, "required", {
                            messages: {
                                required: "No of Dependents is required"
                            }
                        })}
                        
                    </div>



                    <div className="col-md-6 input-popup">


                        <label className="text-muted" htmlFor="checkbox1">Click to Disable Auto Reset </label>
                        <input id="checkbox1" type="checkbox" name={"auto_reset"} checked={checked} value={auto_reset} onChange={handleChange} className='autoReset' />

                    </div>


                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-12">
                            <Button color="success" className="mr-1" type="button" onClick={handleSubmit}>Save</Button>
                            <Button color="primary">{"Cancel"}</Button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
