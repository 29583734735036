import React, { Fragment, useState } from 'react'
import useValidator from '../../hooks/useValidator'
import { Input, Col } from 'reactstrap'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useEffect } from 'react';

export default function HealthDependant({title, StepChange, steps, setPercentage, percentage }) {

    const [start_in, setUserval] = useState(2);
    const [value, setvalue] = useState();
    const [validator, showValidationMessage] = useValidator();

    const onSliderChange = value => {
        setvalue(value);
        setUserval(value);
    };

    const onAfterChange = value => {
        setUserval(null);
    };

    const handleInputChange = (event) => {
        setvalue(event.target.value);
        setUserval(event.target.value);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleBlur = () => {
        if (start_in < 0) {
            setUserval(0);
        } else if (start_in > 100) {
            setUserval(100);
        }
    };

    const handleSubmit = (event) => {
        if (validator.allValid()) {

            const age = 45;

            const aspire = "Health Insurance";
            const postData = {
                "goal_type": "Health Insurance",
                "aspire": aspire,
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
                "income_grow":7,
                "inflation":6,
                "income":sessionStorage.getItem('income').replace(/,/g, ""),
                "gi_dependents":start_in,
                "dob":age,
                "api_type":'insert'
            };


            axios.post(`${apiUrl}goals/addhealth`, postData)
                .then(function (response) {
                    SweetAlert.fire(
                        'Goals',
                        'Added New Goal.',
                        'success'
                    ).then(function () {
                        window.location.href = "/plan"
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    };

    useEffect(() => {
        setvalue(2);
    }, []);

    return(
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <h1> Health Insurance for {"profile_name"}</h1>
                        <div className="input-box ruppys yearly">
                            <div md="10">
                                <label>{"No Of Dependent"}</label></div>
                            <div md="2">
                                <Input
                                    value={start_in}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    style={{ marginTop: '50px' }}
                                />
                            </div>
                            <Col md="12">
                                <Slider
                                    onChange={onSliderChange}
                                    onAfterChange={onAfterChange}
                                    min={0}
                                    max={5}
                                    defaultValue={value}
                                    value={value}
                                />
                            </Col>
                        </div>
                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Save<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
