import React, { useState } from 'react';
import GoalProfile from './../GoalSteps/GoalProfile';
import EducationG from './../GoalSteps/EducationG'
import EducationPg from './../GoalSteps/EducationPg'

export default function AddEducation({title, profileName, gStepChange, gsteps}) {
  const [steps, setSteps] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [stepCount, setStepCount] = useState(0);

  const StepChange = (step) => {
    setSteps(step);
  }

  return (
    <>
      {steps === 1 && (<GoalProfile title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} setStepCount={setStepCount} goalType={"Retirement"}/>)}

      {steps === 2 && (<EducationG title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}

      {steps === 3 && (<EducationPg title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}
    </>
  );
}
