import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Input, Col } from 'reactstrap'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { apiUrl } from '../../constants/global';
import SweetAlert from 'sweetalert2'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'
import { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import AddFamily from './../AddFamily';
import { calculateAge } from '../../constants/common'

export default function AddMarriage({ title, StepChange, steps, gpsteps, gStepChange, progress }) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [start_in, setUserval] = useState(26);
    const [value, setvalue] = useState();
    const [amt, setAmt] = useState("");
    const [expense, setExpense] = useState("");
    const [kidsList, setKidsList] = useState([]);
    const [profDetId, setProfDetId] = useState("");
    const [disable, setDisable] = React.useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [age, setAge] = useState("");
    const [relation, setRelation] = useState("");

    const skipGoal = () => {

        let goalStep = sessionStorage.getItem('goalStep');
        let increment = 1;
        if (goalStep >= 3 && sessionStorage.getItem('kids') !== '1') {
            increment = 1;
        }
        sessionStorage.setItem('goalStep', parseInt(goalStep) + increment);
        gStepChange(parseInt(goalStep) + increment);
        window.location.href = '/plan';
    }

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const onSliderChange = value => {
        setvalue(value);
        setUserval(value);
    };

    const onAfterChange = value => {
        setUserval(null);
    };

    const handleInputChange = (event) => {
        setvalue(event.target.value);
        setUserval(event.target.value);
    };

    const numtoWord = e => {
        const { value } = e.target;
        setExpense(formatAmount(value.replace(/,/g, "")));

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');
        setAmt(ucwords(amountInWords));
    }

    const handleBlur = () => {
        if (start_in < 0) {
            setUserval(0);
        } else if (start_in > 100) {
            setUserval(100);
        }
    };

    const getkids = () => {
        const postData = {
            'profile_id': sessionStorage.getItem('profile_id'),
            'relation': 'kids'
        }
        axios.post(`${apiUrl}profile-details/getfamilybyrelation`, postData).then(function (response) {

            setKidsList(response.data);
            if (response.data.length > 0) {
                setDisable(false);
            } else {
                setDisable(true);
            }
        })
    }

    useEffect(() => {
        getkids();
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;

        setProfDetId(value);
        setAge(calculateAge(e.target[e.target.selectedIndex].getAttribute('g_dob')));
        setRelation(e.target[e.target.selectedIndex].getAttribute('g_relation'));
        const postData = {
            'plan_for': value,
            'goal_type': 'Marriage'
        }
        axios.post(`${apiUrl}goals/check-plan-for-goal`, postData)
            .then(function (response) {
                console.log(response);
                if (response.data.count > 0) {
                    SweetAlert.fire({
                        toast: true,
                        icon: 'error',
                        title: 'You have already created goal for this profile.',
                        animation: false,
                        position: 'top-middle',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    });
                    setDisable(true);
                } else {
                    setDisable(false);
                }

            });
    };

    const onSubmit = data => {

        const life_expectancy = 85;
        const postData = {
            "goal_type": "Marriage",
            "goal_name": "Marriage",
            "aspire": "Marriage",
            "profile_id": sessionStorage.getItem('profile_id'),
            "plan_for": {
                "profile_details_id": profDetId,
                "current_age": age,
                "relation": relation
            },
            "goal_params": {
                "age": age,
                "retire_age": value,
                "life_expectancy": life_expectancy,
                "post_inflation": 7,
                "post_return": 7,
                "goal_inflation": 7,
                "required_after": value,
                "required_till": value
            },
            "risk_profile": {
                "available": false,
                "pre_return": 8,
                "risk_profile_id": sessionStorage.getItem('profile_id')
            },
            "assumptions": {
                "assumption_id": false,
                "inflation": 7.5,
                "income_grow": "10"
            },
            "total_abcd": [{
                "amount": data.expense.replace(/,/g, ""),
                "frequency": 12,
                "no_of_times": 1,
                "gap": 0
            }],
            "actual": [{
                "actual_y": 0,
                "frequency": 1,
                "actual_ls": 1,
                "grow": "0"

            }]
        };

        axios.post(`${apiUrl}goals/add`, postData)
            .then(function (response) {
                SweetAlert.fire(
                    'Goals',
                    'Added New Goal.',
                    'success'
                ).then(function () {
                    skipGoal();
                })
            })
            .catch(function (error) {
                console.log(error);
                SweetAlert.fire(
                    'Goals',
                    'Failed.',
                    'error'
                )
            });

    };

    useEffect(() => {
        setvalue(26);
    }, []);

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/plan-status" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={progress} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <form onSubmit={handleSubmit(onSubmit)}>

                            {
                                kidsList.length > 0 ? (
                                    <>
                                        <div className="input-box selectdiv mb-4">
                                            <label> Select Kid </label>
                                            <select className="form-control digits" name={"profDetId"} onChange={handleChange} autoComplete="off">
                                                <option value="">select</option>
                                                {kidsList.map((data, i) => {
                                                    return (
                                                        <option value={data.profile_details_id} g_relation={data.relation} key={i} g_dob={data.dob}>{data.full_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <button className="btn btn-outline-primary btn-lg" type="button" onClick={toggle}>New Contacts</button>
                                        <Modal isOpen={modal} toggle={toggle}>
                                            <ModalHeader toggle={toggle}>{"AddContacts"}</ModalHeader>
                                            <ModalBody>
                                                <AddFamily />
                                            </ModalBody>
                                        </Modal>
                                    </>
                                )
                            }
                            <div className="input-box ruppys yearly">
                                <label> Current Amount for Marriage </label>
                                <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name="expense" value={expense} {...register("expense", { required: true })} onChange={numtoWord} onKeyPress={numtoWord} autoComplete='off' />
                                <span className="text-word">{amt}</span><br />
                                {errors.expense && <span className='srv-validation-message'>Amount is required</span>}
                                <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            </div>

                            <div className="input-box ruppys yearly">

                                <label>{"Age at Marriage"}</label>
                                <div md="2">
                                    <Input
                                        value={start_in}
                                        onChange={handleInputChange}
                                        onBlur={handleBlur}
                                        type="text"
                                    />
                                </div>
                                <Col md="12">

                                    <Slider
                                        onChange={onSliderChange}
                                        onAfterChange={onAfterChange}
                                        min={25}
                                        max={35}
                                        defaultValue={value}
                                        value={value}
                                    />
                                </Col>
                            </div>

                            <div className="bottombtn mt-7">
                                <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                <button className="skipBtn" onClick={skipGoal}> SKIP </button>
                                <button className="next" type='submit' disabled={disable}> Next<i className="fas fa-angle-right" /> </button>
                            </div>
                        </form>

                    </div>



                </div>

            </div>
        </Fragment>
    )
}
