import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import { Accordion, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Chart from 'react-apexcharts'
import axios from 'axios';
import { apiUrl, BaseUrl } from '../constants/global'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
//import EditAssumptions from './Goals/EditAssumptions'
import EditIncome from './popups/EditIncome';
import EditExpense from './popups/EditExpense';
import formatAmount from 'indian-currency-formatter';
import EditProfileAssumption from './EditProfileAssumption';
import SetRoiOption from './SetRoiOption';
import SweetAlert from 'sweetalert2'

import EditRetirement from './Goals/EditRetirement'
import EditWealth from './Goals/EditWealth'
import EditEducation from './Goals/EditEducation'
import EditMarriage from './Goals/EditMarriage'
import EditCashflowOpt from './Goals/EditCashflowOpt'
import EditVacation from './Goals/EditVacation';

import { Col, Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Table } from 'reactstrap';
import { calculateAge } from '../constants/common'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import FpPlanStatus from './FpPlanStatus';

export default function Adjust() {


  /*SweetAlert.fire({
    title: '<strong className="txt-orange">Yearly,
     Amount too high</strong>',
    type: 'question',
    html: '<div className="customRangeSlider-wrapp "><div className="invest-title"><p>Increase Your Yearly Investment By</p></div><p className="output-range-slider txt-orange"><span id="income_grow_lbl">10</span></p><input type="range" min="0" max="20" step="5" className="range-slider" name="income_grow" id="income_grow" value="10"></div><br/><div className="invest-title"><p>Increase Your Retirement Age By</p></div><p className="output-range-slider txt-orange"><span id="retire_age_lbl">50</span></p><input type="range" min="50" max="75" step="1" className="range-slider" name="retire_age" id="retire_age" value="50">  </div>',
    showCancelButton: true,
    closeOnCancel: true,
    confirmButtonText: 'UPDATE',
    cancelButtonText: 'CLOSE',
    inputValue: 5
  });*/


  /*const cashflowOpt = () => {
    
    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          '1': 'Suggested Fund',
          '2': 'This Year Saving',
        })
      }, 1000)
    })

    SweetAlert.fire({
      title: 'Select CashFlow Option',
      input: 'radio',
      inputOptions: inputOptions,
      width: '800px',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose something!'
        }
      }
    }).then((cashflow_opt) => {

      axios.post(`${apiUrl}profile/cashflow-option`, { profile_id: sessionStorage.getItem('profile_id'), 'cashflow_opt': cashflow_opt.value }).then(
        function (response) {
          SweetAlert.fire(
            'Summary',
            'Cashflow Option Updated Successfully.',
            'success'
          ).then(function () {
            window.location.href = "/adjust";
          });
        }
      )

    });


  }*/

  const DeleteGoal = (id) => {


    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this goal?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF0000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.post(`${apiUrl}goals/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'goal_id': id }).then(
            function (response) {
              SweetAlert.fire(
                'Goals',
                'Successfully Deleted.',
                'success'
              ).then(function () {
                window.location.href = "/adjust";
              });
            }
          )
        }
      });
  }

  // eslint-disable-next-line
  const [chartData, setChartData] = useState({
    series: [{
      name: 'series1',
      data: []
    }],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#1761c5'],
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'string',
        categories: []
      }
    }
  })







  const [goals, setGoals] = useState([]);
  const [assumption, setAssumption] = useState([]);
  const [investFor, setinvestFor] = useState('');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [goalType, setGoalType] = useState("");
  const [goalData, setgoalData] = useState([]);
  const [roi, setRoi] = useState();
  //const [assumptionData, setassumptionData] = useState([]);

  const [incomeSum, setIncomeSum] = useState('');
  const [expenseSum, setExpenseSum] = useState('');
  const [totalSaving, setTotalSaving] = useState('');

  const [linkedSum, setLinkedSum] = useState('');
  const [cashSum, setCashSum] = useState('');

  const [fund, setFund] = useState("");
  //eslint-disable-next-line
  const [shortLumpsumm, setShortLumpsum] = useState();
  const [actual, setActual] = useState('');
  const [shortYearly, setShortYearly] = useState("");
  const [ongoingSaving, setOngoingSaving] = useState("");

  const [profileAssumption, setProfileAssumption] = useState([]);
  const [riskDescription, setRiskDescription] = useState("");
  //const [categoryModal, setCategoryModal] = useState(false)
  //const categoryToggle = () => setCategoryModal(!categoryModal);


  const [optionModal, setOptionModal] = useState(false)
  const optionToggle = () => setOptionModal(!optionModal);


  const [incomeModel, setIncomeModel] = useState(false)
  const incomeToggle = () => setIncomeModel(!incomeModel);

  const [expenseModel, setExpenseModel] = useState(false)
  const expenseToggle = () => setExpenseModel(!expenseModel);

  const [profileAssumptionModal, setProfileAssumptionModal] = useState(false)
  const profileAssumptionToggle = () => setProfileAssumptionModal(!profileAssumptionModal);

  const [roiModal, setRoiOption] = useState(false)
  const roiToggle = () => setRoiOption(!roiModal);

  const [target, setTarget] = useState();

  const [cashflow, setCashflow] = useState([]);
  const [activeTab2, setActiveTab2] = useState('1');

  const [cashflowOption, setCashflowOption] = useState("");
  const [customAmount, setCustomAmount] = useState("");

  const [savingTarget, setSavingTarget] = useState("");
  const [networth, setNetworth] = useState('');
  const [members, setMembers] = useState([]);

  // eslint-disable-next-line
  const [retireAge, setRetireAge] = useState();
  // eslint-disable-next-line
  const [increaseBy, setIncreaseBy] = useState();
  const onSliderChange = value => {
    setRetireAge(value);
  };



  const onAfterChange = value => {
    setIncreaseBy(value);
  };

  const UpdateAssumption = () => {

    const profile_id = sessionStorage.getItem('profile_id');

    const postData = { profile_id: profile_id, assumption: { retire_age: retireAge, income_grow: increaseBy } };

    axios.post(`${apiUrl}profile-assumptions/update_assumption`, postData)
      .then(function (response) {

        const gpostData = { profile_id: profile_id };
        axios.post(`${apiUrl}goals/reset`, gpostData)
          .then(function (response) {
            axios.post(`${apiUrl}profile/summary-update`, { profile_id: profile_id }).then(
              (response, data) => {
                SweetAlert.fire(
                  'Assumption',
                  'Successfully Updated.',
                  'success'
                ).then(function () {
                  window.location.href = "/adjust";
                });
              }
            );
          })
      })
  }

  const [editTitle, setEditTitle] = useState("");
  const EditGoal = (goal_type, data) => {

    if (goal_type === "fpPlanStatus") {
      if (cashflowOption !== "3") {
        SweetAlert.fire(
          'FP Report',
          'Report Can\'t be generated. Set Target Amount First.',
          'warning'
        )
      } else {
        setgoalData(data);
        setGoalType(goal_type);
        setModal(!modal);
        if (goal_type === "cashflowOpt") {
          setEditTitle('Select CashFlow Option');
        } else if (goal_type === "fpPlanStatus") {
          setEditTitle('Update Plan Status');
        } else {
          setEditTitle('Edit ' + data.aspire + ' for ' + data.full_name);
        }
      }
    } else {
      setgoalData(data);
      setGoalType(goal_type);
      setModal(!modal);
      if (goal_type === "cashflowOpt") {
        setEditTitle('Select CashFlow Option');
      } else if (goal_type === "fpPlanStatus") {
        setEditTitle('Update Plan Status');
      } else {
        setEditTitle('Edit ' + data.aspire + ' for ' + data.full_name);
      }
    }




  }


  /*const EditAssumption = (data) => {
    setassumptionData(data);
    setCategoryModal(!categoryModal);
  }*/

  const selectOpt = (data) => {
    setOptionModal(!optionModal);
  }

  const editprofileassumption = () => {
    sessionStorage.setItem('page_referer', '/adjust');
    setProfileAssumptionModal(!profileAssumptionModal);
  }

  const roiOption = () => {
    setRoiOption(!roiModal);
  }

  const IncomeModel = () => {
    setIncomeModel(!incomeModel);
  }

  const ExpenseModel = () => {
    setExpenseModel(!expenseModel);
  }

  const report_history = () => {
    window.location.href = "/report-history";
  }

  function fpreport() {

    if (cashflowOption !== "3") {
      SweetAlert.fire(
        'FP Report',
        'Report Can\'t be generated. Set Target Amount First',
        'warning'
      )
    } else {
      const profile_id = sessionStorage.getItem('profile_id');

      window.open(`${BaseUrl}fp-report/${profile_id}`, "_blank");
    }

  }

  const updateSummary = () => {
    const profile_id = sessionStorage.getItem('profile_id');

    axios.post(`${apiUrl}profile/summary-update`, { profile_id: profile_id }).then(
      (response, data) => {
        SweetAlert.fire(
          'Goal Summary',
          'Successfully Updated.',
          'success'
        ).then(function () {
          window.location.href = "/adjust";
        });
      }
    );
  }

  useEffect(() => {

    const profile_id = sessionStorage.getItem('profile_id');
    axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
      (response, data) => {
        if (response.data.status === 100) {
          setGoals(response.data.goals);
          setAssumption(response.data.assumption);
          setinvestFor(response.data.assumption.retire_age - response.data.self_data.current_age);
          setIncomeSum(response.data.incomeSum.incomeSum);
          setExpenseSum(response.data.outflow);
          setTotalSaving(parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
          setLinkedSum(response.data.InvestmentSummary.c_amount);
          setCashSum(response.data.InvestmentSummary.cash_amount);
          setOngoingSaving(response.data.InvestmentSummary.y_amount);
          setProfileAssumption(response.data.profAssumptionData);

          setRetireAge(parseInt(response.data.assumption.retire_age));
          setIncreaseBy(parseInt(response.data.assumption.income_grow));

          setTarget(response.data.target_amount);
          setCustomAmount(response.data.profgoalSummary.custom_amount);
          setCashflowOption(response.data.profgoalSummary.cashflow_opt);

          if (response.data.profgoalSummary.cashflow_opt === "1") {
            setSavingTarget((response.data.profgoalSummary.suggested_fund - (response.data.InvestmentSummary.c_amount - response.data.InvestmentSummary.cash_amount) * response.data.profgoalSummary.shortfall_lumpsum) / response.data.profgoalSummary.shortfall_yearly_with_increase);
          } else if (response.data.profgoalSummary.cashflow_opt === "2") {
            setSavingTarget(parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
          } else if (response.data.profgoalSummary.cashflow_opt === "3") {
            setSavingTarget(response.data.profgoalSummary.custom_amount);
          }


          setRiskDescription(response.data.risk_profile.risk_description);
          setNetworth(response.data.networth);
          setRoi(parseFloat(response.data.profgoalSummary.pre_return * 100).toFixed(1));


          setFund(response.data.profgoalSummary.suggested_fund);
          setShortLumpsum(response.data.profgoalSummary.shortfall_lumpsum);
          setShortYearly(response.data.profgoalSummary.shortfall_yearly_with_increase);
          setActual(response.data.InvestmentSummary.c_amount !== null ? (parseInt(response.data.InvestmentSummary.c_amount) + parseInt(response.data.InvestmentSummary.cash_amount)) * response.data.profgoalSummary.shortfall_lumpsum : 0);

        }
      });

    axios.get(`${apiUrl}profile-details/getfamily/${profile_id}`).then(
      (response, data) => {
        setMembers(response.data);
      });

    axios.get(`${apiUrl}cashflow-summary/${profile_id}`).then(
      (response, data) => {

        if (response.data.status === 101) {
          setCashflow(0);
          setChartData(0);
        } else {
          setCashflow(response.data.cashflow);
          setChartData({
            series: [{
              name: 'series1',
              data: response.data.nettAmt
            }],
            options: {
              chart: {
                height: 500,
                type: 'area',
                toolbar: {
                  show: false
                }
              },
              dataLabels: {
                enabled: false
              },
              colors: ['#1761c5'],
              stroke: {
                curve: 'smooth'
              },
              xaxis: {
                type: 'string',
                categories: response.data.srno
              },
              annotations: {
                yaxis: [],
                xaxis: [],
                points: response.data.annotation
              },
            }
          });
        }
      });


  }, []);

  return (
    <React.Fragment>
      <Header />
      <SideMenu />
      <div className="container pb-70">
        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>{editTitle}</ModalHeader>
          <ModalBody>
            {
              goalType === 'Retirement' && (<EditRetirement data={goalData} refUrl="/adjust" />)
            }

            {
              goalType === 'Wealth' && (<EditWealth data={goalData} refUrl="/adjust" />)
            }

            {
              goalType === 'Education' && (<EditEducation data={goalData} refUrl="/adjust" />)
            }

            {
              goalType === 'Vacation' && (<EditVacation data={goalData} refUrl="/adjust" />)
            }

            {
              goalType === 'Marriage' && (<EditMarriage data={goalData} refUrl="/adjust" />)
            }

            {
              goalType === 'cashflowOpt' && (<EditCashflowOpt fund={(fund - actual) / shortYearly} totalSaving={totalSaving} cashflowOption={cashflowOption} customAmount={customAmount} refUrl={"/adjust"} />)
            }

            {
              goalType === "fpPlanStatus" && (<FpPlanStatus />)
            }
          </ModalBody>
        </Modal>
        <div className="row m-t-40">




          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div className="adjestyourplan_l">
              <div className="adjestyourplan_title f-20 f-w-700">
                <Link to="/" className="leftarrow m-r-10" data-bs-original-title title> <i className="mdi mdi-chevron-down" /> </Link> Review/Adjust your plan
              </div>


              <Accordion defaultActiveKey="0" className="adjestyourplan_box m-t-30">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="adjestyourplan_box_title lightblue_bg">
                    <div className="f-20 f-w-700"><span className="color0a6 m-r-20">1</span> My Family </div>
                  </Accordion.Header>
                  <Accordion.Body className="adjestyourplan_box_content">

                    <Table borderless>
                      <thead>
                        <tr>
                          <th className="f-22">{"Name"}</th>
                          <th>{"DOB"}</th>
                          <th>{"Relation"}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {// eslint-disable-next-line
                          members.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className="d-inline-block align-middle"><img className="img-40 m-r-15 rounded-circle align-top" src="assets/images/Education.svg" alt="" />
                                      <div className="status-circle bg-primary"></div>
                                      <div className="d-inline-block"><span>{item.full_name}</span>
                                        <p className="font-roboto">{"Age:" + calculateAge(item.dob)}</p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.dob}</td>
                                  <td>{item.relation}</td>
                                </tr>
                              </>
                            )
                          })}



                      </tbody>
                    </Table>

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>




              <Accordion defaultActiveKey="0" className="adjestyourplan_box m-t-30">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="adjestyourplan_box_title lightblue_bg">
                    <div className="f-20 f-w-700"><span className="color0a6 m-r-20">2</span> Savings Rate </div>
                  </Accordion.Header>
                  <Accordion.Body className="adjestyourplan_box_content">
                    <div className="adjestyourplan_box_inner">
                      <div className="f-16 f-w-600 color545">This Years Savings</div>
                      <div className="f-16 f-w-600 color06B m-t-5">Rs {totalSaving === null ? 'NA' : formatAmount(totalSaving)}</div>
                      <div className="savingrate_chart m-t-40">
                        <div className="savingrate_chart_l p-r-10">
                          <div className="f-16 f-w-600 color545">This Years Income</div>
                          <div className="f-16 f-w-600 color176 m-t-5">Rs {incomeSum === null ? 'NA' : formatAmount(incomeSum)}</div>
                          <button className="bluebtn m-t-40 f-18" onClick={() => IncomeModel()}><i className="fas fa-arrow-alt-circle-down m-r-10"></i> Inflows</button>
                          <Modal isOpen={incomeModel} toggle={incomeToggle}>
                            <ModalHeader toggle={incomeToggle}>{"Edit Income"}</ModalHeader>
                            <ModalBody>
                              <EditIncome />
                            </ModalBody>
                          </Modal>

                        </div>
                        <div className="savingrate_chart_r p-l-10">
                          <div className="f-16 f-w-600 color545">This Years Outflow</div>
                          <div className="f-16 f-w-600 colorff1 m-t-5">Rs {expenseSum === null ? 'NA' : formatAmount(expenseSum)}</div>
                          <button className="redbtn m-t-40 f-18" onClick={() => ExpenseModel()}><i className="fas fa-arrow-alt-circle-up m-r-10"></i> Outflows</button>
                          <Modal isOpen={expenseModel} toggle={expenseToggle}>
                            <ModalHeader toggle={expenseToggle}>{"Edit Income"}</ModalHeader>
                            <ModalBody>
                              <EditExpense />
                            </ModalBody>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>



              <Accordion defaultActiveKey="0" className="adjestyourplan_box m-t-30">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="adjestyourplan_box_title lightblue_bg">
                    <div className="f-20 f-w-700"><span className="color0a6 m-r-20">3</span> Investment Horizon </div>
                  </Accordion.Header>
                  <Accordion.Body className="adjestyourplan_box_content">
                    <div className="adjestyourplan_box_inner">
                      <div className="savingrate_chart m-t-40">
                        <div className="savingrate_chart_l p-r-10">
                          <div className="f-16 f-w-600 color545">Invest For</div>
                          <div className="f-16 f-w-600 color176 m-t-5">{investFor} years</div>
                          <button className="bluebtn m-t-40 f-18" onClick={() => selectOpt()}><i className="fas fa-edit m-r-10"></i> View Option</button>
                          <Modal isOpen={optionModal} toggle={optionToggle}>
                            <ModalHeader toggle={optionToggle}>{"Yearly Amount too high"}</ModalHeader>
                            <ModalBody>


                              <div className='row pt-3 m-3'>
                                <div className="invest-title"><p>Increase Your Yearly Investment By</p></div>
                                <div className='col-md-10'>

                                  <Slider defaultValue={increaseBy} min={0} max={20} step={5} onChange={onAfterChange} tabIndex={5} />
                                </div>
                                <div className='col-md-2' style={{ 'margin-top': '-6px' }}>
                                  <span className='popupSlider'>{increaseBy}</span>
                                </div>
                              </div>
                              <br />
                              <div className='row pt-5 pb-5 m-3'>
                                <div className="invest-title"><p>Increase Your Retirement Age By</p></div>
                                <div className='col-md-10'>

                                  <Slider defaultValue={retireAge} min={50} max={75} step={1} onChange={onSliderChange} tabIndex={5} />
                                </div>
                                <div className='col-md-2' style={{ 'margin-top': '-6px' }}>
                                  <span className='popupSlider'>{retireAge}</span>
                                </div>
                              </div>


                              <button type="button" className="btn btn-success" onClick={() => UpdateAssumption()}>Save</button>
                              <button className="ml-1 btn btn-primary">Cancel</button>
                            </ModalBody>
                          </Modal>

                        </div>
                        <div className="savingrate_chart_r p-l-10">
                          <div className="f-16 f-w-600 color545">Yearly Increment</div>
                          <div className="f-16 f-w-600 color176 m-t-5">{parseInt(assumption.income_grow)}%</div>

                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="adjestyourplan_box m-t-30">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="adjestyourplan_box_title lightblue_bg">
                    <div className="f-20 f-w-700"><span className="color0a6 m-r-20">4</span> Investment Risk Profile </div>
                  </Accordion.Header>
                  <Accordion.Body className="adjestyourplan_box_content">
                    <div className="adjestyourplan_box_inner">
                      <div className="savingrate_chart m-t-40">
                        <div className="savingrate_chart_l p-r-10">
                          <div className="f-16 f-w-600 color545">Investment Style</div>
                          <div className="f-16 f-w-600 color06B m-t-5">{riskDescription}</div>
                          <button className="bluebtn m-t-40 f-18" onClick={() => window.location.href = '/risk-view'}><i className="fas fa-edit m-r-10"></i> View</button>
                        </div>
                        <div className="savingrate_chart_r p-l-10">
                          <div className="f-16 f-w-600 color545">Expected ROI</div>
                          <div className="f-16 f-w-600 color176 m-t-5">{roi}% <span className='changeRoi' onClick={() => roiOption()}>change</span></div>
                          <Modal isOpen={roiModal} toggle={roiToggle}>
                            <ModalHeader toggle={roiToggle}>{"Set ROI Option"}</ModalHeader>
                            <ModalBody>
                              <SetRoiOption data={profileAssumption} />
                            </ModalBody>
                          </Modal>

                          <div className="f-16 f-w-600 color176 m-t-40 cursor_pointer" onClick={() => editprofileassumption()}>(+/-) Assumptions %</div>
                          <Modal isOpen={profileAssumptionModal} toggle={profileAssumptionToggle}>
                            <ModalHeader toggle={profileAssumptionToggle}>{"Edit Profile Assumptions"}</ModalHeader>
                            <ModalBody>
                              <EditProfileAssumption data={profileAssumption} />
                            </ModalBody>
                          </Modal>

                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="adjestyourplan_box m-t-30">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="adjestyourplan_box_title lightblue_bg">
                    <div className="f-20 f-w-700"><span className="color0a6 m-r-20">5</span> Adjust Goals</div>
                  </Accordion.Header>
                  <Accordion.Body className="adjestyourplan_box_content">


                    <ul className="adjestyourplan_box_list f-18 f-w-600">

                      {// eslint-disable-next-line
                        goals.map((item, index) => {
                          return (
                            <>
                              <li className="p-r-10" key={index}>
                                <div className>In {parseInt(item.required_after)} Years, {item.full_name} {item.goal_name}, <span className='color176'>{item.target_amt}</span> <span className='text-danger'>{item.loan_perc > 0 ? ', ' + item.loan_perc + '% Loan' : ''}</span></div>
                                <div>

                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
                                      <i className="fas fa-sync-alt"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={() => EditGoal(item.goal_type, item)}>Edit</Dropdown.Item>
                                      <Dropdown.Item onClick={() => DeleteGoal(item.goal_id)}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </li>
                            </>
                          )
                        })}

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="adjestyourplan_box m-t-30">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="adjestyourplan_box_title lightblue_bg">
                    <div className="f-20 f-w-700"><span className="color0a6 m-r-20">6</span> Current Investments </div>
                  </Accordion.Header>
                  <Accordion.Body className="adjestyourplan_box_content">
                    <div className="adjestyourplan_box_inner">
                      <div className="f-16 f-w-600 color545">Networth</div>
                      <div className="f-16 f-w-600 color06B m-t-5">Rs {networth === null ? 'NA' : networth}</div>
                      <div className="savingrate_chart m-t-40">
                        <div className="savingrate_chart_l p-r-10">
                          <div className="f-16 f-w-600 color545">Currently linked</div>
                          <div className="f-16 f-w-600 color06B m-t-5">Rs {linkedSum === null ? 0 : formatAmount(linkedSum)}</div>
                          <Link to="/track-portfolio" className="bluebtn m-t-40 f-18"><i className="fas fa-edit m-r-10"></i> Edit</Link>
                        </div>
                        <div className="savingrate_chart_r  p-l-10">
                          <div className="f-16 f-w-600 color545">Cash/Onetime Available</div>
                          <div className="f-16 f-w-600 color176 m-t-5">Rs {cashSum === null ? 0 : formatAmount(cashSum)}</div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>




            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 m-t-70">
            <div className="adjestyourplan_r">
              <div className="planstatus_wpr">
                <div className="planstatus_title">
                  <div className="f-20 f-w-700">Plan Status</div>
                  <div>


                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
                        <i className="fas fa-ellipsis-v" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => updateSummary()}>Summary Sync</Dropdown.Item>
                        <Dropdown.Item onClick={() => fpreport()}>FP Report</Dropdown.Item>
                        <Dropdown.Item onClick={() => EditGoal('fpPlanStatus', 'NA')}>Generate My PLAN</Dropdown.Item>
                        <Dropdown.Item onClick={() => report_history()}>Report History</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className="m-t-30">
                  <h6>WHAT YOU NEED</h6>
                  <hr />
                  <div className="f-16 f-w-600">Total Goal Amount</div>
                  <div className="f-16 f-w-600">Rs {target/*formatAmount(Math.round(fund - actual)) */}   </div>
                </div>

                <div className="m-t-30">
                  <div className="f-16 f-w-600">Yearly Required</div>
                  <div className="f-16 f-w-600 colorff1">Rs {formatAmount(Math.round((fund) / shortYearly))}</div>
                  {assumption.income_grow > 0 ? <span>and increase by {parseInt(assumption.income_grow)}% every year</span> : ''}
                </div>

                <div className="m-t-30">
                  <div className="f-16 f-w-600">Currently linked</div>
                  <div className="f-16 f-w-600 color06B">Rs {linkedSum === null ? 0 : formatAmount(linkedSum)}</div>
                </div>


                <div className="m-t-30">
                  <div className="f-16 f-w-600">This Year Required</div>
                  <div className="f-16 f-w-600 colorff1">Rs {formatAmount(Math.round((fund - actual) / shortYearly))}</div>
                  {assumption.income_grow > 0 ? <span>and increase by {parseInt(assumption.income_grow)}% every year</span> : ''}
                </div>
                <div className="m-t-30">
                  <h6>WHAT YOU HAVE</h6>
                  <hr />
                  <div className="f-16 f-w-600">This Year Saving</div>
                  <div className="f-16 f-w-600">Rs {totalSaving === null ? 0 : formatAmount(totalSaving)}</div>

                </div>



                <hr />
                <div className="m-t-30">

                  <div className="f-16 f-w-600">Your Saving Target</div>



                  {
                    cashflowOption === "3" ? <>
                      <div className="f-16 f-w-600">Rs {savingTarget !== "0.00" ? formatAmount(Math.round(savingTarget)) + ' /per year' : 'Not Set'}</div>
                      {Math.round(savingTarget) >= (Math.round((fund - actual) / shortYearly)) ? <div className="cashflow_commonbox_title_l"><span className="circle greenclr m-r-20"></span> On Track</div> : <div className="cashflow_commonbox_title_l"><span className="circle redclr m-r-20"></span> Off Track</div>}
                    </> : (cashflowOption === "1" ? <><div className="f-16 f-w-600">Rs {formatAmount(Math.round((fund - actual) / shortYearly)) + ' /per year'}</div><div className="cashflow_commonbox_title_l"><span className="circle greenclr m-r-20"></span> On Track</div></> : <><div className="f-16 f-w-600">Rs {formatAmount(Math.round(totalSaving)) + ' /per year'}</div>{Math.round(totalSaving) >= (Math.round((fund - actual) / shortYearly)) ? <div className="cashflow_commonbox_title_l"><span className="circle greenclr m-r-20"></span> On Track</div> : <div className="cashflow_commonbox_title_l"><span className="circle redclr m-r-20"></span> Off Track</div>}</>)
                  }

                </div>

                <div className="m-t-30">
                  <div className="f-16 f-w-600">This Year Ongoing</div>
                  <div className="f-16 f-w-600">Rs {ongoingSaving === null ? 0 : formatAmount(ongoingSaving)}</div>
                </div>

                <div className="m-t-30">
                  <div className="f-16 f-w-600">Additional This Year</div>
                  <div className="f-16 f-w-600">Rs {cashflowOption === "3" ? formatAmount(Math.round(customAmount - ongoingSaving)) : (cashflowOption === "1" ? formatAmount(Math.round(((fund - actual) / shortYearly) - ongoingSaving)) : formatAmount(Math.round(totalSaving - ongoingSaving)))}</div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div className="row m-t-40">

          <Col lg="12 box-col-12" xl="12 xl-100">
            <Card>
              <CardHeader>
                <h5>{"Goal Timeline"} </h5><span onClick={() => EditGoal('cashflowOpt', 'NA')}>Change</span>
              </CardHeader>
              <CardBody className="tabbed-card">
                <Nav className="nav-pills nav-primary">
                  <NavItem>
                    <NavLink className={activeTab2 === '1' ? 'active' : ''} onClick={() => setActiveTab2('1')}>
                      <i className="icofont icofont-ui-home"></i> {"Graph"}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab2 === '2' ? 'active' : ''} onClick={() => setActiveTab2('2')}>
                      <i className="icofont icofont-man-in-glasses"></i>{"Tabular"}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab2}>
                  <TabPane tabId="1">
                    <div className="chart-container">
                      <Chart options={chartData.options} series={chartData.series} height="500" type="area" />
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="best-seller-table responsive-tbl">
                      <div className="item">
                        <div className="table-responsive product-list">
                          <Table borderless>
                            <thead>
                              <tr>
                                <th className="f-22">{"Sr. No."}</th>
                                <th>{"Year"}</th>
                                <th>{"Fresh Investment"}</th>
                                <th>{"Existing Investment"}</th>
                                <th>{"FV"}</th>
                                <th>{"Other Income"}</th>
                                <th>{"Outflows"}</th>
                                <th className="text-right">{"Net Amount"}</th>
                              </tr>
                            </thead>
                            <tbody>

                              {// eslint-disable-next-line
                                cashflow.length > 0 ?
                                  cashflow.map((item, index) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            <div className="d-inline-block align-middle">
                                              {item.no}
                                            </div>
                                          </td>
                                          <td>{item.year}</td>
                                          <td>{formatAmount(item.fresh_investment)}</td>
                                          <td>{formatAmount(item.actual_fund)}</td>
                                          <td>{formatAmount(item.fv)}</td>
                                          <td>{item.other_income}</td>
                                          <td>{formatAmount(item.outflow)}</td>
                                          <td className={item.nett < 0 ? 'text-danger' : ''}>{formatAmount(item.nett)}</td>
                                        </tr>
                                      </>
                                    )
                                  }) : ''}


                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                </TabContent>
              </CardBody>
            </Card>
          </Col>


        </div>

      </div>{/* container end */}




      <div className="clear"></div>
      <Footer />
    </React.Fragment >
  );
}
