import React, { useState } from 'react';
import GoalProfile from './../GoalSteps/GoalProfile';
import HealthIncome from './../GoalSteps/HealthIncome';
import HealthDependant from './../GoalSteps/HealthDependant';

export default function AddHealth({title}) {
  const [steps, setSteps] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [stepCount, setStepCount] = useState(0);

  const StepChange = (step) => {
    setSteps(step);
  }

  return (
    <>
      {steps === 1 && (<GoalProfile title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} setStepCount={setStepCount} goalType={"Health Insurance"}/>)}

      {steps === 2 && (<HealthIncome title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}

      {steps === 3 && (<HealthDependant title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}
    </>
  );
}
