import React, { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import axios from 'axios';
import { apiUrl } from '../../constants/global';

export default function Strategy() {

    const [step, setStep] = useState(1);

    const [riskDescription, setRiskDescription] = useState();
    const [riskId, setRiskId] = useState();
    const [goalCount, setGoalCount] = useState();

    const ChangeStep = (step) => {
        setStep(step);
    }

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');

        
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
           (response, data) => {
              if (response.data.status === 100) {
                console.log(response.data.risk_profile);
                setRiskDescription(response.data.risk_profile.risk_description);
                setRiskId(response.data.risk_profile.log_id);
                setGoalCount(response.data.goal_count);
  
              }
           });
  
  
     });

    return (
        <Fragment>
            <div className="graybg">
                <div className="theme-form">
                    
                    {step === 1 && <Step1 step={step} ChangeStep={ChangeStep} riskId={riskId} riskDescription={riskDescription} goalCount={goalCount}/>}
                    {step === 2 && <Step2 step={step} ChangeStep={ChangeStep} riskId={riskId} riskDescription={riskDescription} goalCount={goalCount}/>}
                    {step === 3 && <Step3 step={step} ChangeStep={ChangeStep} riskId={riskId} riskDescription={riskDescription} goalCount={goalCount}/>}
                    {step === 4 && <Step4 step={step} ChangeStep={ChangeStep} riskId={riskId} riskDescription={riskDescription} goalCount={goalCount}/>}
                </div>
            </div>

        </Fragment>
    )
}
