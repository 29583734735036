export function DeleteGoal (data){

}

export function AddRetirement(params){
    
}

export function SetBackUrl(url){
    localStorage.setItem('backUrl', url);
}

export function GetBackUrl(data){
    return localStorage.getItem('backUrl');
}

export function calculateAge(dob){
    const today = new Date().getTime();
    const birthday = new Date(dob).getTime();
    const age_mili = Math.abs(today - birthday);

    let days = Math.floor(age_mili / (1000 * 3600 * 24));
    let years = Math.floor(days / 365);


    return years;
}