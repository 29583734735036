import React, { useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import numWords from 'num-words';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";

export default function AddRealEastate() {

    const [validator, showValidationMessage] = useValidator();
    const [amount, setAmount] = useState("");
    const [amount_txt, setAmount_txt] = useState("");
    const ucwords = require('ucwords');
    const [JobData, setJobData] = useState([])
    const [sub_type, setsub_type] = useState("1");
    const [investor_name, setInvestor_name] = useState("");
    let { Id } = useParams();
    const [invest_id, setInvest_id] = useState("");

    const [propertyName, setPropertyName] = useState();
    const [refNo, setRefNo] = useState();

    const [propertyData, setPropertyData] = useState([]);

    useEffect(() => {
        let profSumm = JSON.parse(sessionStorage.getItem('ProfSummary'));
        //console.log(profSumm.prof_det);
        setJobData(profSumm.prof_det);
        let profile_id = sessionStorage.getItem("profile_id");
        if (Id != null) {
            axios.get(`${apiUrl}investment/edit/${Id}`).then(
                (response, data) => {
                    if (response.data.invest_id != null) {
                    
                        setInvestor_name(response.data.profile_details_id);
                        setsub_type(response.data.sub_type);
                        setInvest_id(response.data.invest_id);
                    }
                });
        }


        axios.get(`${apiUrl}investment/get-property/${profile_id}`).then(
            (response, data) => {
                if (response.data !== null) {
                    setPropertyData(response.data);
                    console.log(response.data);
                }
            });


    }, [Id])




    const handleChange = (e) => {
        console.log(e.target.name);
        const { value } = e.target;

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));

        if (e.target.name === 'amount') {
            setAmount(formatAmount(value.replace(/,/g, "")));
            setAmount_txt(ucwords(amountInWords));
        } else if (e.target.name === 'propertyName') {
            setRefNo(value);
            setPropertyName(e.target[e.target.selectedIndex].getAttribute('p_name'));
        } else if (e.target.name === 'sub_type') {
            setsub_type(value);
        }else if (e.target.name === 'investor_name') {
            setInvestor_name(value);
        } 

    };


    const handleSubmit = (e) => {
        if (validator.allValid()) {



            /*
            investor_name: Z0xxTkVHcGVwNUJZcG1uK3dhd0NSZz09
            property_name_rent: cDE2aFRVb2JBYURMSEV1Wmc0MVhNQT09
            rent_per_year: 1,50,000
            Start_After: 05 Feb 2020
            profInvestmentName: real-estate-rental-form
            sub_type: 1
            check: 
            action: form
            */


            const Postdata = {
                "prop_id": sessionStorage.getItem('profile_id'),
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": investor_name,
                "type": 8,
                "owner": sessionStorage.getItem('profile_id'),
                "instr_name": propertyName,
                "sub_type": sub_type,
                "frequency": 12,
                "cost_amount": amount.replace(/,/g, ""),
                "current_value": 0,
                "amount": amount.replace(/,/g, ""),
                "invest_amount": amount.replace(/,/g, ""),
                "invest_id": invest_id,
                "ref_no":refNo
            };

            // logical fields - start_date, months

            axios.post(`${apiUrl}investment/add-investment`, Postdata)
                .then(function (response) {

                    SweetAlert.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Real Estate Rental Successfully added/updated.',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                           toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                           toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                     }).then(function () {
                        window.location.href = "/track-portfolio";
                     });

                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Real Estate',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <div className="row DivJustify">
            <div className="col-lg-8 col-md-12 col-sm-12">

                <div className="financiali-tilte"><h1><Link to="/track-portfolio" className="goback"><i className="fas fa-angle-left" /></Link> Add Real Estate</h1></div>
                <Progress color="success" value="100" className="sm-progress-bar" />
                <div className=" box-shadow Creating-Financial">



                    <div className="input-box selectdiv mb-4">
                        <label> Investor Name </label>



                        <select className="form-control digits" name={"investor_name"} value={investor_name} onChange={handleChange} autoComplete="off">
                            <option value="">Select Investor Name </option>
                            {JobData.map((data, i) => {
                                return (
                                    <option value={data.profile_details_id} key={i} g_dob={data.dob}>{data.full_name}</option>
                                )
                            })}
                        </select>
                        {validator.message("investor_name", investor_name, "required", {
                            messages: {
                                required: "Investor Name is required"
                            }
                        })}
                    </div>

                    <div className="input-box selectdiv mb-4">
                        <label> Property Name </label>

                        <select name={"propertyName"} value={propertyName} onChange={handleChange} autoComplete="off" className="form-control digits">
                        <option value="">Select Property Name </option>
                        

                        {propertyData.map((data, i) => {
                                return (
                                    <option value={data.invest_id} key={i} p_name={data.instr_name}>{data.instr_name}</option>
                                )
                            })}
                        </select>


                        {validator.message("propertyName", propertyName, "required", {
                            messages: {
                                required: "Property Name is required"
                            }
                        })}
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="input-box ruppys yearly">
                                <label> Expected Yearly Rental </label>
                                <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"amount"} value={amount} onChange={handleChange} />
                                <span className="text-word">{amount_txt}</span>
                                {validator.message("amount", amount, "required", {
                                    messages: {
                                        required: "Amount is required"
                                    }
                                })}
                                <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            </div>
                        </div>
                    </div>




                    <div className="form-group">

                        <div className="row">


                            <div className="col-12">
                                <div className="text-end">
                                    <button className="next" type="button" onClick={handleSubmit}>Save</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
