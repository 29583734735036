import React from 'react'

export default function CreateProfileSteps() {
  return (
    <div className="graybg">
     
      
      
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12"> </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="financiali-tilte"> Creating Your Financial Plan</div>
          <div className=" box-shadow Creating-Financial">
            <h1> About Cashflow: Income </h1>
            <div className="input-box ruppys">
              <label> Your primary income </label>
              <input type="text" />
              <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
              <div className="montly"> Monthly</div>
            </div>
            <div className="input-box ruppys yearly">
              <label> Your primary income </label>
              <input type="text" />
              <div className="intext"> Two lakhs </div>
              <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
              <div className="montly"> Yearly</div>
            </div>
            <div className="input-box ruppys yearly">
              <label> Your primary income </label>
              <input type="text" />
              <div className="intext"> Two lakhs </div>
              <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
              <div className="montly"> Yearly</div>
            </div>
            <div className="bottombtn">
              <a href="/#" className="back"> <i className="fas fa-angle-left" /> </a>
              <a href="/#" className="next"> Next<i className="fas fa-angle-right" /> </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12"> </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12"> </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="financiali-tilte"> Creating Your Financial Plan</div>
          <div className=" box-shadow Creating-Financial">
            <h1> Your spouse details </h1>
            <div className="input-box ">
              <label>Name of your spouse </label>
              <input type="text" />
            </div>
            <div className="input-box date">
              <label> Please Select your life stage </label>
              <input type="date" />
              <span> 41 years</span>
            </div>
            <div className="input-box ruppys income">
              <label> Your primary income </label>
              <input type="text" />
              <div className="intext"> Two lakhs </div>
              <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
              <div className="montly"> Yearly</div>
            </div>
            <div className="bottombtn">
              <a href="/#" className="back"> <i className="fas fa-angle-left" /> </a>
              <a href="/#" className="next"> Next<i className="fas fa-angle-right" /> </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12"> </div>
      </div>
    </div>

  )
}