import React, { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import { Button } from 'reactstrap'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';

export default function EditLife({ data }) {

    console.log(data);
    const [validator, showValidationMessage] = useValidator();
    // eslint-disable-next-line
    const [expenses, setExpenses] = useState(formatAmount(data.total_abcd));
    // eslint-disable-next-line
    const [inflation, setInflation] = useState(parseInt(data.goal_inflation * 100));
    // eslint-disable-next-line
    const [income, setIncome] = useState(formatAmount(data.income));
    // eslint-disable-next-line
    const [income_grow, setIncomeGrow] = useState(parseInt(data.income_grow * 100));

    const [checked, setChecked] = useState(data.auto_reset === "1" ? '' : 'checked');
    const [auto_reset, setAuto_reset] = useState();

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'expenses':
                setExpenses(formatAmount(e.target.value.replace(/,/g, "")));
                break;

            case 'inflation':
                setInflation(e.target.value);
                break;

            case 'income':
                setIncome(e.target.value);
                break;

            case 'income_grow':
                setIncomeGrow(e.target.value);
                break;

            case 'auto_reset':
                setChecked(checked === 'checked' ? '' : 'checked');
                setAuto_reset(checked === 'checked' ? '1' : '0');
                break;
            default:
                break;
        }
    };

    const numtoWord = () => {

    }

    const handleSubmit = (e) => {
        if (validator.allValid()) {



            const age = 44;

            const postData = {
                "goal_type": "Life Insurance",
                "goal_id": data.goal_id,
                "aspire": data.aspire,
                "income_grow": income_grow,
                "inflation": inflation,
                "profile_id": data.profile_id,
                "profile_details_id": data.profile_details_id,
                "yearly_expense": expenses.replace(/,/g, ""),
                "yearly_income": income.replace(/,/g, ""),
                "auto_reset": auto_reset,
                "dob": age,
                "api_type": 'insert',
                'goal_group': 2
            };


            axios.post(`${apiUrl}goals/addlife`, postData)
                .then(function (response) {
                    SweetAlert.fire(
                        'Goals',
                        'Successfully Updated Goal.',
                        'success'
                    ).then(function () {
                        window.location.href = "/risk-goals"
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-row">
                <div className='row'>
                    <div className="col-md-6 input-popup">
                        <label> Yearly Income as of Today </label>
                        <input type="text" className="form-control userInput" aria-label="Monthly Income as of Today" name={"income"} value={income} onChange={handleChange} onKeyPress={numtoWord} autoComplete="off" />
                        {validator.message("income", income, "required", {
                            messages: {
                                required: "Income is required"
                            }
                        })}
                        <div className="ruppy-popup"> <i className="fas fa-rupee-sign" /></div>
                    </div>

                    <div className="col-md-6 input-popup">
                        <label> Yearly Expenses as of Today </label>
                        <input type="text" className="form-control userInput" aria-label="Expense" name={"expenses"} value={expenses} onChange={handleChange} onKeyPress={numtoWord} autoComplete="off" />
                        {validator.message("expenses", expenses, "required", {
                            messages: {
                                required: "Expense is required"
                            }
                        })}
                        <div className="ruppy-popup"> <i className="fas fa-rupee-sign" /></div>
                    </div>

                    <div className="col-md-6 input-popup">
                        <label> Income Growth Rate </label>
                        <input type="text" className="form-control" aria-label="Inflation" name={"income_grow"} value={income_grow} onChange={handleChange} autoComplete="off" />
                        {validator.message("income_grow", income_grow, "required", {
                            messages: {
                                required: "Income Growth is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> % </div>
                    </div>


                    <div className="col-md-6 input-popup">
                        <label> Assumed Inflation</label>
                        <input type="text" className="form-control" aria-label="Assumed Inflation" name={"inflation"} value={inflation} onChange={handleChange} autoComplete="off" />
                        {validator.message("inflation", inflation, "required", {
                            messages: {
                                required: "Inflation is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> % </div>
                    </div>


                    <div className="col-md-6 input-popup">


                        <label className="text-muted" htmlFor="checkbox1">Click to Disable Auto Reset </label>
                        <input id="checkbox1" type="checkbox" name={"auto_reset"} checked={checked} value={auto_reset} onChange={handleChange} className='autoReset' />

                    </div>

                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-12">
                            <Button color="success" className="mr-1" type="button" onClick={handleSubmit}>Save</Button>
                            <Button color="primary">{"Cancel"}</Button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
