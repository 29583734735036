import React, { Fragment, useState } from 'react'
import useValidator from '../../hooks/useValidator'
import { Input, Col } from 'reactstrap'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { calculateAge } from '../../constants/common';
import { useEffect } from 'react';
export default function MarriageAge({ title, StepChange, steps, setPercentage, percentage }) {

    const [required_age, setUserval] = useState(26);
    const [value, setvalue] = useState();
    const [validator, showValidationMessage] = useValidator();

    const onSliderChange = value => {
        setvalue(value);
        setUserval(value);
        sessionStorage.setItem('required_after', value);
    };

    const onAfterChange = value => {
        setUserval(null);
    };

    const handleInputChange = (event) => {
        setvalue(event.target.value);
        setUserval(event.target.value);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleBlur = () => {
        if (required_age < 0) {
            setUserval(25);
        } else if (required_age > 35) {
            setUserval(35);
        }
    };

    const handleSubmit = (event) => {
        if (validator.allValid()) {

            const age = calculateAge(sessionStorage.getItem('g_dob'));
            const life_expectancy = 85;
            const relation = "self";
            const aspire = "Marriage";
            const postData = {
                "goal_type": "Marriage",
                "goal_name": "Marriage",
                "aspire": aspire,
                "profile_id": sessionStorage.getItem('profile_id'),
                "plan_for": {
                    "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
                    "current_age": sessionStorage.getItem('current_age'),
                    "relation": relation
                },
                "goal_params": {
                    "age": age,
                    "retire_age": value,
                    "life_expectancy": life_expectancy,
                    "post_inflation": 7,
                    "post_return": 7,
                    "goal_inflation": 7,
                    "required_after": value,
                    "required_till": value
                },
                "risk_profile": {
                    "available": false,
                    "pre_return": 8,
                    "risk_profile_id": sessionStorage.getItem('profile_id')
                },
                "assumptions": {
                    "assumption_id": false,
                    "inflation": 7.5,
                    "income_grow": "10"
                },
                "total_abcd": [{
                    "amount": sessionStorage.getItem("g_expense").replace(/,/g, ""),
                    "frequency": 1,
                    "no_of_times": 1,
                    "gap": 0
                }],
                "actual": [{
                    "actual_y": 0,
                    "frequency": 1,
                    "actual_ls": 1,
                    "grow": "0"

                }]
            };


            axios.post(`${apiUrl}goals/add`, postData)
                .then(function (response) {
                    axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                        (response, data) => {
                            SweetAlert.fire(
                                'Goals',
                                'Added New Goal.',
                                'success'
                            ).then(function () {
                                window.location.href = "/"
                            })
                        }
                    );

                })
                .catch(function (error) {
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    };

    useEffect(() => {
        setvalue(26);
    }, []);
    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <h1> Marriage Goal for {"profile_name"}</h1>
                        <div className="input-box ruppys yearly">
                            <div md="10">
                                <label>{"Age at Marriage"}</label></div>
                            <div md="2">
                                <Input
                                    value={required_age}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    style={{ marginTop: '50px' }}
                                    min={25}
                                    max={35}
                                />
                                
                            </div>
                            <Col md="12">
                                <Slider
                                    onChange={onSliderChange}
                                    onAfterChange={onAfterChange}
                                    min={25}
                                    max={35}
                                    defaultValue={value}
                                    value={value}
                                />
                            </Col>
                        </div>
                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Save<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
