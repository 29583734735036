import React, { Fragment, useState } from 'react'
import useValidator from '../../hooks/useValidator'
import { Input, Col } from 'reactstrap'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useEffect } from 'react';

export default function WealthYears({title, StepChange, steps, setPercentage, percentage, profileName, goalName}) {

    const [start_in, setUserval] = useState(5);
    const [value, setvalue] = useState();
    const [validator, showValidationMessage] = useValidator();

    const onSliderChange = value => {
        setvalue(value);
        setUserval(value);
    };

    const onAfterChange = value => {
        setUserval(null);
    };

    const handleInputChange = (event) => {
        setvalue(event.target.value);
        setUserval(event.target.value);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleBlur = () => {
        if (start_in < 0) {
            setUserval(0);
        } else if (start_in > 100) {
            setUserval(100);
        }
    };

    const handleSubmit = (event) => {
        if (validator.allValid()) {

            const age = 45;
            const life_expectancy = 85;
            const relation = "self";
            const required_after = value;
            const required_till = value;
            const postData = {
                "goal_type": "Wealth",
                "goal_name":goalName,
                "aspire": sessionStorage.getItem('aspire'),
                "profile_id": sessionStorage.getItem('profile_id'),
                "plan_for": {
                    "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
                    "current_age": sessionStorage.getItem('current_age'),
                    "relation": relation
                },
                "goal_params": {
                    "age": age,
                    "retire_age": value,
                    "life_expectancy": life_expectancy,
                    "post_inflation": 7,
                    "post_return": 7,
                    "goal_inflation": 7,
                    "required_after": required_after,
                    "required_till": required_till
                },
                "risk_profile": {
                    "available": false,
                    "pre_return": 8,
                    "risk_profile_id": sessionStorage.getItem('profile_id')
                },
                "assumptions": {
                    "assumption_id": false,
                    "inflation": 7.5,
                    "income_grow": "10"
                },
                "total_abcd": [{
                    "amount": sessionStorage.getItem("expense").replace(/,/g, ""),
                    "frequency": 1,
                    "no_of_times": 1,
                    "gap": 0
                }],
                "actual": [{
                    "actual_y": 0,
                    "frequency": 1,
                    "actual_ls": 1,
                    "grow": "0"

                }]
            };


            axios.post(`${apiUrl}goals/add`, postData)
                .then(function (response) {

                    axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                        (response, data) => {
                          SweetAlert.fire(
                            'Goals',
                            'Added New Goal.',
                            'success'
                          ).then(function () {
                            window.location.href = "/"
                          })
                        }
                      );

                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    };

    useEffect(() => {
        setvalue(5);
    }, []);

    return(
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                    <h1> {title} for {profileName}</h1>
                        <div className="input-box ruppys yearly">
                            <div md="10">
                                <label>{"In how many years ?"}</label></div>
                            <div md="2">
                                <Input
                                    value={start_in}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    style={{ marginTop: '50px' }}
                                />
                            </div>
                            <Col md="12">
                                <Slider
                                    onChange={onSliderChange}
                                    onAfterChange={onAfterChange}
                                    min={1}
                                    max={20}
                                    defaultValue={value}
                                    value={value}
                                />
                            </Col>
                        </div>
                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Save<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
