import React, { Fragment, useState } from 'react'
import AddHealth from './RiskProfile/AddHealth';
import AddHealthChoice from './RiskProfile/AddHealthChoice';

export default function Health() {

  const [steps, setSteps] = useState(1);
  const StepChange = (step) => {
    setSteps(step);
  }

  return (
    <Fragment>
      <div className="tap-top"><i data-feather="chevrons-up"></i></div>
      <div className="page-wrapper CustomPage">
        <div className="container">
          {steps === 1 && (<AddHealthChoice StepChange={StepChange} />)}
          {steps === 2 && (<AddHealth StepChange={StepChange} />)}
        </div>
      </div>
    </Fragment>
  )
}
