import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button } from 'reactstrap'
import axios from 'axios';
import { apiUrl } from '../constants/global';
import SweetAlert from 'sweetalert2'

export default function AddFamily({ goalStep, data }) {

    const { register, handleSubmit, formState: { errors } } = useForm();

    // eslint-disable-next-line
    const [first_name, setFirst_name] = useState(data ? data.first_name : "");
    // eslint-disable-next-line
    const [last_name, setLast_name] = useState(data ? data.last_name : "");
    // eslint-disable-next-line
    const [relationship, setRelationship] = useState(data ? data.relation : "");
    // eslint-disable-next-line
    const [dob, setDob] = useState(data ? data.dob : "");
    // eslint-disable-next-line
    const [profileDetId, setProfileDetId] = useState(data ? data.profile_details_id : " ");
    const [mobile, setMobile] = useState(data ? data.phone : '');

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'first_name':
                setFirst_name(e.target.value);
                break;

            case 'last_name':
                setLast_name(e.target.value);
                break;

            case 'relationship':
                setRelationship(e.target.value);
                break;

            case 'dob':
                setDob(e.target.value);
                break;

            case 'mobile':
                setMobile(e.target.value);
                break;

            default:
                break;
        }
    };


    const AddContact = () => {

        console.log('profile id '+profileDetId);;
        const PostData = {
            "profile_id": sessionStorage.getItem('profile_id'),
            "profile_details_id": profileDetId !== null || profileDetId !== undefined  ? profileDetId : sessionStorage.getItem('spouse_profile_details_id'),
            "dob": dob,
            "phone":mobile,
            "first_name": first_name,
            "last_name": last_name,
            "name": first_name + ' ' + last_name,
            "relation": relationship
        };

        console.log(PostData);

        axios.post(`${apiUrl}profile-details/create`, PostData)
            .then(function (response) {

                console.log(profileDetId);
                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: profileDetId === undefined ? 'Successfully created .' : 'Successfully updated.',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                }).then(function () {

                    window.location.reload();

                });
            });
    }

    return (
        <Fragment>
            <form className="form-bookmark" onSubmit={handleSubmit(AddContact)}>
                <div className="form-row">
                    <div className="col-md-12 mt-1">
                        <label> {"Name"}</label>
                        <div className='row'>
                            <div className='col-md-6 input-box-fluid'>

                                <input type="text" className="form-control" name="first_name" autoComplete='off' {...register("first_name", { required: true })} value={first_name} onChange={handleChange} />
                                <span className="srv-validation-message"><ErrorMessage errors={errors} name="first_name" message="This is required" /></span>
                            </div>
                            <div className='col-md-6'>
                                <input type="text" className="form-control" name="last_name" autoComplete='off' {...register("last_name", { required: true })} value={last_name} onChange={handleChange} />
                                <span className="srv-validation-message"><ErrorMessage errors={errors} name="last_name" message="This is required" /></span>
                            </div>
                        </div>
                    </div>

                    {relationship === "self" ? <>

                        <div className="col-md-12 input-box-fluid">
                            <label> {"Mobile No."}</label>
                            <input type="tel" className="form-control" name="mobile" autoComplete='off' {...register("mobile", { required: true, maxLength: 12})} value={mobile} onChange={handleChange} />
                            <span className="srv-validation-message"><ErrorMessage errors={errors} name="mobile" message="This is required" /></span>
                        </div>
                    </> :
                        <>
                            <div className="col-md-12 input-box-fluid">
                                <label> {"Relationship"}</label>
                                <select className="form-control digits" name="relationship" type="select" {...register("relationship", { required: true })} value={relationship} onChange={handleChange}>
                                    <option value="">Select Relation</option>
                                    <option value="spouse">Spouse</option>
                                    <option value="son">Son</option>
                                    <option value="daughter">Daughter</option>
                                    <option value="father">Father</option>
                                    <option value="mother">Mother</option>
                                    <option value="HUF">HUF</option>
                                    <option value="father-in-law">Father-in-law</option>
                                    <option value="mother-in-law">Mother-in-law</option>
                                    <option value="sibling">Sibling</option>
                                    <option value="company">Company</option>
                                    <option value="employee">Employee</option>
                                    <option value="other">Other</option>
                                    <option value="Individual">Individual</option>
                                    <option value="Non-Individual">Non-Individual</option>
                                </select>
                            </div>

                            <div className="col-md-12 input-box-fluid">
                                <label> {"DOB"}</label>
                                <input type="date" maxLength="4" pattern="[1-9][0-9]{3}" max={"9999-12-31"} className="form-control" name="dob" autoComplete='off' {...register("dob", { required: true })} value={dob} onChange={handleChange} />
                                <span className="srv-validation-message"><ErrorMessage errors={errors} name="dob" message="This is required" /></span>
                            </div>
                        </>
                    }
                </div>

                <Button color="success" type="submit">{"Save"}</Button>
                <Button color="primary" className="ml-1">{"Cancel"}</Button>

            </form>
        </Fragment>
    )
}
