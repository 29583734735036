import React, { Fragment, useState } from 'react'
//import { Link } from 'react-router-dom'
import useValidator from '../hooks/useValidator'
import { apiUrl, } from '../constants/global';
import axios from 'axios';
import SweetAlert from 'sweetalert2'

export default function Otp() {

   const [validator, showValidationMessage] = useValidator();
   const [otp, setOtp] = useState('')
   // eslint-disable-next-line
   const [mobile, setMobile] = useState(9930578095)

   const handleChange = (e) => {
      const { value } = e.target;
      if (e.target.name === 'otp') {
         setOtp(value);
      }
   }

   const resendOtp = (e) =>{
      console.log('clicked resent otp');
   }

   const handleSubmit = (e) => {
      if (validator.allValid()) {

         const Postdata = {
            "otp": otp,
            "mobile": mobile
         };

         axios.post(`${apiUrl}login`, Postdata)
            .then(function (response) {


               SweetAlert.fire({
                  toast: true,
                  icon: 'success',
                  title: 'Signed in Successfully',
                  animation: false,
                  position: 'top-right',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                     toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                     toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                  }
               }).then(function () {

               });
            })
            .catch(e => {
               SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });
      } else {
         showValidationMessage(true);
      }
   }

   return (
      <Fragment>
         <div className="container-fluid">
            <div className="row">
               <div className="col-xl-6"><img className="bg-img-cover bg-center" src="assets/images/login/Login.png" alt="looginpage" /></div>
               <div className="col-xl-6 p-0">
                  <div className="login-card">
                     <div>
                        <div className="login-main">

                           <form class="theme-form">
                              <h4>Verify Your Mobile Number</h4>
                              <div class="form-group">
                                 <label class="col-form-label">Otp Successfully delivered on </label>
                                 <div class="row">
                                    <div class="col-4 col-sm-3">
                                       <input class="form-control mb-1" type="text" value="+ 91" />
                                    </div>
                                    <div class="col-8 col-sm-9">
                                       <input class="form-control mb-1" type="tel" value="9930 578 095" />
                                    </div>
                                    <div class="col-12">
                                       <div class="text-end">
                                          <div class="mt-2 mb-2"><span class="reset-password-link">If don't receive OTP?&nbsp;&nbsp;<span class="btn-link text-danger" onClick={resendOtp}>Resend</span></span></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div class="form-input position-relative">

                                 <div class="form-group">
                                    <label class="col-form-label pt-0">Enter OTP</label>
                                    <input class="form-control" type="text" name={"otp"} value={otp} maxLength={6} required="" placeholder="*********" onChange={handleChange} />
                                    {validator.message("otp", otp, "required", {
                                       messages: {
                                          required: "OTP is required"
                                       }
                                    })}
                                 </div>
                              </div>


                              <div class="form-group mb-4 mt-4">
                                 <button class="btn btn-primary btn-block w-100" type="button" onClick={handleSubmit}>Validate</button>
                              </div>
                           </form>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   )
}
