import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from './Home'
import About from './About'
import Plan from './Plan'
import Login from './Login'
import Ulogin from './Ulogin'
import Register from './Register'
import CreateProfile from './CreateProfile'
import Loans from './Loans'
import PlanStatus from './PlanStatus'
import AdminDashboard from './AdminDashboard'
import AddGoals from './AddGoals'
import GoalCashflow from './GoalCashflow'
import Transact from './Transact'
import Portfolio from './Portfolio'
import Risk from './Risk'
import RiskSet from './RiskSet'

import Advise from './Advise'
import Cashflow from './Cashflow'
import CreateProfileSteps from './CreateProfileSteps'
import Profile from './Profile'
import GoalDashboard from './GoalDashboard'
import Insurance from './Insurance';
import Health from './Health'
import CreateGoalsOld from './CreateGoalsOld'
import Adjust from './Adjust';
import AddEmi from './AddEmi'
import ChooseInsurance from './ChooseInsurance'
import RiskView from './RiskView'
import RiskGoals from './RiskGoals'
import EditEmi from './EditEmi'

import TrackPortfolio from './TrackPortfolio'

import AddDeposits from './Portfolio/AddDeposits'
import AddEpfPpf from './Portfolio/AddEpfPpf'
import AddEquity from './Portfolio/AddEquity'
import AddMutualFund from './Portfolio/AddMutualFund'
import AddNps from './Portfolio/AddNps'
import AddGold from './Portfolio/AddGold'
import AddRealEastate from './Portfolio/AddRealEastate'
import AddRealEastateRental from './Portfolio/AddRealEastateRental'



import CreateGoals from './GoalsCreationStepper/CreateGoals'

import EditHealth from './RiskProfile/EditHealth'
import EditInsurance from './RiskProfile/EditInsurance'
import Strategy from './Strategy/Strategy'
import ReportHistory from './ReportHistory'
import Compose from './Compose'

import EmergencyFund from './EmergencyFund'

import Otp from './Otp'

import AutoLogin from './AutoLogin'

export default function RouterPage() {

    return (
        <div>
            <BrowserRouter>
                <Routes>

                    <Route path="/goals" element={<Home />}></Route>
                    <Route path="/risk-goals" element={<RiskGoals />}></Route>
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/add-goal/:goalName" element={<AddGoals />}></Route>
                    
                    <Route path="/otp" element={<Otp />}></Route>

                    <Route path="/plan" element={<Plan />}></Route>
                    <Route path="/" element={<Plan />}></Route>
                    <Route path="/sign-in" element={<Login />}></Route>
                    <Route path="/lms-login" element={<AutoLogin />}></Route>
                    <Route path="/emergency-fund" element={<EmergencyFund />}></Route>
                    <Route path="/ulogin" element={<Ulogin />}></Route>
                    <Route path="/strategy" element={<Strategy />}></Route>
                    <Route path="/sign-up" element={<Register />}></Route>
                    <Route path="/create-profile" element={<CreateProfile />}></Route>
                    <Route path="/create-profile-steps" element={<CreateProfileSteps />}></Route>
                    <Route path="/loans" element={<Loans />}></Route>
                    <Route path="/plan-status" element={<PlanStatus />}></Route>
                    <Route path="/admin-dashboard" element={<AdminDashboard />}></Route>
                    <Route path="/risk-profile" element={<Risk />}></Route>
                    <Route path="/risk-profile-set" element={<RiskSet />}></Route>
                    <Route path="/risk-plan" element={<Insurance />}></Route>
                    <Route path="/risk-plan-health" element={<Health />}></Route>
                    <Route path="/transact" element={<Transact />}></Route>
                    <Route path="/portfolio" element={<Portfolio />}></Route>
                    <Route path="/cashflow" element={<Cashflow />}></Route>
                    <Route path="/advise" element={<Advise />}></Route>
                    <Route path="/profile" element={<Profile />}></Route>
                    <Route path="/add-goal" element={<GoalDashboard />}></Route>
                    <Route path="/goals-creation-old" element={<CreateGoalsOld />}></Route>

                    <Route path="/goals-creation" element={<CreateGoals />}></Route>

                    <Route path="/adjust" element={<Adjust />}></Route>
                    <Route path="/add-emi" element={<AddEmi />}></Route>
                    <Route path="/add-insurance" element={<ChooseInsurance />}></Route>
                    <Route path="/risk-view" element={<RiskView />}></Route>

                    <Route path="/edit-health/:investId" element={<EditHealth />}></Route>
                    <Route path="/edit-life/:investId" element={<EditInsurance />}></Route>
                    <Route path="/edit-emi/:emiId" element={<EditEmi />}></Route>


                    <Route path="/retirement-cashflow/:goalId" element={<GoalCashflow />}></Route>
                    <Route path="/marriage-cashflow/:goalId" element={<GoalCashflow />}></Route>
                    <Route path="/education-cashflow/:goalId" element={<GoalCashflow />}></Route>
                    <Route path="/vacation-cashflow/:goalId" element={<GoalCashflow />}></Route>
                    <Route path="/wealth-cashflow/:goalId" element={<GoalCashflow />}></Route>

                    <Route path="/add-deposits" element={<AddDeposits title={"Add Deposits"}/>} ></Route>
                    <Route path="/add-equity" element={<AddEquity title={"Add Equity"}/>}></Route>
                    <Route path="/add-gold" element={<AddGold title={"Add Gold"}/>}></Route>
                    <Route path="/add-real-estate" element={<AddRealEastate title={"Add Real Estate"}/>}></Route>
                    <Route path="/add-real-estate-rental" element={<AddRealEastateRental title={"Add Real Estate Rental"}/>}></Route>

                    
                    <Route path="/add-mf" element={<AddMutualFund title={"Add Mutual Fund"}/>}></Route>
                    <Route path="/add-ppf" element={<AddEpfPpf title={"Add PPF/EPF"}/>}></Route>
                    <Route path="/add-nps" element={<AddNps title={"Add NPS"}/>}></Route>


                    <Route path="/edit-deposits/:Id" element={<AddDeposits title={"Edit Deposits"}/>} ></Route>
                    <Route path="/edit-equity/:Id" element={<AddEquity title={"Edit Equity"}/>}></Route>
                    <Route path="/edit-gold/:Id" element={<AddGold title={"Edit Gold"}/>}></Route>
                    <Route path="/edit-real-estate/:Id" element={<AddRealEastate title={"Edit Real Estate"}/>}></Route>
                    <Route path="/edit-real-estate-rental/:Id" element={<AddRealEastateRental title={"Edit Real Estate Rental"}/>}></Route>
                    <Route path="/edit-mf/:Id" element={<AddMutualFund title={"Edit Mutual Fund"}/>}></Route>
                    <Route path="/edit-ppf/:Id" element={<AddEpfPpf title={"Edit PPF/EPF"}/>}></Route>
                    <Route path="/edit-nps/:Id" element={<AddNps />}></Route>
                    
                    <Route path="/track-portfolio" element={<TrackPortfolio />}></Route>
                    <Route path="/report-history" element={<ReportHistory />}></Route>
                    <Route path="/compose" element={<Compose />}></Route>
                    
                    
                </Routes>
            </BrowserRouter>
        </div>
    )
}
