import React, { useEffect, useState } from 'react'
import axios from "axios";
import SweetAlert from 'sweetalert2'
import useValidator from '../../hooks/useValidator'
import { apiUrl } from '../../constants/global'
import { Link } from 'react-router-dom'

export default function BasicDetails({ StepChange, steps, setPercentage, percentage, stepCount }) {


    const [validator, showValidationMessage] = useValidator();
    const [dob, setDOB] = useState(sessionStorage.getItem('self_dob'));
    const [gender, setgender] = useState();

    const previousButton = () => {
        StepChange(steps - 1);
        setPercentage(percentage - 100 / stepCount);
    }

    const handleSubmitNext = () => {
        if (validator.allValid()) {

            const PostData = {
                "life_stage": "single",
                "gender": gender,
                "dob": dob,
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": sessionStorage.getItem("profile_details_id"),
                "source": 1,
                "step": steps
            };


            axios.post(`${apiUrl}add-basic-profile`, PostData)
                .then(function (response) {
                    SweetAlert.fire(
                        'Profile',
                        'Updated.',
                        'success'
                    ).then(function () {

                        sessionStorage.setItem('self_dob', dob);
                        sessionStorage.setItem('self_gender', gender)

                        const profile_id = sessionStorage.getItem('profile_id');
                        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
                            (response, data) => {
                                if (response.data.status === 100) {
                                    sessionStorage.setItem('ProfSummary', JSON.stringify(response.data));
                                }
                            });

                        StepChange(steps + 1);
                        setPercentage(percentage + 100 / stepCount);
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Profile',
                        'Failed.',
                        'error'
                    )
                });


        } else {
            showValidationMessage(true);
        }
    };
    const handleChange = e => {
        const { value } = e.target;
        if (e.target.name === 'dob') {
            setDOB(value);
        } else if (e.target.value === 'Male' || e.target.value === 'Female') {
            setgender(value);
            sessionStorage.setItem('self_gender', value);
        }
    };

    useEffect(() => {
        setgender(sessionStorage.getItem('self_gender'));
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="financiali-tilte"> <Link to="/plan" className="goback"><i className="fas fa-angle-left" /></Link> Creating Your Financial Plan</div>
                    <div className=" box-shadow Creating-Financial">
                        <h1> About Yourself </h1>
                        <div className="input-box date">
                            <label> Date of Birth </label>
                            <input className="form-control mb-1" maxLength="4" pattern="[1-9][0-9]{3}" max={"9999-12-31"} type="date" name={"dob"} value={dob} onChange={handleChange} />
                            {validator.message("dob", dob, "required", {
                                messages: {
                                    required: "DOB is required"
                                }
                            })}
                            <span></span>
                        </div>
                        <div className="gender">
                            <p className="mf"> Gender </p>
                            <input type="radio" id="male" name={"gender"} value={"Male"} onChange={handleChange} checked={gender === 'Male' ? true : false} />
                            <label htmlFor="male"><i className="ion ion-md-male" /> Male</label>
                            <input type="radio" id="female" name={"gender"} value={"Female"} onChange={handleChange} checked={gender === 'Female' ? true : false} />
                            <label htmlFor="female">  <i className="ion ion-md-female" /> Female</label>
                        </div>






                        <div className="bottombtn">
                            <button type="button" onClick={previousButton} className="back"> <i className="fas fa-angle-left" /> </button>
                            <button type="button" onClick={handleSubmitNext} className="next"> {"Next"} <i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
            </div>
        </>
    )
}
