import React, { useState } from 'react';
import GoalProfile from './../GoalSteps/GoalProfile';
import LifeIncome from './../GoalSteps/LifeIncome';
import LifeExpense from './../GoalSteps/LifeExpense'; 

export default function AddLife({title}) {
  const [steps, setSteps] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [stepCount, setStepCount] = useState(0);

  const StepChange = (step) => {
    setSteps(step);
  }

  return (
    <>
      {steps === 1 && (<GoalProfile title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} setStepCount={setStepCount} goalType={"Life Insurance"}/>)}

      {steps === 2 && (<LifeIncome title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}

      {steps === 3 && (<LifeExpense title={title} StepChange={StepChange} steps={steps} setPercentage={setPercentage} percentage={percentage} stepCount={stepCount} />)}
    </>
  );
}
