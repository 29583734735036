import React, { Fragment, useState } from 'react'
import useValidator from '../../hooks/useValidator'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Link } from 'react-router-dom'
export default function EducationPg({title, StepChange, steps, setPercentage, percentage }) {

    const [validator, showValidationMessage] = useValidator();
    const [expense, setExpense] = useState("");

    const handleChange = e => {
        const { value } = e.target;
        setExpense(formatAmount(value.replace(/,/g, "")));
        numtoWord(e);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleSubmit = () => {
        if (validator.allValid()) {
            sessionStorage.setItem('g_expense', expense);
            StepChange(steps + 1);
        } else {
            showValidationMessage(true);
        }
    };

    const [amt, setAmt] = useState("");

    const numtoWord = e => {
        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');
        setAmt(ucwords(amountInWords));
    }

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <h1> What's your monthly household spends? </h1>
                        <div className="input-box ruppys yearly">
                            <label> Your current expenses </label>
                            <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"expense"} value={expense} onChange={handleChange} onKeyPress={numtoWord} />
                            <span className="text-word">{amt}</span>
                            {validator.message("expense", expense, "required", {
                                messages: {
                                    required: "Expense is required"
                                }
                            })}
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton checked={true} width={100} height={40} onlabel='Yearly' offlabel='Monthly' style="toggleBtn toggleBtnC" />
                        </div>
                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
