import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Chart from 'react-apexcharts'
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../constants/global'
import formatAmount from 'indian-currency-formatter';
import { Dropdown } from 'react-bootstrap'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import EditRetirement from './Goals/EditRetirement'
import EditWealth from './Goals/EditWealth'
import EditEducation from './Goals/EditEducation'
import EditMarriage from './Goals/EditMarriage'
import EditCashflowOpt from './Goals/EditCashflowOpt'
import EditVacation from './Goals/EditVacation';

import SweetAlert from 'sweetalert2'
//import { abbreviateNumber } from "js-abbreviation-number";
import { SetBackUrl, GetBackUrl } from '../constants/common';

import { Col, Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Table } from 'reactstrap';

export default function Home() {

   const [goalCount, setGoalCount] = useState('');
   const [onTrack, setOnTrack] = useState('');
   const [targetAmount, setTargetAmount] = useState('');
   // eslint-disable-next-line
   const [goals, setGoals] = useState([]);
   const [modal, setModal] = useState(false);
   const toggle = () => setModal(!modal);
   const [goalData, setgoalData] = useState([]);

   const [cashflow, setCashflow] = useState([]);
   const [activeTab2, setActiveTab2] = useState('1');
   const [planStatus, setPlanStatus] = useState();
   const [PlanStepsType, setPlanStepsType] = useState();
   const [goalType, setGoalType] = useState("");
   const [cash, setCash] = useState("");
   const [investment, setInvestment] = useState("");
   const [totalSaving, setTotalSaving] = useState('');
   const [assumption, setAssumption] = useState([]);
   const [roi, setRoi] = useState();
   const [riskTitle, setRiskTitle] = useState();
   const [investFor, setinvestFor] = useState('');
   const [fund, setFund] = useState("");
   const [deficit, setDificit] = useState('');
   const [shortYearly, setShortYearly] = useState("");

   const [cashflowOption, setCashflowOption] = useState("");
   const [customAmount, setCustomAmount] = useState("");
   const [actual, setActual] = useState('');


   const [editTitle, setEditTitle] = useState("");
   const EditGoal = (goal_type, data) => {
      setgoalData(data);
      if (goal_type === "cashflowOpt") {
         setEditTitle('Select CashFlow Option');
      } else {
         setEditTitle('Edit ' + data.aspire + ' for ' + data.full_name);
      }

      setGoalType(goal_type);
      setModal(!modal);
   }

   const ViewGoal = (goal_id, goal_type) => {

      if (goal_type === "Retirement") {
         window.location.href = "retirement-cashflow/" + goal_id;
      } else if (goal_type === "Education") {
         window.location.href = "education-cashflow/" + goal_id;
      }else if (goal_type === "Vacation") {
         window.location.href = "vacation-cashflow/" + goal_id;
      } else if (goal_type === "Marriage") {
         window.location.href = "marriage-cashflow/" + goal_id;
      } else if (goal_type === "Wealth") {
         window.location.href = "wealth-cashflow/" + goal_id;
      }

   }

   const startPlan = () => {
      const params = { profile_id: sessionStorage.getItem('profile_id') };

      if (PlanStepsType === 'Optional') {
         window.location.href = "plan-status";
      } else {
         if (planStatus === "Continue Plan") {
            window.location.href = 'create-profile';
         } else {
            axios.post(`${apiUrl}get-plan-steps`, params).then(
               (response, data) => {
                  if (response.data.status === 100) {
                     window.location.href = 'create-profile';
                  }
               }
            );
         }
      }


   }




   const DeleteGoal = (id) => {


      SweetAlert.fire({
         title: "Are you sure?",
         text: "you want to delete this goal?",
         type: "warning",
         showCancelButton: true,
         confirmButtonColor: "#FF0000",
         confirmButtonText: "Yes",
         cancelButtonText: "Cancel",
         closeOnConfirm: false,
         closeOnCancel: true
      })
         .then((result) => {
            if (result.isConfirmed) {


               axios.post(`${apiUrl}goals/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'goal_id': id }).then(
                  function (response) {

                     axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                        (response, data) => {
                           SweetAlert.fire(
                              'Goals',
                              'Successfully Deleted.',
                              'success'
                           ).then(function () {
                              window.location.href = "/";
                           });
                        }
                     );

                  }
               )
            }
         });
   }

   const [apexDonutCharts, setApexDonutCharts] = useState({
      series: [6, 10],
      options: {
         chart: {
            type: 'donut',
         },
         colors: ['#51bb25', '#fb740d'],
         labels: ["Equity", "Debt"],
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 300
               },
               legend: {
                  position: 'bottom'
               }
            }
         }]
      },
   });

   // eslint-disable-next-line
   const [chartData, setChartData] = useState({
      series: [{
         name: 'series1',
         data: []
      }],
      options: {
         chart: {
            height: 350,
            type: 'area',
            toolbar: {
               show: false
            }
         },
         dataLabels: {
            enabled: false
         },
         colors: ['#1761c5'],
         stroke: {
            curve: 'smooth'
         },
         xaxis: {
            type: 'string',
            categories: []
         }
      }
   })



   useEffect(() => {
      sessionStorage.setItem('propery_radio', '/goals');
      sessionStorage.removeItem('goalStep');
      sessionStorage.removeItem('subType');
      sessionStorage.removeItem('giStep');
      
      SetBackUrl('/');
      console.log(GetBackUrl);
      const profile_id = sessionStorage.getItem('profile_id');
      axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
         (response, data) => {
            if (response.data.status === 100) {
               setGoals(response.data.goals);
               setGoalCount(response.data.goal_count);
               setOnTrack(response.data.on_track);
               setTargetAmount(response.data.target_amount);
               setCash(response.data.InvestmentSummary.cash_amount);
               setInvestment(response.data.assets_sum);
               setTotalSaving(response.data.incomeSum.incomeSum === null ? 0 : parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
               setAssumption(response.data.assumption);
               setRoi(response.data.profgoalSummary.pre_return * 100);

               setRiskTitle(response.data.risk_profile.risk_description);
               setFund(response.data.profgoalSummary.suggested_fund);


               setCustomAmount(response.data.profgoalSummary.custom_amount);
               setCashflowOption(response.data.profgoalSummary.cashflow_opt);
               setShortYearly(response.data.profgoalSummary.shortfall_yearly_with_increase);
               setActual((parseInt(response.data.InvestmentSummary.c_amount) + parseInt(response.data.InvestmentSummary.cash_amount)) * response.data.profgoalSummary.shortfall_lumpsum);
               setDificit((response.data.InvestmentSummary.c_amount - response.data.InvestmentSummary.cash_amount) * response.data.profgoalSummary.shortfall_lumpsum);
               setinvestFor(response.data.assumption.retire_age - response.data.self_data.current_age);

               if (response.data.step_res.length > 0) {
                  const status = response.data.step_res[0].PlanStepsTypeStatus === 'Done' ? response.data.step_res[0].PlanStepsTypeStatus : "Continue Plan";
                  setPlanStatus(status);
                  setPlanStepsType(response.data.step_res[0].PlanStepsType);
               } else {
                  setPlanStatus("Plan Now");
               }

               if (response.data.risk_profile !== '') {
                  setApexDonutCharts({
                     series: [response.data.risk_profile.max_val, 100 - response.data.risk_profile.max_val],
                     options: {
                        chart: {
                           type: 'donut',
                        },
                        colors: ['#1761c5', '#c1d9f6'],
                        labels: ["Equity", "Debt"],
                        responsive: [{
                           breakpoint: 480,
                           options: {
                              chart: {
                                 width: 200
                              },
                              legend: {
                                 show: true,
                                 position: 'bottom'
                              }
                           }
                        }]
                     },
                  });
               } else {
                  setApexDonutCharts(0);
               }

               if (response.data.goal_count > 0) {
                  axios.get(`${apiUrl}cashflow-summary/${profile_id}`).then(
                     (response, data) => {

                        if (response.data.status === 101) {
                           setCashflow(0);
                           setChartData(0);
                        } else {
                           setCashflow(response.data.cashflow);
                           setChartData({
                              series: [{
                                 name: 'series1',
                                 data: response.data.nettAmt
                              }],
                              options: {
                                 chart: {
                                    height: 500,
                                    type: 'area',
                                    toolbar: {
                                       show: false
                                    }
                                 },
                                 dataLabels: {
                                    enabled: false
                                 },
                                 colors: ['#1761c5'],
                                 stroke: {
                                    curve: 'smooth'
                                 },
                                 xaxis: {
                                    type: 'string',
                                    categories: response.data.srno
                                 },
                                 annotations: {
                                    yaxis: [],
                                    xaxis: [],
                                    points: response.data.annotation
                                 },
                              },
                              
                           });
                        }
                     });
               }

            }
         });





   }, []);

   return (
      <Fragment>
         <Header />
         <SideMenu />
         <div className="container">
            <div className="row">

               <Modal isOpen={modal} toggle={toggle} size="lg">
                  <ModalHeader toggle={toggle}>{editTitle}</ModalHeader>
                  <ModalBody>
                     {
                        goalType === 'Retirement' && (<EditRetirement data={goalData} refUrl="/goals" />)
                     }

                     {
                        goalType === 'Wealth' && (<EditWealth data={goalData} refUrl="/goals" />)
                     }

                     {
                        goalType === 'Education' && (<EditEducation data={goalData} refUrl="/goals" />)
                     }

                     {
                        goalType === 'Vacation' && (<EditVacation data={goalData} refUrl={window.location.pathname} />)
                     }

                     {
                        goalType === 'Marriage' && (<EditMarriage data={goalData} refUrl="/goals" />)
                     }

                     {
                        goalType === 'cashflowOpt' && (<EditCashflowOpt fund={(fund - actual) / shortYearly} totalSaving={totalSaving} cashflowOption={cashflowOption} customAmount={customAmount} refUrl={"/goals"} />)
                     }

                  </ModalBody>
               </Modal>
               <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="name-box">
                     <h1 className="h1Inn"> Hey {sessionStorage.getItem('full_name')}, </h1>
                     {
                        goalCount > 0 ? (
                           <>
                              <span> your are <strong className="on_track_green"> on track </strong>  to achieve <strong className="goal_count_blue">  {onTrack} of {goalCount} </strong> financial life goal</span>
                              <button type="button" className="btn Pay-Now float-left" onClick={() => { window.location.href = "/adjust" }}> Review / Adjust</button>

                           </>
                        ) :
                           (
                              <>
                                 {
                                    planStatus === "Continue Plan" ? <span> Financial Plan is getting ready. </span> : <span> Financial Plan is not created yet</span>
                                 }

                                 {
                                    goals > 0 ? <Link type="button" className="btn Pay-Now col-md-3" to="/adjust"> {"Review / Ajust"}</Link> : <Link type="button" className="btn Pay-Now col-md-3" to="#" onClick={startPlan}> {planStatus}</Link>
                                 }
                              </>
                           )
                     }


                     <br />

                  </div>
               </div>
               <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="my-goals box-shadow">
                     <h1> MY GOAL </h1>
                     <div className="nofi"> <img src="assets/images/nitification.png" alt="notification" /></div>
                     <div className="money">
                        <strong> <i className="fas fa-rupee-sign"></i> {targetAmount != null ? targetAmount : 'NA'}</strong>
                        <p>  Total Target Amount</p>
                     </div>
                     <div className="price-risk">
                        <strong>{goalCount > 0 ? goalCount : '0'}	 </strong>
                        <h3> Goals</h3>
                     </div>


                     {
                        goalCount > 0 ? <Link to="/add-goal" className="aGoal"> + Add Goal </Link> : ''
                     }

                  </div>
               </div>
            </div>
            <div className="row">

               {// eslint-disable-next-line
                  goals.map((item, index) => {
                     return (
                        <>
                           <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                              <div className="box-goal">
                                 <div className="my-goals">
                                    <div className="top-goal">
                                       <div className="retirement"> <img src={'assets/images/' + item.goal_name + '.svg'} alt="retirement" className='goalIcon' /></div>
                                       <div className="goal-name">
                                          <h1>{item.aspire} </h1>
                                          <p>{item.full_name}</p>
                                       </div>
                                       <div className="three-dot">
                                          <Dropdown>
                                             <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
                                                <i className="fas fa-ellipsis-v" />
                                             </Dropdown.Toggle>

                                             <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => EditGoal(item.goal_type, item)}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => ViewGoal(item.goal_id, item.goal_type)}>View Goal</Dropdown.Item>

                                                {
                                                   (item.goal_type === "Retirement" && item.relation === "self") ? '' : <Dropdown.Item onClick={() => DeleteGoal(item.goal_id)}>Delete</Dropdown.Item>
                                                }
                                                

                                                <Dropdown.Item href="/add-goal">Add</Dropdown.Item>
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </div>
                                    </div>
                                    <div className="Accumulated">
                                       <div className="money">
                                          <strong> Time Left</strong>
                                          <p>   {parseInt(item.required_after)} Years</p>
                                       </div>
                                       <div className="money">
                                          <strong> Target Amount</strong>
                                          <p> {formatAmount(Math.round(item.suggested_fund))} <br /> <span className='text-danger'>{item.loan_perc > 0 ? item.loan_perc+'% Loan': ''}</span></p>
                                       </div>
                                    </div>
                                    <div className="procees">
                                       <div className="progress-showcase mt-4">
                                          <div className="progress sm-progress-bar">
                                             <div className={item.achived_percentage > 80 ? 'progress-bar bg-info' : 'progress-bar bg-danger'} role="progressbar" style={{ 'width': item.achived_percentage + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                          </div>
                                       </div>
                                       <div>
                                       </div>
                                       <div className="ontrack">
                                          <table>
                                             <tr>
                                                <td>{item.achived_percentage > 80 ? <div className="greendot"> </div> : <div className="reddot"> </div>}   </td>
                                                <td>{item.achived_percentage > 80 ? 'On Track' : 'Off Track'} </td>
                                                <td>  <b> To Achieve {item.achived_percentage}% by {item.achived_by}  </b>  </td>

                                             </tr>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </>
                     )
                  })}
            </div>

            <div className="row">

               <Col lg="12 box-col-12" xl="12 xl-100">
                  <Card>
                     <CardHeader>
                        <h5>{"Goal Timeline"} </h5><span onClick={() => EditGoal('cashflowOpt', 'NA')}>Change</span>
                     </CardHeader>
                     <CardBody className="tabbed-card">
                        <Nav className="nav-pills nav-primary">
                           <NavItem>
                              <NavLink className={activeTab2 === '1' ? 'active' : ''} onClick={() => setActiveTab2('1')}>
                                 <i className="icofont icofont-ui-home"></i> {"Graph"}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink className={activeTab2 === '2' ? 'active' : ''} onClick={() => setActiveTab2('2')}>
                                 <i className="icofont icofont-man-in-glasses"></i>{"Tabular"}
                              </NavLink>
                           </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab2}>
                           <TabPane tabId="1">
                              <div className="chart-container">
                                 {// eslint-disable-next-line
                                    cashflow.length > 0 ?
                                       <Chart options={chartData.options} series={chartData.series} height="500" type="area" />
                                       :
                                       <img src="assets/images/no-cashflow.png" alt="notification" />
                                 }

                              </div>
                           </TabPane>
                           <TabPane tabId="2">
                              <div className="best-seller-table responsive-tbl">
                                 <div className="item">
                                    <div className="table-responsive product-list">
                                       <Table borderless>
                                          <thead>
                                             <tr>
                                                <th className="f-22">{"Sr. No."}</th>
                                                <th>{"Year"}</th>
                                                <th>{"Fresh Investment"}</th>
                                                <th>{"Existing Investment"}</th>
                                                <th>{"FV"}</th>
                                                <th>{"Other Income"}</th>
                                                <th>{"Outflows"}</th>
                                                <th className="text-right">{"Net Amount"}</th>
                                             </tr>
                                          </thead>
                                          <tbody>

                                             {// eslint-disable-next-line
                                                cashflow.length > 0 ?
                                                   cashflow.map((item, index) => {
                                                      return (
                                                         <>
                                                            <tr>
                                                               <td>
                                                                  <div className="d-inline-block align-middle">
                                                                     {item.no}
                                                                  </div>
                                                               </td>
                                                               <td>{item.year}</td>
                                                               <td>{formatAmount(item.fresh_investment)}</td>
                                                               <td>{formatAmount(item.actual_fund)}</td>
                                                               <td>{formatAmount(item.fv)}</td>
                                                               <td>{item.other_income}</td>
                                                               <td>{formatAmount(item.outflow)}</td>
                                                               <td className={item.nett < 0 ? 'text-danger': ''}>{formatAmount(item.nett)}</td>
                                                            </tr>
                                                         </>
                                                      )
                                                   }) :
                                                   <>
                                                      <tr>
                                                         <td>
                                                            <strong>No Data</strong>
                                                         </td>

                                                      </tr>
                                                   </>
                                             }


                                          </tbody>
                                       </Table>
                                    </div>
                                 </div>
                              </div>
                           </TabPane>

                        </TabContent>
                     </CardBody>
                  </Card>
               </Col>


            </div>

            <div className="row">
               <div className="graph-title">
                  <h2>  Whats Behind This </h2>



               </div>

               <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="my-goals Investment">
                     <h1> Linked  <br />Investment </h1>
                     <div className="nofi"></div>
                     <div className="Accumulated">
                        <div className="money">
                           <strong> <i className="fas fa-rupee-sign"></i> {investment}</strong>
                        </div>
                     </div>
                     <div className="linked">
                        <Link to="/track-portfolio" className="aGoal"> + Add Investments </Link>
                     </div>

                  </div>
               </div>
               <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="my-goals Investment">
                     <h1> Cash / Lumpsum  <br /> Available </h1>
                     <div className="nofi"></div>
                     <div className="Accumulated">
                        <div className="money">
                           <strong> <i className="fas fa-rupee-sign"></i> {cash === null ? 0 : formatAmount(cash)}</strong>
                        </div>
                     </div>
                     <div className="linked">
                        <span to="#"><br /></span>
                     </div>

                  </div>
               </div>

               <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="my-goals Investment">
                     <h1> Available For <br /> Investments</h1>
                     <div className="nofi"></div>
                     <div className="Accumulated">
                        <div className="money">
                           <strong> <i className="fas fa-rupee-sign"></i> {totalSaving === null ? 'NA' : formatAmount(totalSaving)}</strong>
                        </div>
                     </div>
                     <div className="linked">
                        <Link to="/cashflow" className="aGoal"> Cashflow </Link>
                     </div>

                  </div>
               </div>
               <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="my-goals Investment">
                     <h1> Income /Savings  <br />Growth </h1>
                     <div className="nofi"></div>
                     <div className="Accumulated">
                        <div className="money">
                           <strong>  {parseInt(assumption.income_grow)}% every year</strong>
                        </div>
                     </div>
                     <div className="linked">
                        <span to="#"><br /></span>
                     </div>

                  </div>
               </div>




            </div>


            <div className="row">
               <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2 className="i-retrun"> Investment Retrun</h2>
                  <div className="my-goals Investment Expected">
                     <div className="return return_first_tab">
                        <h1> Expected rate of retrun ? </h1>

                        <div className="Accumulated">
                           <div className="f-16 f-w-600 color176">
                              {
                                 roi === 0 || roi === null ? 'NA' : Math.round(roi) + '%'
                              }
                           </div>

                           <div className="money cursor_pointer" onClick={() => window.location.href = '/risk-view'}>
                              <strong> Investment style </strong>
                              <span> {riskTitle === null || riskTitle === undefined ? 'NA' : riskTitle} </span>
                              <span className="chevron"> <i className="mdi mdi-chevron-down"></i> </span>
                           </div>
                        </div>
                     </div>

                     <div className="prosees">


                        <div id="donutchart">
                           {
                              roi === 0 || roi === null ? '' : <Chart options={apexDonutCharts.options} series={apexDonutCharts.series} type="donut" />
                           }

                        </div>


                     </div>

                  </div>


               </div>


               <div className="col-lg-6 col-md-6 col-sm-12">

                  <div className="my-goals Investment Expected Status"  >
                     <div className="return">
                        <h1> Saving Target</h1>

                        {
                           goalCount > 0 ?
                              (
                                 <>
                                    <div className="Accumulated">
                                       <div className="money">
                                          <div className="f-16 f-w-600">This Year Required</div>
                                          <div className="f-16 f-w-600 colorff1">Rs {formatAmount(Math.round((fund - deficit) / shortYearly))}</div>
                                          {assumption.income_grow > 0 ? <span>and increase by {parseInt(assumption.income_grow)}% every year</span> : ''}

                                       </div>
                                    </div>
                                 </>
                              ) :
                              <h1 className="f-16 f-w-600 text-success mt-2 mb-3">You have not set any saving target yet.</h1>
                        }

                     </div>


                     {
                        goalCount > 0 ?
                           (
                              <>
                                 <div className="prosees">
                                    <Link to="/adjust" type="button" className="btn Pay-Now" data-bs-original-title="" title=""> Review / Ajust</Link>
                                 </div>
                                 <div className="Status-clear">
                                    <div className="Accumulated">
                                       <div className="money">
                                          <strong> Invest For ?</strong>
                                          <b className="black"> {investFor} Years</b>
                                       </div>
                                       <div className="money">
                                          <div className="f-16 f-w-600">This Year Saving</div>
                                          <div className="f-16 f-w-600">Rs {totalSaving === null ? 'NA' : formatAmount(totalSaving)}</div>
                                          {totalSaving > ((fund - deficit) / shortYearly) ? <div className="cashflow_commonbox_title_l"><span className="circle greenclr m-r-20"></span> On Track</div> : <div className="cashflow_commonbox_title_l"><span className="circle redclr m-r-20"></span> Off Track</div>}
                                       </div>
                                    </div>
                                 </div>
                              </>
                           ) :
                           (
                              <>
                                 {
                                    goalCount > 0 ? <Link type="button" className="btn Pay-Now col-md-3 mb-5" to="/adjust"> {"Review / Ajust"}</Link> : <Link type="button" className="btn Pay-Now col-md-3 mb-5" to="#" onClick={startPlan}> {planStatus}</Link>
                                 }
                              </>
                           )
                     }



                  </div>


               </div>



            </div>



         </div>

         <div className="clear"></div>
         <Footer />
      </Fragment>
   )

}
