import React, { Fragment, useState } from "react";
import { Button } from 'reactstrap'
import useValidator from '../hooks/useValidator'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../constants/global';

export default function FpPlanStatus() {

    const [validator, showValidationMessage] = useValidator();
    // eslint-disable-next-line
    const [statusRemarks, setStatusRemarks] = useState("");
    // eslint-disable-next-line
    const [statusDate, setStatusDate] = useState();
    // eslint-disable-next-line
    const [statusType, setStatusType] = useState(1);

    // eslint-disable-next-line
    const [dob, setDob] = useState();

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'statusRemarks':
                setStatusRemarks(e.target.value);
                break;

            case 'statusDate':
                setStatusDate(e.target.value);
                break;

            case 'statusType':
                setStatusType(e.target.value);
                break;

            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        if (validator.allValid()) {

            axios.post(`${apiUrl}fpplanstatus/add-update`, { profile_id: sessionStorage.getItem('profile_id'), 'statusType': statusType, 'statusRemarks': statusRemarks, 'statusDate': statusDate, plan_type:10 }).then(
                function (response) {
                    SweetAlert.fire(
                        'Plan Status',
                        'Plan Status Updated Successfully.',
                        'success'
                    ).then(function () {
                        window.location.href = "/adjust";
                    });
                }
            )
        } else {
            showValidationMessage(true);
        }
    }


    return (
        <Fragment>
            <div className="row">
                <div className="input-box selectdiv mb-4 col-md-12" >
                    <label >Plan Status*</label>
                    <select name="statusType" className="form-control digits" value={statusType} onChange={handleChange}>
                        <option value="">select plan type</option>
                        <option value="1">In Progress</option>
                        <option value="2">Pending</option>
                        <option value="3">Inputs Received</option>
                        <option value="4">Ready to Share</option>
                        <option value="5">Plan Shared</option>
                        <option value="6">Plan Re Shared</option>
                        <option value="7">Plan Freezed</option>
                        <option value="9">Execution Done</option>
                    </select>

                    {validator.message("statusType", statusType, "required", {
                        messages: {
                            required: "Plan Status is required"
                        }
                    })}

                </div>
              

                <div className="col-md-12 input-box-fluid">
                        <label> {"status Date"}</label>
                        <input type="date" maxLength="4" pattern="[1-9][0-9]{3}" max={"9999-12-31"} className="form-control" name="statusDate" autoComplete='off' value={statusDate} onChange={handleChange}/>
                        {validator.message("statusDate", statusDate, "required", {
                        messages: {
                            required: "Status Date is required"
                        }
                    })}

                    </div>

                <div className="col-md-12 input-box ruppys yearly">
                    <label >Remarks*</label>
                    <input type="text" name="statusRemarks" className="form-control" placeholder="Status Remarks" value={statusRemarks} onChange={handleChange} />
                    {validator.message("statusRemarks", statusRemarks, "required", {
                        messages: {
                            required: "Remarks is required"
                        }
                    })}
                </div>
            </div>

            <div className="form-group">
                <div className="row">
                    <div className="col-12">
                        <Button color="success" className="mr-1" type="button" onClick={handleSubmit}>Save</Button>
                        <Button color="primary">{"Cancel"}</Button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}