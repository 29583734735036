import React, { useState, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message';
import { Button } from 'reactstrap'
import axios from 'axios';
import { apiUrl } from '../constants/global';
import SweetAlert from 'sweetalert2'

export default function EditProfileAssumption({ data, categoryToggle }) {

  //eslint-disable-next-line
  const [profAssupmtion, setProfAssumption] = useState(data);

  const { register, handleSubmit, formState: { errors } } = useForm();


  const onSubmit = (data) => {


    const postData = {
      "assumption": data,
      "profile_id": sessionStorage.getItem('profile_id')
    }

    axios.post(`${apiUrl}profile-assumptions/update_assumption`, postData).then(
      (response, data) => {
        if (response.data.status === 100) {

          if (sessionStorage.getItem('goalsCount') > 0) {
            const gpostData = { profile_id: sessionStorage.getItem('profile_id') };
            axios.post(`${apiUrl}goals/reset`, gpostData)
              .then(function (response) {
                axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                  (response, data) => {

                    SweetAlert.fire({
                      toast: true,
                      icon: 'success',
                      title: 'Profile Assumption Updated Successfully.',
                      animation: false,
                      position: 'top-right',
                      showConfirmButton: false,
                      timer: 2000,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                      }
                    }).then(function () {
                      window.location.href = sessionStorage.getItem('page_referer') !== null ? sessionStorage.getItem('page_referer') : "/adjust";
                    });

                  }
                );
              })
          } else {

            SweetAlert.fire({
              toast: true,
              icon: 'success',
              title: 'Profile Assumption Updated Successfully.',
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
              }
            }).then(function () {
              window.location.href = sessionStorage.getItem('page_referer') !== null ? sessionStorage.getItem('page_referer') : "/adjust";
            });

          }

        }
      });


  }

  return (
    <Fragment>


      <div className="form-bookmark">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>


            {profAssupmtion.map((item, index) => {
              return (
                <>

                  <div className="col-md-6 input-box-fluid ruppys yearly" key={index}>
                    <label> {item.assumption_desc}</label>
                    <input type="text" className="form-control" aria-label="Goal Name" name={item.assumption_name} defaultValue={item.assumption_current} autoComplete='off' {...register(`${item.assumption_name}`, { required: true })} />
                    <span className="srv-validation-message"><ErrorMessage errors={errors} name={item.assumption_name} message="This is required" /></span>
                  </div>
                </>
              );
            })}

          </div>
          <Button color="success" type="submit">{"Save"}</Button>
          <Button color="primary" className="ml-1" type='button' toggle={categoryToggle}>{"Cancel"}</Button>
        </form>

      </div>
    </Fragment>
  )
}