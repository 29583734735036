import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from "axios";
import { apiUrl } from '../constants/global'
import Header from '../layout/Header';
import SideMenu from '../layout/SideMenu';
import Footer from '../layout/Footer';
import formatAmount from 'indian-currency-formatter';
import Chart from 'react-apexcharts'
import EditRetirement from './Goals/EditRetirement'
import EditWealth from './Goals/EditWealth'
import EditEducation from './Goals/EditEducation'
import EditMarriage from './Goals/EditMarriage'
import EditVacation from './Goals/EditVacation';
import { Dropdown } from 'react-bootstrap'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { Col, Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Table } from 'reactstrap';

export default function GoalCashflow({ data, type }) {

   //eslint-disable-next-line
   let { goalId } = useParams();
   const [goalData, setgoalData] = useState([]);
   const [goalType, setGoalType] = useState("");
   const [goalparams, setGoalparams] = useState([]);
   const [cashflow, setCashflow] = useState([]);
   const [activeTab2, setActiveTab2] = useState('1');
   const [modal, setModal] = useState(false);

   const toggle = () => setModal(!modal);
   const [editTitle, setEditTitle] = useState("");
   const EditGoal = (goal_type, data) => {

      console.log(goalType);
      setGoalparams(data);
      setEditTitle('Edit ' + data.aspire + ' for ' + data.full_name);
      setGoalType(goal_type);
      setModal(!modal);
   }

   // eslint-disable-next-line
   const [chartData, setChartData] = useState({
      series: [{
         name: 'series1',
         data: []
      }],
      options: {
         chart: {
            height: 350,
            type: 'area',
            toolbar: {
               show: false
            }
         },
         dataLabels: {
            enabled: false
         },
         colors: ['#1761c5'],
         stroke: {
            curve: 'smooth'
         },
         xaxis: {
            type: 'string',
            categories: []
         }
      }
   })

   useEffect(() => {

      const profile_id = sessionStorage.getItem('profile_id');
      let reflink = '';
      axios.post(`${apiUrl}goals/get_goal_by_id`, { 'goal_id': goalId, 'profile_id': profile_id }).then(
         (response, data) => {
            if (response.data) {
               setgoalData(response.data.goals[0]);


               if (response.data.goals[0].goal_type === "Education" || response.data.goals[0].goal_type === "Vacation") {
                  reflink = `${apiUrl}goal-education-cashflow`
               } else {
                  reflink = `${apiUrl}goal-cashflow`
               }

               axios.post(reflink, { 'goal_id': goalId, 'profile_id': profile_id }).then(
                  (response, data) => {
                     console.log(response.data);
                     if (response.data.status === 101) {
                        setCashflow(0);
                        setChartData(0);
                     } else {
                        setCashflow(response.data.cashflow);
                        setChartData({
                           series: [{
                              name: 'series1',
                              data: response.data.nettAmt
                           }],
                           options: {
                              chart: {
                                 height: 350,
                                 type: 'area',
                                 toolbar: {
                                    show: false
                                 }
                              },
                              dataLabels: {
                                 enabled: false
                              },
                              colors: ['#1761c5'],
                              stroke: {
                                 curve: 'smooth'
                              },
                              xaxis: {
                                 type: 'string',
                                 categories: response.data.srno
                              }
                           }
                        });
                     }
                  });

            } else {
               console.log('no data found');
            }

         });


   }, [goalId]);

   return (
      <React.Fragment>
         <Header />
         <SideMenu />
         <div className="container">
            <div className="row">
               <Modal isOpen={modal} toggle={toggle} size="lg">
                  <ModalHeader toggle={toggle}>{editTitle}</ModalHeader>
                  <ModalBody>
                     {
                        goalType === 'Retirement' && (<EditRetirement data={goalparams} refUrl={window.location.pathname} />)
                     }

                     {
                        goalType === 'Wealth' && (<EditWealth data={goalparams} refUrl={window.location.pathname} />)
                     }

                     {
                        goalType === 'Education' && (<EditEducation data={goalparams} refUrl={window.location.pathname} />)
                     }

                     {
                        goalType === 'Vacation' && (<EditVacation data={goalparams} refUrl={window.location.pathname} />)
                     }

                     {
                        goalType === 'Marriage' && (<EditMarriage data={goalparams} refUrl={window.location.pathname} />)
                     }


                  </ModalBody>
               </Modal>
               <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-goal">
                     <div className="my-goals">
                        <div className="top-goal">
                           <div className="retirement"> <img src={'/assets/images/' + goalData.goal_name + '.svg'} alt="retirement" className='goalIcon' /></div>
                           <div className="goal-name">
                              <h1>{goalData.aspire} </h1>
                              <p>{goalData.full_name}</p>
                           </div>
                           <div className="three-dot">
                              <Dropdown>
                                 <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
                                    <i className="fas fa-ellipsis-v" />
                                 </Dropdown.Toggle>

                                 <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => EditGoal(goalData.goal_type, goalData)}>Edit</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </div>
                        </div>
                        <div className="Accumulated">
                           <div className="money">
                              <strong> Time Left</strong>
                              <p>   {parseInt(goalData.required_after)} Years</p>
                           </div>
                           <div className="money">
                              <strong> Target Amount</strong>
                              <p> {formatAmount(Math.round(goalData.suggested_fund))}</p>
                           </div>
                        </div>
                        <div className="procees">
                           <div className="progress-showcase mt-4">
                              <div className="progress sm-progress-bar">
                                 <div className={goalData.achived_percentage > 80 ? 'progress-bar bg-info' : 'progress-bar bg-danger'} role="progressbar" style={{ 'width': goalData.achived_percentage + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </div>
                           <div>
                           </div>
                           <div className="ontrack">
                              <table>
                                 <tr>
                                    <td>{goalData.achived_percentage > 80 ? <div className="greendot"> </div> : <div className="reddot"> </div>}   </td>
                                    <td>{goalData.achived_percentage > 80 ? 'On Track' : 'Off Track'} </td>
                                    <td>  <b> To Achive {goalData.achived_percentage}% by {goalData.achived_by}  </b>  </td>

                                 </tr>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="name-box">


                     <h1 className="h1Inn"> The Corpus required is based on </h1>

                     {
                        goalData.goal_type === 'Retirement' && (<EditRetirement data={goalData} refUrl={window.location.pathname} action="view" />)
                     }

                     {
                        goalData.goal_type === 'Wealth' && (<EditWealth data={goalData} refUrl={window.location.pathname} action="view" />)
                     }

                     {
                        goalData.goal_type === 'Education' && (<EditEducation data={goalData} refUrl={window.location.pathname} action="view" />)
                     }

                     {
                        goalData.goal_type === 'Vacation' && (<EditVacation data={goalData} refUrl={window.location.pathname} action="view" />)
                     }

                     {
                        goalData.goal_type === 'Marriage' && (<EditMarriage data={goalData} refUrl={window.location.pathname} action="view" />)
                     }


                  </div>
               </div>

            </div>


            <div className="row">

               <Col lg="12 box-col-12" xl="12 xl-100">
                  <Card>
                     <CardHeader>
                        <h5>{"Goal Timeline"} </h5>
                     </CardHeader>
                     <CardBody className="tabbed-card">
                        <Nav className="nav-pills nav-primary">
                           <NavItem>
                              <NavLink className={activeTab2 === '1' ? 'active' : ''} onClick={() => setActiveTab2('1')}>
                                 <i className="icofont icofont-ui-home"></i> {"Graph"}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink className={activeTab2 === '2' ? 'active' : ''} onClick={() => setActiveTab2('2')}>
                                 <i className="icofont icofont-man-in-glasses"></i>{"Tabular"}
                              </NavLink>
                           </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab2}>
                           <TabPane tabId="1">
                              <div className="chart-container">
                                 {// eslint-disable-next-line
                                    cashflow.length > 0 ?
                                       <Chart options={chartData.options} series={chartData.series} height="350" type="area" />
                                       :
                                       <img src="assets/images/no-cashflow.png" alt="notification" />
                                 }
                              </div>
                           </TabPane>
                           <TabPane tabId="2">
                              <div className="best-seller-table responsive-tbl">
                                 <div className="item">
                                    <div className="table-responsive product-list">
                                       <Table borderless>
                                          <thead>
                                             <tr>
                                                <th className="f-22">{"Sr. No."}</th>
                                                <th>{"Year"}</th>
                                                <th>{"Fresh Investment"}</th>
                                                <th>{"Existing Investment"}</th>
                                                <th>{"FV"}</th>
                                                <th>{"Other Income"}</th>
                                                <th>{"Outflows"}</th>
                                                <th className="text-right">{"Net Amount"}</th>
                                             </tr>
                                          </thead>
                                          <tbody>

                                             {// eslint-disable-next-line
                                                cashflow.length > 0 ?
                                                   cashflow.map((item, index) => {
                                                      return (
                                                         <>
                                                            <tr>
                                                               <td>
                                                                  <div className="d-inline-block align-middle">
                                                                     {item.no}
                                                                  </div>
                                                               </td>
                                                               <td>{item.year}</td>
                                                               <td>{formatAmount(item.fresh_investment)}</td>
                                                               <td>{formatAmount(item.actual_fund)}</td>
                                                               <td>{formatAmount(item.fv)}</td>
                                                               <td>{item.other_income}</td>
                                                               <td>{formatAmount(item.outflow)}</td>
                                                               <td>{formatAmount(item.nett)}</td>
                                                            </tr>
                                                         </>
                                                      )
                                                   }) :
                                                   <>
                                                      <tr>
                                                         <td>
                                                            <strong>No Data</strong>
                                                         </td>

                                                      </tr>
                                                   </>
                                             }


                                          </tbody>
                                       </Table>
                                    </div>
                                 </div>
                              </div>
                           </TabPane>

                        </TabContent>
                     </CardBody>
                  </Card>
               </Col>


            </div>


         </div>
         <div className="clear"></div>
         <Footer />
      </React.Fragment>
   )
}
