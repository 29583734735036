import React, { Fragment, useState } from 'react'
import useValidator from '../../hooks/useValidator'
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'

export default function WealthName({title, StepChange, steps, setPercentage, percentage, profileName, gpsteps, gStepChange, goalName }) {

    //console.log('goal step'+gsteps);
    const [validator, showValidationMessage] = useValidator();
    
    var gName = goalName !== null ? goalName : 'Buy '+ goalName;
        
    const [aspire, setAspire] = useState(gName);

    const handleChange = e => {
        const { value } = e.target;
        setAspire(value);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const skipGoal = () => {
        
        let goalStep = sessionStorage.getItem('goalStep');
        let increment= 1;
        if(goalStep >= 3 && sessionStorage.getItem('kids') !== '1'){
            increment = 2;
        }
        sessionStorage.setItem('goalStep', parseInt(goalStep)+increment);
        gStepChange(parseInt(goalStep)+increment);
    }

    const handleSubmit = () => {
        if (validator.allValid()) {
            sessionStorage.setItem('aspire', aspire);
            StepChange(steps + 1);
        } else {
            showValidationMessage(true);
        }
    };

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                    <h1> {'Buy '+goalName} for {sessionStorage.getItem('g_name')}</h1>
                        <div className="input-box ruppys yearly">
                            <label> Goal Name</label>
                            <input type="text" className="form-control" aria-label="Goal Name" name={"aspire"} value={aspire} onChange={handleChange} />
                            {validator.message("aspire", aspire, "required", {
                                messages: {
                                    required: "Goal Name is required"
                                }
                            })}
                            
                        </div>
                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="skipBtn" onClick={skipGoal}> SKIP </button>
                            <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
