import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import useValidator from '../hooks/useValidator'
import { apiUrl } from '../constants/global';
import axios from 'axios';
import SweetAlert from 'sweetalert2'

export default function Register({ ulogin, relManager }) {

    console.log(ulogin);

    const [validator, showValidationMessage] = useValidator();

    const [fname, setfname] = useState('')
    const [lname, setlname] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [checked, setChecked] = useState()

    const [loading, setLoading] = useState(false)

    const [togglePassword, setTogglePassword] = useState(false)

    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }

    const handleChange = (e) => {
        const { value } = e.target;

        if (e.target.name === 'fname') {
            setfname(value);
        } else if (e.target.name === 'lname') {
            setlname(value);
        } else if (e.target.name === 'mobile') {
            setMobile(value);
        } else if (e.target.name === 'email') {
            setemail(value);
        } else if (e.target.name === 'password') {
            setpassword(value);
        } else if (e.target.name === 'checked') {
            setChecked(checked === 'checked' ? '' : 'checked');
        }
    }

    const handleSubmit = (e) => {
        if (validator.allValid()) {
            const Postdata = {
                "name": fname + " " + lname,
                "mobile": mobile,
                "email": email,
                "password": password,
                "register": 1,
                "rel_manager": relManager !== null ? relManager : ''
            };
            setLoading(true);
            axios.post(`${apiUrl}register`, Postdata)
                .then(function (response) {




                    axios.post(`${apiUrl}profile/add`, Postdata).then(function (response) {
                        sessionStorage.setItem('profile_id', response.data.profile_id);

                        axios.post(`${apiUrl}profile/summary-add`, { profile_id: response.data.profile_id }).then(function (response) {
                        });

                        const apiData = {
                            "campaign_id": '9399',
                            "campaign_type": 'TeleSales',
                            "check_condication_1": '0',
                            "check_condication_2": '0',
                            "ignore_duplicate": '1',
                            "ignore_open_task": '1',
                            "check_condication_task": '0',
                            "consider_same_account": '0',
                            "match_col": 'email_and_mobile',
                            "lead_source": 'PLAN2LMS',
                            "lead_caimpaign": 'PLAN2LMS',
                            "relationship_manager": 'MELITTA_C',
                            "tele_caller": '1000049',
                            "import_data": {
                                0: {
                                    "f_name": fname,
                                    "m_name": '',
                                    "l_name": lname,
                                    "name": fname + " " + lname,
                                    "mobile": mobile,
                                    "email": email
                                }
                            }
                        };


                        axios.post(`https://lmslive.finnovate.in/import/import-everything-api.php`, apiData).then(function (response) {
                        });
                        

                        SweetAlert.fire(
                            'Sign Up',
                            'Successfully Registered On Finnovate.',
                            'success'
                        ).then(function () {
                            setLoading(false);
                            window.location.href = ulogin === 1 ? "admin-dashboard" : "/sign-in";
                        });

                    });

                })
                .catch(e => {
                    //console.log(e.response.data.data);

                    if (e.response.data.data.hasOwnProperty("password")) {
                        //SweetAlert.fire("Warning", "" + e.response.data.data.password[0] + "", "warning");
                        SweetAlert.fire({
                            toast: true,
                            icon: 'error',
                            title: e.response.data.data.password[0],
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                            }
                        });
                    } else {
                        SweetAlert.fire("Warning", "" + e.response.data.data.email[0] + "", "warning");



                    }


                    setLoading(false);
                });
            /*.catch(function (error) {
                console.log(error);
                SweetAlert.fire(
                    'Sign Up',
                    'Failed. Something went wrong. Retry again',
                    'error'
                )
            });*/
            //setLoading(false);

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    {ulogin === 1 ? '' : <div className="col-xl-6"><img className="bg-img-cover bg-center" src="assets/images/login/Login.png" alt="looginpage" /></div>}

                    <div className={ulogin === 1 ? "col-xl-12 p-0" : "col-xl-6 p-0"} >
                        <div className={ulogin === 1 ? "login-card login-card-popup" : "login-card"}>
                            <div>
                                <div className="login-main">
                                    <form className="theme-form">
                                        <h4>Create your account</h4>
                                        <p>Enter your personal details to create account</p>
                                        <div className="form-group">
                                            <label className="col-form-label pt-0">Your Name*</label>
                                            <div className="row g-2">
                                                <div className="col-6">
                                                    <input className="form-control" type="text" name={"fname"} value={fname} placeholder="First name" onChange={handleChange} autoComplete="off" />
                                                    {validator.message("fname", fname, "required", {
                                                        messages: {
                                                            required: "First Name is required"
                                                        }
                                                    })}
                                                </div>
                                                <div className="col-6">
                                                    <input className="form-control" type="text" name={"lname"} value={lname} placeholder="Last name" onChange={handleChange} autoComplete="off" />
                                                    {validator.message("lname", lname, "required", {
                                                        messages: {
                                                            required: "Last Name is required"
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Mobile No.*</label>
                                            <input className="form-control" type="mobile" name={"mobile"} value={mobile} placeholder="Mobile Number" onChange={handleChange} autoComplete="off" />
                                            {validator.message("mobile", mobile, "required|phone", {
                                                messages: {
                                                    required: "Mobile No. is required",
                                                    phone: "Invalid Mobile No."
                                                }
                                            })}
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Email Address*</label>
                                            <input className="form-control" type="email" name={"email"} value={email} placeholder="Test@gmail.com" onChange={handleChange} autoComplete="off" />
                                            {validator.message("email", email, "required", {
                                                messages: {
                                                    required: "Email is required"
                                                }
                                            })}
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Password*</label>
                                            <div className="form-input position-relative">
                                                <input className="form-control" type={togglePassword ? "text" : "password"} name={"password"} value={password} required="" placeholder="*********" onChange={handleChange} autoComplete="off" />
                                                <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                                {validator.message("password", password, "required|min:8", {
                                                    messages: {
                                                        required: "Password is required"
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        <div className="form-group mb-0">
                                            <div className="checkbox p-0">
                                                <input id="checkbox1" type="checkbox" name={"checked"} checked={checked} value={checked} onChange={handleChange} />
                                                <label className="text-muted" htmlFor="checkbox1">Agree with<a rel="noopener noreferrer" href="https://www.finnovate.in/terms-conditions.html " target="_blank" className="ms-2">Terms & Conditions</a></label>
                                                {validator.message("checked", checked, "required", {
                                                    messages: {
                                                        required: "Click to Agree T&C"
                                                    }
                                                })}
                                            </div>
                                            {loading && <button className="btn btn-primary btn-block w-100" type="button">registering....</button>}
                                            {!loading && <button className="btn btn-primary btn-block w-100" type="button" onClick={handleSubmit}>Create Account</button>}

                                        </div>



                                        {ulogin === 1 ? '' : <p className="mt-4 mb-0 text-center">Already have an account?<Link className="ms-2" to="/sign-in">Sign in</Link></p>}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
