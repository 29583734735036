import React, { useState } from 'react'
import useValidator from '../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import numWords from 'num-words';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../constants/global';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'

export default function AddEmi() {

    const [validator, showValidationMessage] = useValidator();
    const [sub_type, setSubType] = useState();
    const [loan_amount, setloan_amount] = useState();
    const [outstanding_amt, setoutstanding_amt] = useState();
    const [emi, setemi] = useState()
    const ucwords = require('ucwords');

    const [loan_amount_txt, setLoanAmt] = useState("");
    const [outstanding_amt_txt, setOutAmt] = useState("");
    const [emi_txt, setEmiAmt] = useState("");

    const handleChange = (e) => {
        console.log(e.target.name);
        const { value } = e.target;

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));

        if (e.target.name === 'sub_type') {
            setSubType(value);
        } else if (e.target.name === 'loan_amount') {
            setloan_amount(formatAmount(value.replace(/,/g, "")));
            setLoanAmt(ucwords(amountInWords));
        } else if (e.target.name === 'outstanding_amt') {
            setoutstanding_amt(formatAmount(value.replace(/,/g, "")));
            setOutAmt(ucwords(amountInWords));
        } else if (e.target.name === 'emi') {
            setemi(formatAmount(value.replace(/,/g, "")));
            setEmiAmt(ucwords(amountInWords));
        }

    };


    const handleSubmit = (e) => {
        if (validator.allValid()) {

            const Postdata = {
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": sessionStorage.getItem('profile_id'),
                "co_name": sessionStorage.getItem('profile_id'),
                "owner": sessionStorage.getItem('profile_id'),
                "instr_name": "Loan",
                "type": 20,
                "sub_type": sub_type,
                "loan_amount": loan_amount.replace(/,/g, ""),
                "outstanding_loan": outstanding_amt.replace(/,/g, ""),
                "emi": emi.replace(/,/g, "")

            };

            axios.post(`${apiUrl}prof-liability`, Postdata)
                .then(function (response) {
                    /*SweetAlert.fire(
                        'Loans',
                        'Added Successfully.',
                        'success'
                    ).then(function () {
                        
                    });*/

                    SweetAlert.fire({
                        title: 'Loan Added Successfully!!!',
                        text: "Do you want to add more loans ?",
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Add New Loan',
                        cancelButtonText: 'Skip & Next'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/cashflow";
                        } else {
                            window.location.href = "/cashflow";
                        }
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Loans',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <div>
            <div className="tap-top"><i data-feather="chevrons-up"></i></div>
            <div className="page-wrapper CustomPage">
                <div className="container">
                    <div className="row DivJustify">
                        <div className="col-lg-8 col-md-12 col-sm-12">

                            <div className="financiali-tilte"><Link to="/cashflow" className="goback"><i className="fas fa-angle-left" /></Link><h1>Add Loan</h1></div>
                            <Progress color="success" value="100" className="sm-progress-bar" />
                            <div className=" box-shadow Creating-Financial">

                                <div className="input-box selectdiv mb-4">
                                    <label> Loan Type </label>
                                    <select className="form-control digits" name={"sub_type"} value={sub_type} onChange={handleChange} autoComplete="off">
                                        <option value="">Select Loan Type </option>
                                        <option value="1">Home Loan</option>
                                        <option value="2">Car Loan</option>
                                        <option value="3">Personal Loan</option>
                                        <option value="4">LAP</option>
                                        <option value="5">Gold Loan</option>
                                        <option value="6">Others</option>
                                    </select>
                                    {validator.message("sub_type", sub_type, "required", {
                                        messages: {
                                            required: "Loan Type is required"
                                        }
                                    })}
                                </div>

                                <div className="input-box ruppys yearly">
                                    <label> Amount Availed </label>
                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"loan_amount"} value={loan_amount} onChange={handleChange} autoComplete="off" />
                                    <span>{loan_amount_txt}</span>
                                    {validator.message("loan_amount", loan_amount, "required", {
                                        messages: {
                                            required: "Loan Amount is required"
                                        }
                                    })}
                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                </div>

                                <div className="input-box ruppys yearly">
                                    <label> EMI </label>
                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"emi"} value={emi} onChange={handleChange} autoComplete="off" />
                                    <span>{emi_txt}</span>
                                    {validator.message("emi", emi, "required", {
                                        messages: {
                                            required: "Loan EMI is required"
                                        }
                                    })}
                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                </div>

                                <div className="input-box ruppys yearly">
                                    <label> Current Total Oustanding Amount </label>
                                    <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"outstanding_amt"} value={outstanding_amt} onChange={handleChange} autoComplete="off" />
                                    <span>{outstanding_amt_txt}</span>
                                    {validator.message("outstanding_amt", outstanding_amt, "required", {
                                        messages: {
                                            required: "Loan Outstanding amount is required"
                                        }
                                    })}
                                    <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="text-end">
                                                <button className="next" type="button" onClick={handleSubmit}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
