import React from "react";
import { slide as Menu } from "react-burger-menu";

export default function ToggleMenu() {
  return (
    <Menu>
      <a className="menu-item" href="/">
        Home
      </a>

      <a className="menu-item" href="/plan">
        Plan
      </a>

      <a className="menu-item" href="/add-goal">
        Add Goal
      </a>

      <a className="menu-item" href="/desserts">
        Track Portfolio
      </a>
    </Menu>
  )
}
