import React, { useState, useEffect, Fragment } from 'react';
import Footer from '../layout/Footer';
import { apiUrl } from '../constants/global';
import { Link } from 'react-router-dom'
import axios from 'axios';
import useValidator from '../hooks/useValidator'
import SweetAlert from 'sweetalert2';

const friendsArray = [
   {
      "ques_id": 152,
      "key": "gender",
      "ans_id": null
   },
   {
      "ques_id": 153,
      "key": "age",
      "ans_id": null
   },
   {
      "ques_id": 154,
      "key": "occupation",
      "ans_id": null
   },
   {
      "ques_id": 155,
      "key": "IncomeSelf",
      "ans_id": null
   },
   {
      "ques_id": 156,
      "key": "IncomeSpouse",
      "ans_id": null
   },
   {
      "ques_id": 157,
      "key": "expense_perc",
      "ans_id": null
   },
   {
      "ques_id": 158,
      "key": "emi_perc",
      "ans_id": null
   },
   {
      "ques_id": 159,
      "key": "dependents",
      "ans_id": null
   },
   {
      "ques_id": 160,
      "key": "exp_inv_equity_mf",
      "ans_id": null
   },
   {
      "ques_id": 161,
      "key": "illustrative_portfolio",
      "ans_id": null
   },
   {
      "ques_id": 162,
      "key": "worry",
      "ans_id": null
   },
   {
      "ques_id": 163,
      "key": "financial_decission",
      "ans_id": null
   },
   {
      "ques_id": 164,
      "key": "equity_perc",
      "ans_id": null
   },
   {
      "ques_id": 165,
      "key": "acco_status",
      "ans_id": null
   },
   {
      "ques_id": 166,
      "key": "invest_knowledge",
      "ans_id": null
   }
];

export default function RiskSet() {
   const [riskq, setriskq] = useState([])
   const [steps, setSteps] = useState(0);
   const [validator, showValidationMessage] = useValidator();
   const [friends, setFriends] = useState(friendsArray); // Setting default value

   const [genderV, setgenderV] = useState('');
   const [ageV, setageV] = useState('');
   const [occupationV, setoccupationV] = useState('');
   const [IncomeSelfV, setIncomeSelfV] = useState('');
   const [IncomeSpouseV, setIncomeSpouseV] = useState('');
   const [expense_percV, setexpense_percV] = useState('');
   const [emi_percV, setemi_percV] = useState('');
   const [dependentsV, setdependentsV] = useState('');
   const [exp_inv_equity_mfV, setexp_inv_equity_mfV] = useState('');
   const [illustrative_portfolioV, setillustrative_portfolioV] = useState('');
   const [worryV, setworryV] = useState('');
   const [financial_decissionV, setfinancial_decissionV] = useState('');
   const [equity_percV, setequity_percV] = useState('');
   const [acco_statusV, setacco_statusV] = useState('');
   const [invest_knowledgeV, setinvest_knowledgeV] = useState('');

   const [profileId, setProfileId] = useState('');

   const StepChange = (step) => {
      setSteps(step);
   }

   const previousButton = () => {
      StepChange(steps - 1);
   }

   const submitButton = () => {
      console.log('data');
      console.log(friends);

      const postData = {
         "set_id": 3,
         "fname": "sanket",
         "lname": "mota",
         "mobile": 9568952565,
         "profile_id": profileId,
         "type":'enc',
         "dob": "1990-10-20",
         "email": "ashutoshajgaonkar@hotmail.com",
         "weight": 1,
         "goaltype_id": 7,
         "form_data": friends
      }

      axios.post(`${apiUrl}generate-risk`, postData)
         .then(function (response) {


            SweetAlert.fire(
               'Risk Profile',
               'Successfully created risk. Thank You.',
               'success'
            ).then(function () {
               window.location.href = '/sign-in';
            });


         })
         .catch(function (error) {
            console.log(error);
            SweetAlert.fire(
               'Risk Profile',
               'Failed.',
               'error'
            )
         });

   }
   const handleChange = (e) => {

      switch (e.target.name) {
         case 'gender':
            setgenderV(e.target.name);
            break;
         case 'age':
            setageV(e.target.name);
            break;
         case 'occupation':
            setoccupationV(e.target.name);
            break;
         case 'IncomeSelf':
            setIncomeSelfV(e.target.name);
            break;
         case 'IncomeSpouse':
            setIncomeSpouseV(e.target.name);
            break;
         case 'expense_perc':
            setexpense_percV(e.target.name);
            break;
         case 'emi_perc':
            setemi_percV(e.target.name);
            break;
         case 'dependents':
            setdependentsV(e.target.name);
            break;
         case 'exp_inv_equity_mf':
            setexp_inv_equity_mfV(e.target.name);
            break;
         case 'illustrative_portfolio':
            setillustrative_portfolioV(e.target.name);
            break;
         case 'worry':
            setworryV(e.target.name);
            break;
         case 'financial_decission':
            setfinancial_decissionV(e.target.name);
            break;
         case 'equity_perc':
            setequity_percV(e.target.name);
            break;
         case 'acco_status':
            setacco_statusV(e.target.name);
            break;
         case 'invest_knowledge':
            setinvest_knowledgeV(e.target.name);
            break;
         default:
            return '';
      }

      let ques_id = e.target.getAttribute("ques_id");
      setFriends(
         friends.map((friend) =>
            friend.ques_id === parseInt(ques_id)
               ? { ...friend, ans_id: e.target.value }
               : { ...friend }
         )
      );
   }

   const nextButton = (e) => {

      if (validator.allValid()) {
         StepChange(steps + 1);
      } else {
         showValidationMessage(true);
      }
   }

   useEffect(() => {

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const profile_id = params.get('profile_id');

      setProfileId(profile_id);

      axios.post(`${apiUrl}url-logged`, {url:window.location.href}).then(
         (response, data) => {
            
         }
      );

      const PostData = { weight: 1, set_id: 3, goaltype_id: 7 }
      axios.post(`${apiUrl}risk-question`, PostData).then(
         (response, data) => {
            setriskq(response.data.response_data.question);
            sessionStorage.setItem('questions', JSON.stringify(response.data.response_data.question));
         }
      );
   }, []);

   return (
      <Fragment>
         <div className="row DivJustify">
            <div className="col-lg-6 col-md-12 col-sm-12">
               <div className="financiali-tilte"><Link to="/risk-view" className="goback"><i className="fas fa-angle-left" /></Link>{"Set your Risk Profile"}</div>

               <div className=" box-shadow Creating-Financial">


                  {riskq.map((item, index) => {
                     return (
                        <>
                           {steps === 0 && index === 0 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span>{company.name}</span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}

                                    <input className="form-control" type="hidden" name={"genderV"} value={genderV} />
                                    {validator.message("genderV", genderV, "required", {
                                       messages: {
                                          required: "Gender is required"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 1 && index === 1 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"ageV"} value={ageV} />
                                    {validator.message("ageV", ageV, "required", {
                                       messages: {
                                          required: "Age is required"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 2 && index === 2 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"occupationV"} value={occupationV} />
                                    {validator.message("occupationV", occupationV, "required", {
                                       messages: {
                                          required: "occupation is required"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 3 && index === 3 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"IncomeSelfV"} value={IncomeSelfV} />
                                    {validator.message("IncomeSelfV", IncomeSelfV, "required", {
                                       messages: {
                                          required: "Income Self is required"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 4 && index === 4 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"IncomeSpouseV"} value={IncomeSpouseV} />
                                    {validator.message("IncomeSpouseV", IncomeSpouseV, "required", {
                                       messages: {
                                          required: "Income Spouse is required"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}


                           {steps === 5 && index === 5 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"expense_percV"} value={expense_percV} />
                                    {validator.message("expense_percV", expense_percV, "required", {
                                       messages: {
                                          required: "expense perc is required"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}


                           {steps === 6 && index === 6 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"emi_percV"} value={emi_percV} />
                                    {validator.message("emi_percV", emi_percV, "required", {
                                       messages: {
                                          required: "EMI perc is required"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 7 && index === 7 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"dependentsV"} value={dependentsV} />
                                    {validator.message("dependentsV", dependentsV, "required", {
                                       messages: {
                                          required: "dependents is required"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 8 && index === 8 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"exp_inv_equity_mfV"} value={exp_inv_equity_mfV} />
                                    {validator.message("exp_inv_equity_mfV", exp_inv_equity_mfV, "required", {
                                       messages: {
                                          required: "Please select one option"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 9 && index === 9 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"illustrative_portfolioV"} value={illustrative_portfolioV} />
                                    {validator.message("illustrative_portfolioV", illustrative_portfolioV, "required", {
                                       messages: {
                                          required: "Please select one option"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 10 && index === 10 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"worryV"} value={worryV} />
                                    {validator.message("worryV", worryV, "required", {
                                       messages: {
                                          required: "Please select one option"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 11 && index === 11 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"financial_decissionV"} value={financial_decissionV} />
                                    {validator.message("financial_decissionV", financial_decissionV, "required", {
                                       messages: {
                                          required: "Please select one option"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 12 && index === 12 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"equity_percV"} value={equity_percV} />
                                    {validator.message("equity_percV", equity_percV, "required", {
                                       messages: {
                                          required: "Please select one option"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 13 && index === 13 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"acco_statusV"} value={acco_statusV} />
                                    {validator.message("acco_statusV", acco_statusV, "required", {
                                       messages: {
                                          required: "Please select one option"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={nextButton}> Next<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}

                           {steps === 14 && index === 14 && (
                              <div key={index}>
                                 <label className="col-form-label h1_question">{item.label}</label>
                                 <div className="row">
                                    {item.answers.map((company, index) => {
                                       return (
                                          <>
                                             <div className="col-12 col-sm-12">
                                                <div className="radio_btn">
                                                   <label>
                                                      <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onChange={handleChange} />
                                                      <span><span>{company.name}</span></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    }
                                    )}
                                    <input className="form-control" type="hidden" name={"invest_knowledgeV"} value={invest_knowledgeV} />
                                    {validator.message("invest_knowledgeV", invest_knowledgeV, "required", {
                                       messages: {
                                          required: "Please select one option"
                                       }
                                    })}
                                 </div>

                                 <div className="bottombtn mt-7">
                                    <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                                    <button className="next" type='button' onClick={submitButton}> Save<i className="fas fa-angle-right" /> </button>
                                 </div>
                              </div>
                           )}
                        </>
                     );
                  })}
               </div>
            </div>
         </div>

         <div className="clear"></div>
         <Footer />
      </Fragment>
   );
}
