import React, { useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Link } from 'react-router-dom'

export default function Expense({ StepChange, steps, setPercentage, percentage, stepCount, setStepCount }) {

    const [validator, showValidationMessage] = useValidator();
    const [household_expense, setHouseExp] = useState("");
    const [childrens_edu_expense, setChildExp] = useState("");
    const [rental_paid_expense, setRentExp] = useState("");

    const handleChange = e => {
        console.log(e.target.name);
        const { value } = e.target;
        if (e.target.name === 'household_expense') {
            setHouseExp(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'childrens_edu_expense') {
            setChildExp(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'rental_paid_expense') {
            setRentExp(formatAmount(value.replace(/,/g, "")));
        }
        numtoWord(e);
    };

    const previousButton = () => {
        StepChange(steps - 1);
        setPercentage(percentage - 20);
    }

    useEffect(() => {
        let profSumm = JSON.parse(sessionStorage.getItem('ProfSummary'));

        profSumm.incomes.forEach((value, index) => {

            if (value.sub_type === "Household") {
                setHouseExp(formatAmount(value.total_amount/value.frequency));
                setHouseholdF(value.frequency);
            } else if (value.sub_type === "ChildrenEducation") {
                setChildExp(formatAmount(value.total_amount/value.frequency));
                setChildeduF(value.frequency);
            } else if (value.sub_type === "RentalExpense") {
                setRentExp(formatAmount(value.total_amount/value.frequency));
                setRentalF(value.frequency);
            }

        });
    }, [])

    const handleSubmit = () => {
        if (validator.allValid()) {

            const Postdata = {
                "profile_id": sessionStorage.getItem('profile_id'),
                "type": 2,
                "consider": 1,
                "step": steps,
                "frequency": 1,
                "income": {
                    "Household": {
                        "sub_type": "Household", "amount": household_expense, "frequency": householdFreq
                    }
                }

            };

            if (childrens_edu_expense !== '') {
                Postdata.income.ChildrenEducation = {
                    "sub_type": "ChildrenEducation", "amount": childrens_edu_expense, "frequency": child_eduFreq
                };
            }

            if (rental_paid_expense !== '') {
                Postdata.income.RentalExpense = {
                    "sub_type": "RentalExpense", "amount": rental_paid_expense, "frequency": rentalFreq
                };
            }

            axios.post(`${apiUrl}profile-income`, Postdata)
                .then(function (response) {
                    SweetAlert.fire(
                        'Expense',
                        'Self Incomme Updated.',
                        'success'
                    ).then(function () {

                        const profile_id = sessionStorage.getItem('profile_id');
                        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
                            (response, data) => {
                                if (response.data.status === 100) {
                                    sessionStorage.setItem('ProfSummary', JSON.stringify(response.data));
                                }
                            });

                            if(sessionStorage.getItem('life_stage') === "single"){
                                window.location = "/plan-status";
                            }else{
                                StepChange(steps + 1);
                                setPercentage(percentage + 100 / stepCount);
                            }
                        
                    })
                })
                .catch(function (error) {
                    SweetAlert.fire(
                        'Expense',
                        'Failed. Something went wrong. Retry again',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    };

    const [household_amt, setHousehold_amt] = useState("");
    const [child_amt, setChild_amt] = useState("");
    const [rental_amt, setRental_amt] = useState("");

    const numtoWord = e => {

        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');

        if (e.target.name === 'household_expense') {
            setHousehold_amt(ucwords(amountInWords));
        } else if (e.target.name === 'childrens_edu_expense') {
            setChild_amt(ucwords(amountInWords));
        } else if (e.target.name === 'rental_paid_expense') {
            setRental_amt(ucwords(amountInWords));
        }


    }

    const [householdFreq, setHouseholdF] = useState(12);
    const [child_eduFreq, setChildeduF] = useState(12);
    const [rentalFreq, setRentalF] = useState(12);

    return (
        <>


            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="financiali-tilte"><Link to="/plan" className="goback"><i className="fas fa-angle-left" /></Link> Creating Your Financial Plan</div>
                    <div className=" box-shadow Creating-Financial">
                        <h1> About Cashflow: Expense </h1>

                        <div className="input-box ruppys">
                            <label> Household Expense* </label>
                            <input type="text" className="form-control userInput" name={"household_expense"} value={household_expense} onChange={handleChange} />
                            <div className="intext text-word"> {household_amt} </div>
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={householdFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="primary" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                setHouseholdF(checked === true ? 1 : 12)
                            }} />

                            {validator.message("household_expense", household_expense, "required", {
                                messages: {
                                    required: "Household Expense is required",
                                    integer: "Invalid"
                                }
                            })}
                        </div>


                        <div className="input-box ruppys yearly">
                            <label> Childrens Education </label>
                            <input type="text" className="form-control userInput" name={"childrens_edu_expense"} value={childrens_edu_expense} onChange={handleChange} />
                            <div className="intext text-word"> {child_amt} </div>
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={child_eduFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="primary" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                setChildeduF(checked === true ? 1 : 12)
                            }} />
                        </div>
                        <div className="input-box ruppys yearly">
                            <label> Rental Paid </label>
                            <input type="text" className="form-control userInput" name={"rental_paid_expense"} value={rental_paid_expense} onChange={handleChange} />
                            <div className="intext text-word"> {rental_amt} </div>
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={rentalFreq === 1 ? true : false} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="primary" style="toggleBtn toggleBtnC" onChange={(checked) => {
                                setRentalF(checked === true ? 1 : 12)
                            }} />
                        </div>
                        <div className="bottombtn">
                            <button type="button" className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type="button" onClick={handleSubmit}>{"Next"}<i className="fas fa-angle-right" /></button>

                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
            </div>


        </>
    )
}
