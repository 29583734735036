import React, { Fragment, useEffect } from 'react'
import { useParams } from "react-router-dom";
import AddRetirement from './Goals/AddRetirement';
import AddWealth from './Goals/AddWealth';
import AddMarriage from './Goals/AddMarriage';
import AddEducation from './Goals/AddEducation';
import AddLife from './Goals/AddLife';
import AddHealth from './Goals/AddHealth';
import AddVacation from './Goals/AddVacation';

export default function AddGoals() {

  let { goalName } = useParams();
  console.log("this.context:", goalName)
  /*const [steps, setSteps] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [stepCount, setStepCount] = useState(0);

  const StepChange = (step) => {
    setSteps(step);
  }*/

  useEffect(() => {
    console.log(goalName);

    sessionStorage.removeItem('goal_creation')
  });

  return (

    <Fragment>
      <div className="tap-top"><i data-feather="chevrons-up"></i></div>
      <div className="page-wrapper CustomPage">
        <div className="container">
          <div className="theme-form">
            {goalName === 'retirement' && (<AddRetirement title={"Add Goal"}/>)}
            {goalName === 'education' && (<AddEducation title={"Add Goal"}/>)}
            {(goalName === 'wealth' || goalName === 'buycar' || goalName === 'buyhome') && (<AddWealth title={"Add Goal"} goalName={goalName === 'buycar' ? 'Car' : (goalName === 'buyhome' ? 'House' : (goalName === 'vacation' ? 'Vacation' : 'Wealth'))}/>)}
            {goalName === 'vacation' && (<AddVacation title={"Add Goal"} goalName={"Vacation"}/>)}
            {goalName === 'marriage' && (<AddMarriage title={"Add Goal"}/>)}
            {goalName === 'life' && (<AddLife title={"Add Life Insurance"}/>)}
            {goalName === 'health' && (<AddHealth title={"Add Health Insurance"}/>)}
          </div>
        </div>
      </div>
    </Fragment>

  )
}