import React, { Fragment, useState } from 'react'
import useValidator from '../../hooks/useValidator'
import { Input, Col } from 'reactstrap'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { calculateAge } from '../../constants/common';

export default function VacationYears({ title, StepChange, steps, setPercentage, percentage, profileName, goalName }) {

    const [validator, showValidationMessage] = useValidator();

    const [requiredAfter, setRequiredAfter] = useState(1);
    const [afterValue, setAfterValue] = useState();

    const [requiredFor, setRequiredFor] = useState(1);
    const [forValue, setForValue] = useState();

    const onSliderChange = value => {
        setAfterValue(value);
        setRequiredAfter(value);
    };

    const onAfterChange = value => {
        setRequiredAfter(null);
    };

    const handleInputChange = (event) => {
        setAfterValue(event.target.value);
        setRequiredAfter(event.target.value);
    };

    const onSliderChangeFor = value => {
        setForValue(value);
        setRequiredFor(value);
    };

    const onAfterChangeFor = value => {
        setRequiredFor(null);
    };

    const handleInputChangeFor = (event) => {
        setForValue(event.target.value);
        setRequiredFor(event.target.value);
    };

    const handleBlur = () => {
        if (requiredAfter < 0) {
            setRequiredFor(0);
        } else if (requiredAfter > 100) {
            setRequiredFor(100);
        }
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleBlurFor = () => {
        if (requiredFor < 0) {
            setRequiredAfter(0);
        } else if (requiredFor > 100) {
            setRequiredAfter(100);
        }
    };

    const handleSubmit = (event) => {
        if (validator.allValid()) {

            const age = calculateAge(sessionStorage.getItem('g_dob'));
            const required_after = afterValue < 1 ? 1 : afterValue;
            const required_till = parseInt(required_after) + parseInt(forValue);
            const postData = {
                "goal_type": "Vacation",
                "aspire": "Vacation",
                "goal_name": "Vacation",
                "profile_id": sessionStorage.getItem('profile_id'),
                "plan_for": {
                    "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
                    "current_age": age < 1 ? 1 : age,
                    "relation": sessionStorage.getItem('g_relation'),
                },
                "yearly_expense": {
                    "0": sessionStorage.getItem("expense").replace(/,/g, ""),
                    "1": 0
                },
                "no_years": {
                    "0": forValue,
                    "1": 0
                },
                "goal_params": {
                    "age": age < 1 ? 1 : age,
                    "post_inflation": 7,
                    "post_return": 7,
                    "goal_inflation": 7,
                    "required_after": required_after,
                    "required_till": required_till
                },
                "risk_profile": {
                    "available": false,
                    "pre_return": 8,
                    "risk_profile_id": sessionStorage.getItem('profile_id')
                },
                "assumptions": {
                    "assumption_id": false,
                    "inflation": 7.5,
                    "income_grow": "10"
                },
                "total_abcd": [{
                    "amount": sessionStorage.getItem("expense").replace(/,/g, ""),
                    "frequency": 12,
                    "no_of_times": 1,
                    "gap": 0
                }],
                "actual": [{
                    "actual_y": 0,
                    "frequency": 1,
                    "actual_ls": 1,
                    "grow": "0"

                }]
            };

            axios.post(`${apiUrl}goals/add`, postData)
                .then(function (response) {
                    SweetAlert.fire(
                        'Goals',
                        'Added New Goal.',
                        'success'
                    ).then(function () {
                        window.location.href = "/";
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });


        } else {
            showValidationMessage(true);
        }
    };

    return (
        <Fragment>
            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <h1> {title} for {profileName}</h1>
                        <div className="input-box ruppys yearly">
                            <div md="10">
                                <label>{"Required After ?"}</label></div>
                            <div md="2">
                                <Input
                                    value={requiredAfter}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    style={{ marginTop: '50px' }}
                                />
                            </div>
                            <Col md="12">
                                <Slider
                                    onChange={onSliderChange}
                                    onAfterChange={onAfterChange}
                                    min={1}
                                    max={10}
                                    defaultValue={afterValue}
                                    value={afterValue}
                                />
                            </Col>
                        </div>

                        <div className="input-box ruppys yearly">
                            <div md="10">
                                <label>{"Required For ?"}</label></div>
                            <div md="2">
                                <Input
                                    value={requiredFor}
                                    onChange={handleInputChangeFor}
                                    onBlur={handleBlurFor}
                                    type="text"
                                    style={{ marginTop: '50px' }}
                                />
                            </div>
                            <Col md="12">
                                <Slider
                                    onChange={onSliderChangeFor}
                                    onAfterChange={onAfterChangeFor}
                                    min={1}
                                    max={20}
                                    defaultValue={forValue}
                                    value={forValue}
                                />
                            </Col>
                        </div>

                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Save<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
