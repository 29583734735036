import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import useValidator from '../hooks/useValidator'
import { apiUrl,  } from '../constants/global';
import axios from 'axios';
import SweetAlert from 'sweetalert2'

export default function Login() {

   const [validator, showValidationMessage] = useValidator();
   const [email, setemail] = useState('')
   const [password, setpassword] = useState('')
   const [togglePassword, setTogglePassword] = useState(false)
   const [loading, setLoading] = useState(false)

   const HideShowPassword = (tPassword) => {
      setTogglePassword(!tPassword)
   }

   const handleChange = (e) => {
      const { value } = e.target;
      if (e.target.name === 'email') {
         setemail(value);
      } else if (e.target.name === 'password') {
         setpassword(value);
      }
   }

   const handleSubmit = (e) => {
      if (validator.allValid()) {
         setLoading(true);
         const Postdata = {
            "email": email,
            "password": password
         };

         axios.post(`${apiUrl}login`, Postdata)
            .then(function (response) {

               
               SweetAlert.fire({
                  toast: true,
                  icon: 'success',
                  title: 'Signed in Successfully',
                  animation: false,
                  position: 'top-right',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                     toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                     toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                  }
               }).then(function () {

                  sessionStorage.setItem('token', response.data.data.token);
                  const pdata = { "email": email };
                  axios.post(`${apiUrl}profile/view`, pdata).then(function (response) {
                     sessionStorage.setItem('profile_id', response.data.profile_id);
                     sessionStorage.setItem('full_name', response.data.full_name);
                     if(response){
                        window.location.href = "/plan";
                     }
                  });

                  //
                  
               });
            })
            .catch(e => {
               SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
               setLoading(false);
            });
      } else {
         showValidationMessage(true);
      }
   }

   return (
      <Fragment>
         <div className="container-fluid">
            <div className="row">
               <div className="col-xl-6"><img className="bg-img-cover bg-center" src="assets/images/login/Login.png" alt="looginpage" /></div>
               <div className="col-xl-6 p-0">
                  <div className="login-card">
                     <div>
                        <div className="login-main">
                           <form className="theme-form">
                              <h4>Sign in to account</h4>
                              <p>Enter your email and password to login</p>
                              <div className="form-group">
                                 <label className="col-form-label">Email Address</label>
                                 <input className="form-control" type="email" name={"email"} value={email} placeholder="Test@gmail.com" onChange={handleChange} autoComplete="off" />
                                 {validator.message("email", email, "required", {
                                    messages: {
                                       required: "Email is required"
                                    }
                                 })}
                              </div>
                              <div className="form-group">
                                 <label className="col-form-label">Password</label>
                                 <div className="form-input position-relative">
                                    <input className="form-control" type={togglePassword ? "text" : "password"} name={"password"} value={password} placeholder="*********" onChange={handleChange} autoComplete="off" />
                                    <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                    {validator.message("password", password, "required", {
                                       messages: {
                                          required: "Password is required"
                                       }
                                    })}
                                 </div>
                              </div>
                              <div className="form-group mt-3">
       
                                 {loading && <button className="btn btn-primary btn-block w-100" type="button">signing in...</button>}
                                 {!loading && <button className="btn btn-primary btn-block w-100" type="button" onClick={handleSubmit}>Sign in</button>}

                              </div>
                              <p className="mt-4 mb-0 text-center">Don't have account?<Link className="ms-2" to="/sign-up">Create Account</Link></p>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   )
}
