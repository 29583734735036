import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import useValidator from '../../hooks/useValidator'

export default function Step1({ step, ChangeStep, riskId, riskDescription }) {
    console.log('risk id'+riskDescription);
    const [validator, showValidationMessage] = useValidator();
    const [risk, setRisk] = useState("");

    const handleSubmit = () => {

        if (validator.allValid()) {
            ChangeStep(step + 1);
        } else {
            showValidationMessage(true);
        }

    }

    const handleChange = e => {
        if (e.target.name === 'risk') {
            if(e.target.value){
                setRisk(e.target.value);
                sessionStorage.setItem('risk', e.target.value);
            }else{
                sessionStorage.setItem('page_referer', '/strategy');
                window.location.href = "/risk-profile";
            }
        }
    };

    useEffect(() => {

        setRisk(riskId);
  
     }, [riskId]);

    return (
        <Fragment>

            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="financiali-tilte"></div>
                    <div className=" box-shadow Creating-Financial">
                        <h1> Set Your Investment Risk Profile </h1>
                        <div className="form-group">

                            <div className="m-checkbox-inline">
                                <label for="risk">
                                    <input className="radio_animated" id="edo-ani" type="radio" name={"risk"} value={risk} onChange={handleChange}/>{riskDescription === undefined ? "Create Risk Profile" : riskDescription}
                                </label>

                            </div>

                            {validator.message("risk", risk, "required", {
                                messages: {
                                    required: "Risk Profile is required"
                                }
                            })}

                        </div>
                        <div className="bottombtn">
                            <button className="back" disabled> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Next<i className="fas fa-angle-right" /> </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12"> </div>
            </div>
        </Fragment>
    )
}
