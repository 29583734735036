import React, { Fragment } from 'react'
import { Button, Progress } from 'reactstrap';
import { Link } from 'react-router-dom'

export default function AddLoanChoice({ StepChange }) {

    const showLoanForm = (e) => {
        StepChange(2);
    }

    const SkipStep = (e) => {
        window.location.href = "/risk-plan";
    }

    return (

        <Fragment>
            <div className="row DivJustify">

                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/plan" className="goback"><i className="fas fa-angle-left" /></Link> Do you have any loans</div>

                    <Progress color="success" value="50" className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <h1> select one to continue </h1>
                       
                            <Button className='btn_choice btnYes' onClick={showLoanForm}>Yes</Button>
                            <Button className='btn_choice btnNo' onClick={SkipStep}>No</Button>




                    </div>
                </div>

            </div>



        </Fragment>

    )
}
