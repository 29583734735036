import React, { useState } from 'react';
import useValidator from '../../hooks/useValidator'
import formatAmount from 'indian-currency-formatter';
import { Button } from 'reactstrap'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';

export default function EditMarriage({ data, refUrl, action }) {

    console.log(data);
    const [validator, showValidationMessage] = useValidator();
    // eslint-disable-next-line
    const [expenses, setExpenses] = useState(formatAmount(data.total_abcd));
    // eslint-disable-next-line
    const [inflation, setInflation] = useState(parseInt(data.goal_inflation));
    // eslint-disable-next-line
    const [grow_till, setGrow_till] = useState(parseInt(data.required_after) + parseInt(data.profile_age));
    // eslint-disable-next-line
    const [life_expectancy, setLife_expectancy] = useState(parseInt(data.life_expectancy));

    const [loan_perc, setLoan_perc] = useState(data.loan_perc);
    const [loan_tenure, setLoan_tenure] = useState(data.loan_tenure);
    const [loan_rate, setLoan_rate] = useState(data.loan_rate);

    const numtoWord = () => {

    }

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'expenses':
                setExpenses(formatAmount(e.target.value.replace(/,/g, "")));
                break;

            case 'inflation':
                setInflation(e.target.value);
                break;

            case 'grow_till':
                setGrow_till(e.target.value);
                break;

            case 'life_expectancy':
                setLife_expectancy(e.target.value);
                break;

            case 'loan_perc':
                setLoan_perc(e.target.value);
                break;


            case 'loan_tenure':
                setLoan_tenure(e.target.value);
                break;

            case 'loan_rate':
                setLoan_rate(e.target.value);
                break;

            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        if (validator.allValid()) {


            const postData = {
                "goal_type": "Marriage",
                "goal_id": data.goal_id,
                "aspire": data.aspire,
                "profile_id": data.profile_id,
                "plan_for": {
                    "profile_details_id": data.profile_details_id,
                    "current_age": data.current_age,
                    "relation": data.relation
                },
                "goal_params": {
                    "age": data.current_age,
                    "post_inflation": 7,
                    "post_return": 8,
                    "goal_inflation": inflation,
                    "required_after": grow_till,
                    "required_till": grow_till,
                    "loan_perc": loan_perc,
                    "loan_tenure": loan_tenure,
                    "loan_rate": loan_rate
                },
                "risk_profile": {
                    "available": false,
                    "pre_return": 10,
                    "risk_profile_id": data.profile_id
                },
                "assumptions": {
                    "assumption_id": false,
                    "inflation": 7.5,
                    "income_grow": "10"
                },
                "total_abcd": [{
                    "amount": expenses.replace(/,/g, ""),
                    "frequency": 1,
                    "no_of_times": 1,
                    "gap": 0
                }],
                "actual": [{
                    "actual_y": 0,
                    "frequency": 1,
                    "actual_ls": 1,
                    "grow": "0"

                }]
            };

            axios.post(`${apiUrl}goals/add`, postData)
                .then(function (response) {
                    axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                        (response, data) => {
                            SweetAlert.fire(
                                'Goals',
                                'Successfully Updated Goal.',
                                'success'
                            ).then(function () {
                                window.location.href = refUrl
                            })
                        }
                    );
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    }

    return (
        <>
            <div className="form-row">
                <div className='row'>
                    <div className="col-md-4 input-popup">
                        <label> Current Expenses For Marriage </label>
                        <input type="text" className="form-control userInput" aria-label="Expense" name={"expenses"} value={expenses} onChange={handleChange} onKeyPress={numtoWord} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("expenses", expenses, "required", {
                            messages: {
                                required: "Expense is required"
                            }
                        })}
                        <div className="ruppy-popup"> <i className="fas fa-rupee-sign" /></div>
                    </div>


                    <div className="col-md-4 input-popup">
                        <label> Age at Marriage </label>
                        <input type="text" className="form-control" aria-label="Inflation" name={"grow_till"} value={grow_till} onChange={handleChange} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("grow_till", grow_till, "required", {
                            messages: {
                                required: "Age at Marriage is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> % </div>
                    </div>


                    <div className="col-md-4 input-popup">
                        <label> Assumed Inflation</label>
                        <input type="text" className="form-control" aria-label="Retire Age" name={"inflation"} value={inflation} onChange={handleChange} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("inflation", inflation, "required", {
                            messages: {
                                required: "Inflation is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> Years </div>
                    </div>

                    <div className="col-md-4 input-popup">
                        <label> Loan % </label>
                        <input type="text" className="form-control" aria-label="Loan Percentafe" name={"loan_perc"} value={loan_perc} onChange={handleChange} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("loan_perc", loan_perc, "required", {
                            messages: {
                                required: "Loan Percentage is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> %  </div>
                    </div>

                    <div className="col-md-4 input-popup">
                        <label> Loan Rate </label>
                        <input type="text" className="form-control" aria-label="Loan Percentafe" name={"loan_rate"} value={loan_rate} onChange={handleChange} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("loan_rate", loan_rate, "required", {
                            messages: {
                                required: "Loan Rate is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> %  </div>
                    </div>

                    <div className="col-md-4 input-popup">
                        <label> Loan Tenure </label>
                        <input type="text" className="form-control" aria-label="Loan Percentafe" name={"loan_tenure"} value={loan_tenure} onChange={handleChange} autoComplete="off" readOnly={action === "view" ? true : false} />
                        {validator.message("loan_tenure", loan_tenure, "required", {
                            messages: {
                                required: "Loan Tenure is required"
                            }
                        })}
                        <div className="ruppy-popup-right"> %  </div>
                    </div>




                </div>

                {action !== "view" && (
                    <div className="form-group">
                        <div className="row">
                            <div className="col-12">
                                <Button color="success" className="mr-1" type="button" onClick={handleSubmit}>Save</Button>
                                <Button color="primary">{"Cancel"}</Button>
                            </div>
                        </div>
                    </div>
                )

                }

            </div>
        </>
    )
}