import React, { Fragment, useState, useEffect } from 'react'
import useValidator from '../../hooks/useValidator'
import numWords from 'num-words';
import formatAmount from 'indian-currency-formatter';
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../../constants/global';
import {calculateAge} from '../../constants/common'

export default function LifeExpense({ title, StepChange, steps, setPercentage, percentage }) {

    const [validator, showValidationMessage] = useValidator();
    const [expense, setExpense] = useState("");
    const [amt, setAmt] = useState("");

    const handleChange = e => {
        const { value } = e.target;
        setExpense(formatAmount(value.replace(/,/g, "")));
        numtoWord(e);
    };

    const previousButton = () => {
        StepChange(steps - 1);
    }

    const handleSubmit = () => {

        if (validator.allValid()) {

            sessionStorage.setItem('expense', expense);

            const age = calculateAge(sessionStorage.getItem('g_dob'));

            const postData = {
                "goal_type": "Life Insurance",
                "aspire": sessionStorage.getItem('aspire'),
                "profile_id": sessionStorage.getItem('profile_id'),
                "profile_details_id": sessionStorage.getItem('g_profile_details_id'),
                "yearly_expense": sessionStorage.getItem('expense').replace(/,/g, ""),
                "yearly_income": sessionStorage.getItem('income').replace(/,/g, ""),
                "dob": age,
                "api_type": 'insert',
                "select_opt":"regular",
                'goal_group': 2
            };

            axios.post(`${apiUrl}goals/addlife`, postData)
                .then(function (response) {
                    SweetAlert.fire(
                        'Goals',
                        'Added New Goal.',
                        'success'
                    ).then(function () {
                        window.location.href = "/plan"
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    SweetAlert.fire(
                        'Goals',
                        'Failed.',
                        'error'
                    )
                });

        } else {
            showValidationMessage(true);
        }
    };

    const numtoWord = e => {
        const amountInWords = numWords(e.target.value.replace(/,/g, ""));
        const ucwords = require('ucwords');
        setAmt(ucwords(amountInWords));
    }

    useEffect(() => {
        const profile = JSON.parse(sessionStorage.getItem('ProfSummary'));
        const ucwords = require('ucwords');
        setExpense(formatAmount(profile.profIncomeData.total_expense.replace(/,/g, "")));
        const amountInWords = numWords(profile.profIncomeData.total_expense);
        setAmt(ucwords(amountInWords));


    }, [])

    return (
        <Fragment>


            <div className="row DivJustify">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="financiali-tilte"><Link to="/" className="goback"><i className="fas fa-angle-left" /></Link>{title}</div>
                    <Progress color="success" value={percentage} className="sm-progress-bar" />
                    <div className=" box-shadow Creating-Financial">
                        <h1> {title} for {"profile_name"}</h1>
                        <div className="input-box ruppys yearly">
                            <label> Your current expenses </label>
                            <input type="text" className="form-control userInput" aria-label="Amount (to the nearest dollar)" name={"expense"} value={expense} onChange={handleChange} onKeyPress={numtoWord} autoComplete='off' />
                            <span className="text-word">{amt}</span>
                            {validator.message("expense", expense, "required", {
                                messages: {
                                    required: "Current expenses is required"
                                }
                            })}
                            <div className="ruppy"> <i className="fas fa-rupee-sign" /></div>
                            { /* eslint-disable-next-line */}
                            <BootstrapSwitchButton id="btnself" width={70} height={40} checked={true} size="sm" onlabel='YEARLY' offlabel='MONTHLY' onstyle="success" offstyle="danger" style="toggleBtn toggleBtnC" />
                        </div>

                        <div className="bottombtn mt-7">
                            <button className="back" onClick={previousButton}> <i className="fas fa-angle-left" /> </button>
                            <button className="next" type='button' onClick={handleSubmit}> Save<i className="fas fa-angle-right" /> </button>
                        </div>

                    </div>


                </div>
            </div>


        </Fragment>


    )
}

